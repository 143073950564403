import axios from 'axios'
import { getAllTrafficCards } from './grid'

export const getAvailableTags = async (projectId: string): Promise<string[]> => {
  let result: string[] = []

  try {
    const trafficCards = await getAllTrafficCards(projectId)
    const set = new Set<string>()

    for (const trafficCard of trafficCards) {
      for (const tag of trafficCard.tags) {
        set.add(tag)
      }
    }

    result = Array.from(set)
  } catch (error: any) {
    console.log(error)
  }

  return result
}

export const saveTrafficCard = async (projectId: string, model: any, translations: any) => {
  const method = model._id ? 'update' : 'create'

  const formData = new FormData()
  formData.append('projectId', projectId)
  formData.append('trafficCard', JSON.stringify(model))

  for (const language of model.languages) {
    const file = translations.value.image[language][0]
    const verticalFile = translations.value.verticalImage[language][0]

    if (file instanceof File) {
      formData.append(`image_${language}`, file)
    }

    if (file instanceof File) {
      formData.append(`verticalImage_${language}`, verticalFile)
    }
  }

  await axios.post(`/api/traffic-card/${method}`, formData)
}

export const getTrafficCard = async (projectId: string, trafficCardId: string) => {
  const { data } = await axios.post('/api/traffic-card/get', { projectId, id: trafficCardId })

  return data
}
