
import { Ref, computed, defineComponent, onMounted, ref, watch } from 'vue'
import BckColorPicker from '@/components/ui/BckColorPicker.vue'
import BckFile from '@/components/ui/BckFile.vue'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import AssetsService from '@/services/AssetsService'
import { useForm } from 'vee-validate'
import { useProjectStyle } from '@/composables/useProjectStyle'
import { AssetType, FontType } from '@/types/models'
import { useRouter } from 'vue-router'
import { useFormState } from '@/composables/useFormState'
import SeamlessPreview from '@/components/common/SeamlessPreview.vue'
import { getFullCdnUrlByAssetType } from '@/graphqlBackOffice/projects/transformers'
import { getImagePreview } from '@/utils/uploadsHelpers'
import { IMAGES_EXT, FONTS_EXT } from '@/utils/constants'
import {
  loaderStyle,
  uploadHelperText,
  cardImage,
  formatsWrapper,
  cardsWrapper,
  brandsWrapper,
} from '@/views/project/styles'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

export default defineComponent({
  components: {
    SeamlessPreview,
    BckFile,
    BckColorPicker,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { loading, project } = ProjectsService.getProjectById(props.id)
    const { updateAsset: updateAssetMutation } = ProjectsService.useMutations()
    const googleFonts = ref()
    const errorMessage = ref('')
    const saving = ref(false)

    const router = useRouter()
    const canEdit = isAuthorized(Permissions.EDIT_PROJECT_STYLE, props.id)

    const fontTabs: { key: FontType; name: string }[] = [
      { key: 'google', name: 'Google Fonts' },
      { key: 'custom', name: 'Upload Font' },
    ]

    onMounted(async () => {
      googleFonts.value = await AssetsService.getGoogleFonts()
    })

    const {
      branding,
      setBranding,
      getProjectAsset,
      brandingValidation,
      updateBrandingLocally,
      updateAsset,
      activeFont,
      activeBoldFont,
    } = useProjectStyle()

    const { setValues, handleSubmit, values, errors, meta } = useForm({
      validationSchema: brandingValidation,
    })

    // const formatPicked = ref(-1)

    // watch(
    //   () => values.cardDesign,
    //   (format) => {
    //     formatPicked.value = format === 'oldCard' ? 0 : format === 'newCard' ? 1 : 2
    //   }
    // )

    // const currentCardDesig = computed(() => {
    //   switch (formatPicked.value) {
    //     case 0:
    //       return 'oldCard'
    //     case 1:
    //       return 'newCard'
    //     case 2:
    //       return 'e2eCard'
    //     default:
    //       return 'oldCard'
    //   }
    // })

    // Check for unsaved changes on page leave.
    const { useLeavingConfirmation } = useFormState(meta, values)
    useLeavingConfirmation()

    const setFormValues = () => {
      setBranding(project.value?.branding)
      setValues({
        ...branding.value,
        logo: getProjectAsset(branding.value?.assets, 'logo')?.resourcePath
          ? getProjectAsset(branding.value?.assets, 'logo')
          : null,
        font: getProjectAsset(branding.value?.assets, 'font')?.resourcePath
          ? getProjectAsset(branding.value?.assets, 'font')
          : null,
        boldFont: getProjectAsset(branding.value?.assets, 'boldFont')?.resourcePath
          ? getProjectAsset(branding.value?.assets, 'boldFont')
          : null,
        projectBackgroundImage: getProjectAsset(branding.value?.assets, 'projectBackgroundImage')?.resourcePath
          ? getProjectAsset(branding.value?.assets, 'projectBackgroundImage')
          : null,
      })
    }

    /**
     * The current logo from the page - can be either the stored to cdn logo
     * or the current uploaded file
     */
    const logo = computed(() => {
      const asset = getProjectAsset(branding.value?.assets, 'logo')
      if (asset && typeof asset.resourcePath === 'object') {
        return URL.createObjectURL(asset?.resourcePath)
      }
      return getFullCdnUrlByAssetType(branding.value, 'logo')
    })

    const projectBackgroundImage = computed(() => {
      const asset = getProjectAsset(branding.value?.assets, 'projectBackgroundImage')
      if (asset && typeof asset.resourcePath === 'object') {
        return URL.createObjectURL(asset?.resourcePath)
      }
      return getFullCdnUrlByAssetType(branding.value, 'projectBackgroundImage')
    })

    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          setFormValues()
        }
      }
    )

    if (!loading.value) {
      setFormValues()
    }

    const updateGoogleFont = (newFont: string) => {
      fontUrl.value = newFont
      updateBrandingLocally(props.id, 'googleFontPath', newFont)
    }

    // const checkTheFormat = (formatType: string) => {
    //   switch (formatType) {
    //     case 'old':
    //       formatPicked.value = 0
    //       updateBrandingLocally(props.id, 'cardDesign', 'oldCard')
    //       break
    //     case 'new':
    //       formatPicked.value = 1
    //       updateBrandingLocally(props.id, 'cardDesign', 'newCard')
    //       break
    //     case 'e2e':
    //       formatPicked.value = 2
    //       updateBrandingLocally(props.id, 'cardDesign', 'e2eCard')
    //       break
    //     default:
    //       break
    //   }
    // }

    const updateBranding = ($event: Event) => {
      const target = $event.target as HTMLInputElement
      updateBrandingLocally(props.id, target.name, target.value)
    }

    const updateFile = (filePayload: File, fieldName: AssetType) => {
      if (branding.value && !filePayload) branding.value.googleFontPath = ''
      updateAsset(props.id, filePayload, fieldName)
    }

    const onSubmit = handleSubmit(async () => {
      saving.value = true
      try {
        // Upload files to bucket and then save them to db
        for (const file of branding.value?.assets || []) {
          if (!file.resourcePath || typeof file.resourcePath !== 'object') {
            continue
          }

          const result = await updateAssetMutation.mutate({
            projectName: project?.value?.name,
            id: file._id,
            file: file.resourcePath,
            resourceType: file.resourceType,
          })

          if (result?.data.updateAsset.resourceType === 'font' && branding.value)
            branding.value.googleFontPath = result.data.updateAsset.resourcePath

          await updateAsset(props.id, result?.data?.updateAsset?.resourcePath, file.resourceType)
        }

        await ProjectsService.updateBranding(branding.value)

        // go to pwa configuration page
        router.push({
          name: 'Meta Information',
          params: { action: 'save' },
        })
      } catch (err) {
        errorMessage.value = err?.response?.data?.error || 'Something went wrong, please try again later.'
      } finally {
        saving.value = false
      }
    })

    const seamlessPreviewLogo = computed(() => {
      return getImagePreview(logo.value)
    })

    const font = computed(() => getProjectAsset(branding.value?.assets, 'font')?.resourcePath)

    const boldFont = computed(() => getProjectAsset(branding.value?.assets, 'boldFont')?.resourcePath)

    const convertFont = (font: File) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(font)
        reader.onerror = reject
        reader.onload = () => (reader.result && resolve(reader.result?.toString())) || reject
      })

    const fontUrl = ref<string | undefined>(undefined)
    const boldFontUrl = ref<string | undefined>(undefined)

    const convertAndUpdateFont = async (file: File, ref: Ref<string | undefined>) =>
      (ref.value = await convertFont(file))

    if (typeof activeFont.value === 'string') {
      fontUrl.value = activeFont.value
    }
    if (typeof activeBoldFont.value === 'string') {
      boldFontUrl.value = activeBoldFont.value
    }

    if (typeof activeFont.value === 'object') {
      convertAndUpdateFont(activeFont.value, fontUrl)
    }
    if (typeof activeBoldFont.value === 'object') {
      convertAndUpdateFont(activeBoldFont.value, boldFontUrl)
    }

    const watchFont = async (font: any, ref: Ref<string | undefined>) => {
      try {
        if (typeof font === 'string') {
          ref.value = font
        } else {
          if (typeof font === 'object') {
            ref.value = await convertFont(font)
          } else {
            ref.value = undefined
          }
        }
      } catch {
        ref.value = undefined
      }
    }

    watch(activeFont, (font: any) => watchFont(font, fontUrl))
    watch(activeBoldFont, (font: any) => watchFont(font, boldFontUrl))

    return {
      css: { loaderStyle },
      uploadHelperText,
      cardImage,
      formatsWrapper,
      cardsWrapper,
      brandsWrapper,
      getProjectAsset,
      onSubmit,
      updateFile,
      updateBranding,
      updateGoogleFont,
      errorMessage,
      errors,
      saving,
      loading,
      project,
      values,
      googleFonts,
      branding,
      logo,
      projectBackgroundImage,
      seamlessPreviewLogo,
      font,
      // checkTheFormat,
      // formatPicked,
      // currentCardDesig,
      boldFont,
      fontUrl,
      boldFontUrl,
      meta,
      IMAGES_EXT,
      FONTS_EXT,
      canEdit,
      fontTabs,
      updateFontType: (type: FontType) => updateBrandingLocally(props.id, 'fontType', type),
      updateFunnelOnly: () => updateBrandingLocally(props.id, 'funnelOnly', values.funnelOnly),
      activeFont,
      activeBoldFont,
    }
  },
})
