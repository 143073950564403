
import { useModal } from '@/composables/useModal'
import { defineComponent, PropType, ref } from 'vue'
import ReadMoreTemplateModal from '@/views/campaigns/components/ReadMoreTemplateModal.vue'
import { formError } from '@/components/ui/styles'
import { wrapper, titleWrapper, addMoreWrapper } from '@/components/common/styles/AddReadMoreButton'
import { ReadMoreTemplate } from '@/types/models'
import { isEmpty } from 'lodash'

export default defineComponent({
  components: { ReadMoreTemplateModal },

  props: {
    saveActionName: {
      type: String,
      default: '',
    },
    readMoreTemplate: {
      type: Object as PropType<ReadMoreTemplate>,
      default: () => {
        return {}
      },
    },
    selectedLanguage: {
      type: String,
      default: '',
    },
    languages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    removeButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['updated-button-text', 'remove', 'template-saved'],

  setup() {
    const { isModalVisible, closeModal, showModal } = useModal()
    const readMoreError = ref('')
    const openModal = () => {
      showModal()
      // Also clean any previous error message regarding template
      readMoreError.value = ''
    }
    return {
      css: { formError },
      isEmpty,
      isModalVisible,
      closeModal,
      openModal,
      readMoreError,
      wrapper,
      titleWrapper,
      addMoreWrapper,
    }
  },
})
