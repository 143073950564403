
import { computed, defineComponent, onMounted, ref, Ref, watch, watchEffect } from 'vue'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import { useRouter } from 'vue-router'
import BckRadioGroup from '@/components/ui/BckRadioGroup.vue'
import { formError } from '@/components/ui/styles'
import { useFormState } from '@/composables/useFormState'
import { useStore } from '@/store'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { getLanguageFromKey } from '@/utils/languagesHelper'
import { CampaignsService } from '@/graphqlBackOffice/campaigns'
import { omit } from 'lodash'
import { OfferTemplateKey } from '@/types/models'

export default defineComponent({
  name: 'CreateEditCampaign',

  components: { BckRadioGroup },

  props: {
    id: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { push } = useRouter()
    const store = useStore()
    const pageError = ref('')
    const saving = ref(false)
    const { updateCampaign } = CampaignsService.useMutations()
    const cardDesign: Ref<string | undefined> = ref('')

    const { loading: loadingProject, project } = ProjectsService.getProjectById(props.id, 'cache-first')
    const languages: Ref<string[] | undefined> = ref([])

    watchEffect(() => {
      if (!loadingProject.value) {
        cardDesign.value = project.value?.branding?.cardDesign
        languages.value = project.value?.languages
      }
    })

    const marketingPlatforms = [
      {
        value: 'facebook',
        label: 'Facebook Ads',
        icon: {
          iconName: 'facebookAds',
          viewBox: '0 0 550 550',
        },
      },
      {
        value: 'google',
        label: 'Google Ads',
        icon: {
          iconName: 'googleAds',
          viewBox: '0 0 550 550',
        },
      },
    ]
    const goBack = () => {
      store.dispatch('campaignsModule/setNewCampaign', getUpdatedCampaign())
      push({ name: 'Funnel Manager', params: { action: 'save' } })
    }

    const customValidationSchema = yup.object().shape({
      campaignName: yup.string().required('This field is required.'),
      languages: yup.mixed().test('notEmpty', 'This field is required.', (value) => value && value.length),
      marketingPlatform: yup.mixed().required('This field is required.'),
      campaignType: yup.string().required('This field is required.'),
      verticalScrolling: yup.boolean(),
      showTermsOfService: yup.boolean(),
    })

    const { values, handleSubmit, errors, meta, setValues, setErrors } = useForm({
      validationSchema: customValidationSchema,
    })

    const { campaign, loading } = props.campaignId
      ? CampaignsService.getCampaignById(props.campaignId, 'cache-and-network')
      : {
          campaign: computed(() => store.state.campaignsModule.newCampaign),
          loading: ref(false),
        }

    const setInitialFormValues = () => {
      setValues({
        campaignName: campaign.value?.name,
        marketingPlatform: campaign.value?.platform,
        campaignType: campaign.value?.type,
        languages: campaign.value?.languages || [],
        verticalScrolling: cardDesign.value === 'e2eCard' ? true : !!campaign.value?.verticalScrolling,
        showTermsOfService: !!campaign.value?.showTermsOfService,
      })
    }

    if (!props.campaignId) {
      setInitialFormValues()
    } else {
      watch(
        () => loading.value,
        (loading) => {
          if (!loading) {
            if (!campaign.value) {
              push({ name: 'Funnel Manager' })
            }
            setInitialFormValues()
          }
        }
      )
    }

    onMounted(() => {
      // go back to previous step if not completed.
      store.dispatch('campaignsModule/setNewCampaign', {
        ...campaign.value,
        template: 'MIX' as OfferTemplateKey,
      })
    })

    const { useLeavingConfirmation, hasUnsavedChanges } = useFormState(meta, values)
    useLeavingConfirmation()

    const getUpdatedCampaign = () => {
      return {
        ...omit(campaign.value, 'status'),
        name: values.campaignName,
        projectId: props.id,
        languages: values.languages,
        type: values.campaignType,
        platform: values.marketingPlatform,
        verticalScrolling: values.verticalScrolling,
        showTermsOfService: values.showTermsOfService,
      }
    }

    const onSubmit = handleSubmit(async () => {
      try {
        const updatedRecord = getUpdatedCampaign()
        if (!props.campaignId) {
          const { newCampaign, networkErrorMessage, error } = await CampaignsService.createCampaign(updatedRecord)
          if (networkErrorMessage.value) {
            pageError.value = networkErrorMessage.value
          } else if (error) {
            if (error.message?.indexOf('duplicate key error') > -1) {
              setErrors({ campaignName: 'This name is already in use.' })
            } else {
              pageError.value = 'Something went wrong, please try again later'
            }
          } else {
            store.dispatch('campaignsModule/setNewCampaign', undefined)
            push({
              name: 'Funnels',
              params: { campaignId: newCampaign?._id, action: 'save' },
            })
          }
        } else {
          if (hasUnsavedChanges.value) {
            await updateCampaign.mutate({
              filter: { _id: props.campaignId },
              record: omit(updatedRecord, ['createdAt', 'funnelsText', '_id']),
            })
          }
          push({
            name: 'Funnels',
            params: { campaignId: props.campaignId, action: 'save' },
          })
        }
      } catch (err) {
        pageError.value = err?.response?.data?.error || 'Something went wrong, please try again later.'
      } finally {
        saving.value = false
      }
    })

    return {
      onSubmit,
      goBack,
      getLanguageFromKey,
      values,
      errors,
      meta,
      css: { formError },
      languages,
      loading,
      marketingPlatforms,
      campaignTypes: [
        { value: 'REMARKETING', label: 'Remarketing' },
        { value: 'ENGAGEMENT', label: 'Engagement' },
        { value: 'ACQUISITION', label: 'Acquisition' },
        { value: 'RETARGETING', label: 'Retargeting' },
      ],
      campaign,
      pageError,
      saving,
      cardDesign,
    }
  },
})
