import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryFlag = _resolveComponent("CountryFlag")!
  const _component_BckMultiselect = _resolveComponent("BckMultiselect")!

  return (_openBlock(), _createBlock(_component_BckMultiselect, {
    searchable: "",
    placeholder: _ctx.placeholder,
    mode: "tags",
    options: _ctx.languages,
    name: _ctx.name,
    values: _ctx.selectedLanguages,
    "close-on-select": false,
    margin: _ctx.margin,
    max: _ctx.max,
    "label-offset": ['-10px', '-25px'],
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event)))
  }, {
    option: _withCtx(({ option }) => [
      _createVNode(_component_CountryFlag, {
        country: option.flagKey
      }, null, 8, ["country"]),
      _createTextVNode(" " + _toDisplayString(option.label), 1)
    ]),
    _: 1
  }, 8, ["placeholder", "options", "name", "values", "margin", "max"]))
}