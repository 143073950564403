import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["name", "disabled"]
const _hoisted_2 = ["value", "name", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckLabel = _resolveComponent("BckLabel")!
  const _component_BckGrid = _resolveComponent("BckGrid")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BckGrid, { "align-items": "center" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.css.labelWrapper)
        }, [
          _createVNode(_component_BckLabel, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          })
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.css.colorPicker)
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            type: "text",
            autocomplete: "off",
            name: _ctx.name,
            class: _normalizeClass([
            _ctx.inputStyle({ hasValue: true }),
            _ctx.meta.dirty && _ctx.errorMessage ? `${_ctx.dangerText}` : '',
          ]),
            disabled: _ctx.disabled,
            onFocusout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocusout && _ctx.handleFocusout(...args)))
          }, null, 42, _hoisted_1), [
            [_vModelText, _ctx.inputValue]
          ]),
          _createElementVNode("input", {
            value: _ctx.inputValue,
            name: _ctx.name,
            disabled: _ctx.disabled,
            type: "color",
            onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.colorChanged && _ctx.colorChanged(...args)))
          }, null, 40, _hoisted_2)
        ], 2)
      ]),
      _: 1
    }),
    (_ctx.meta.dirty && _ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.formError)
        }, _toDisplayString(_ctx.errorMessage), 3))
      : _createCommentVNode("", true)
  ]))
}