import { style } from 'typestyle'

export const integrationCardContainer = style({
  width: '540px',
  height: '244px',
  padding: '40px 26px',
  alignItems: 'center',
  gap: '24px',
  borderRadius: '20px',
  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
  background: '#fff',
  display: 'flex',
})

export const groupCardTitle = style({
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '8px',
})

export const groupCardSubtitle = style({
  marginBottom: '24px',
})
export const groupCardButton = style({
  alignContent: 'center',
})
export const wrapper = style({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  gap: '24px',
})

export const imageCard = style({
  display: 'flex',
  alignItems: 'center',
  padding: '40px',
  $nest: {
    img: {
      width: '120px',
      height: '120px',
    },
  },
})

export const imageCardIntegration = style({
  width: '120px',
  height: '120px',
  display: 'flex',
  marginRight: '57px',
  alignItems: 'center',
  $nest: {
    img: {
      width: '120px',
      height: '120px',
    },
  },
})
