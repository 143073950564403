import { useAction } from '@/graphqlBackOffice/composables'

import { UPDATE_PWA_CONFIGURATION, transformPwaConfigurationForApi } from '.'
import { PwaConfiguration } from '@/types/models/pwaConfiguration'

class PwaConfigurationService {
  public updatePwaConfiguration(pwaConfiguration: PwaConfiguration) {
    const { mutate } = useAction(UPDATE_PWA_CONFIGURATION)
    return mutate({
      pwaConfiguration: transformPwaConfigurationForApi(pwaConfiguration),
    })
  }
}

export default new PwaConfigurationService()
