
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import {
  colorPickerStyle as css,
  dangerText,
  inputStyle,
  formError,
} from '@/components/ui/styles'
import * as yup from 'yup'
import { getColor } from '@/utils'

export default defineComponent({
  name: 'BckColorPicker',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta,
    } = useField(
      props.name,
      yup.string().test('colorCode', 'Color code is not valid', (value) => {
        return !!value?.match(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i)
      }),
      {
        initialValue: props.value || getColor('black'),
      }
    )

    const colorChanged = ($event: Event) => {
      emit('change', $event)
      handleChange($event)
    }

    const handleFocusout = ($event: Event) => {
      emit('change', $event)
      handleChange($event)
    }

    return {
      css,
      formError,
      inputStyle,
      handleFocusout,
      dangerText,
      colorChanged,
      errorMessage,
      inputValue,
      meta,
    }
  },
})
