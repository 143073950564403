
import { computed, defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import { useModal } from '@/composables/useModal'
import { dashboardEnvGroup, dashboardImage } from '../styles'
import * as cssConfig from '../styles/ProjectConfiguration'
import {
  projectStepStyle as css,
  title,
  content,
  stepsSubtitle,
  stepsLabel,
} from '../styles'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import ConfigurationStep from '@/views/project/components/ConfigurationStep.vue'
import { getProjectLogo } from '@/graphqlBackOffice/projects/transformers'
import { useSeamlessUrls } from '@/composables/useSeamlessUrls'
import { formError } from '@/components/ui/styles'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'

interface DomainFormType {
  domain: string
  email: string
}

export default defineComponent({
  components: { ConfigurationStep },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { isModalVisible, closeModal } = useModal()
    const {
      isModalVisible: isDomainModalVisisble,
      closeModal: closeDomainModal,
    } = useModal()
    const router = useRouter()
    const { deleteFFSocialDomainConfig, updateFFSocialDomainStatus } =
      ProjectsService.useMutations()
    const canOpenLinks = isAuthorized(Permissions.OPEN_PROJECT_LINKS, props.id)

    const { loading, project, refetchProject } = ProjectsService.getProjectById(
      props.id,
      undefined,
      'cache-and-network'
    )
    const { productDefaultLanguage } =
      ProductBuilderService.getProjectDefaultLanguage(props.id)
    const domainName = ref('')
    const containerId = ref('')
    const isSubmitting = ref(false)
    const isDomainRequestSubmitting = ref(false)
    const loadingCounter = ref(1)
    const isdomainFormError = ref(false)

    const configurationStatus = ref({
      aws: false,
      gtm: false,
      // firebase: false,
      seamless: false,
    })
    const { envs } = useSeamlessUrls(props.id, project)
    const configuredSucceeded = ref()
    let intervalId = 0

    const domainFormValidationSchema = yup.object().shape({
      domain: yup.string().required('This field is required.'),
      email: yup.string().email().required('This field is required.'),
    })

    const { handleSubmit, errors, resetForm, setErrors } = useForm({
      validationSchema: domainFormValidationSchema,
    })

    const hasErrors = computed(() => Object.keys(errors.value).length > 0)

    const onCloseDomainModal = () => {
      closeDomainModal()
      resetForm()
      // The resetForm function somehow doesn't reset errors state.. so we are manually resetting errors in next line
      setErrors({
        email: ['This field is required.'],
        domain: ['This field is required.'],
      })
      isdomainFormError.value = false
    }

    const onDomainFormSubmit = handleSubmit(async (values: DomainFormType) => {
      isDomainRequestSubmitting.value = true
      isdomainFormError.value = false
      try {
        onCloseDomainModal()
      } catch (error) {
        isdomainFormError.value = true
      } finally {
        isDomainRequestSubmitting.value = false
      }
    })

    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          if (!project.value?.domain?.productionDomainConfigurationId) {
            router.push({ name: 'Configuration Domain Name' })
          }

          configuredSucceeded.value =
            project.value?.settings.configuration || false

          if (configuredSucceeded.value) {
            domainName.value =
              project.value?.domain?.productionDomainConfiguration
                ?.domainName || ''

            containerId.value = ''

            clearInterval(intervalId)
          } else {
            intervalId = setInterval(() => {
              if (loadingCounter.value === 23) {
                loadingCounter.value = 0
              }
              if (!pageLoading.value) {
                loadingCounter.value++
              }
            }, 1000)
          }
        }
      }
    )

    watch(
      () => loadingCounter.value,
      (loadingCounter) => {
        // Retry automatically after 23 seconds
        if (loadingCounter === 23) {
          retryConfig()
        }
      }
    )

    const logo = computed(() =>
      project.value ? getProjectLogo(project.value) : ''
    )

    const onSubmit = async () => {
      isModalVisible.value = false
      isSubmitting.value = true
      const productionRemoval = await deleteFFSocialDomainConfig.mutate({
        id: project.value?.domain?.productionDomainConfiguration?._id,
      })
      const previewRemoval = await deleteFFSocialDomainConfig.mutate({
        id: project.value?.domain?.previewDomainConfiguration?._id,
      })
      isSubmitting.value = false
      if (
        !productionRemoval?.data.deleteFFSocialDomain.error &&
        !previewRemoval?.data.deleteFFSocialDomain.error
      ) {
        router.push({ name: 'Configuration Domain Type' })
      }
    }
    const retryConfig = async () => {
      try {
        isSubmitting.value = true
        const response = await updateFFSocialDomainStatus.mutate({
          productionId: project.value?.domain?.productionDomainConfigurationId,
          previewId: project.value?.domain?.previewDomainConfigurationId,
          domainId: project.value?.domain?._id,
          defaultLanguage: project.value?.productId.defaultLanguage,
        })
        const status = response?.data.updateFFSocialDomainStatus.status
        configurationStatus.value.aws = status.aws.success
        configurationStatus.value.gtm = status.gtm.success
        configurationStatus.value.seamless =
          status.template.success && status.publish.success

        if (
          response?.data.updateFFSocialDomainStatus.record.status === 'INSYNC'
        ) {
          refetchProject()
        }

        if (status.template.success && status.publish.success) {
          router.push({ name: 'Project', params: { id: props.id } })
        }
      } catch (e) {
        console.log(e)
      } finally {
        isSubmitting.value = false
      }
    }

    const pageLoading = computed(
      () =>
        loading.value ||
        isSubmitting.value ||
        typeof configuredSucceeded.value === 'undefined'
    )

    const onRedirect = (url: string) => {
      window.open(`https://${url}`, '_blank')
    }

    return {
      loading,
      envs,
      configuredSucceeded,
      dashboardEnvGroup,
      dashboardImage,
      project,
      logo,
      domainName,
      containerId,
      title,
      content,
      stepsSubtitle,
      stepsLabel,
      onSubmit,
      retryConfig,
      isSubmitting,
      isModalVisible,
      closeModal,
      css,
      cssConfig,
      loadingCounter,
      configurationStatus,
      pageLoading,
      isDomainModalVisisble,
      onCloseDomainModal,
      formError,
      isdomainFormError,
      onDomainFormSubmit,
      hasErrors,
      isDomainRequestSubmitting,
      canOpenLinks,
      onRedirect,
      productDefaultLanguage,
    }
  },
})
