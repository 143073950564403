
import { defineComponent, watch, reactive, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useTabs } from '@/composables/useTabs'
import { useModal } from '@/composables/useModal'
import { useTableView } from '@/composables/useTableView'
import { adminPage } from '@/views/user/styles'
import { tablePanel } from '@/components/ui/styles'
import { roles, statuses } from '@/utils/constants'
import UsersService from '@/graphqlBackOffice/users/service'
import UserFormModal from '@/views/user/components/UserFormModal.vue'
import InvitationsTable from '@/views/user/components/InvitationsTable.vue'
import UsersTable from '@/views/user/components/UsersTable.vue'
import DeleteSelectedItemsButton from '@/components/common/DeleteSelectedItemsButton.vue'
import { useQueryFilters } from '@/graphqlBackOffice/composables'
import { Permissions } from '@/services/auth/permissions'
import { isAuthorized } from '@/services/auth/authService'

/* eslint-disable @typescript-eslint/no-explicit-any */

export default defineComponent({
  components: {
    DeleteSelectedItemsButton,
    UsersTable,
    InvitationsTable,
    UserFormModal,
  },
  setup() {
    const state = reactive({
      users: {},
      invites: {},
      usersTableLoading: {},
      invitesTableLoading: {},
    })
    let refetchUsersQuery: any = undefined
    let refetchInvitationsQuery: any = undefined

    const canInviteUsers = isAuthorized(Permissions.INVITE_USERS_TO_PLATFORM)
    const canChangeUserRole = isAuthorized(
      Permissions.CHANGE_PLATFORM_USER_ROLES
    )

    const { deleteInvitations, deleteUsers } = UsersService.useMutations()
    const { currentRoute } = useRouter()
    const {
      isModalVisible: isInvitationModalVisible,
      closeModal: closeInvitationModal,
      showModal: showInvitationModal,
    } = useModal()

    const {
      queryInitialValues,
      getFiltersFromCurrentRoute,
      selectedItems,
      toggleSelectedItems,
      onSearchInputChange,
      onTableSort,
    } = useTableView()

    const { changeSelectedTab, selectedTab } = useTabs(
      queryInitialValues.selectedTab || 'users',
      true
    )

    /**
     * Fetches paginated, filtered and sorted users or invitations
     * not assigned on any projects.
     */
    const getUsersOrInvites = () => {
      const tableFilters = getFiltersFromCurrentRoute()

      if (selectedTab.value === 'users') {
        if (!refetchUsersQuery) {
          const { loading, refetchUsers, result } =
            UsersService.getUsers(tableFilters)
          state.usersTableLoading = loading
          state.users = result || { items: [], count: 0 }
          refetchUsersQuery = refetchUsers
        } else {
          refetchUsersQuery(tableFilters)
        }
      } else {
        if (!refetchInvitationsQuery) {
          const { loading, refetchInvitations, result } =
            UsersService.getInvitations(tableFilters)
          state.invitesTableLoading = loading
          state.invites = result || { items: [], count: 0 }
          refetchInvitationsQuery = refetchInvitations
        } else {
          refetchInvitationsQuery(tableFilters)
        }
      }
    }

    watch(currentRoute, getUsersOrInvites)

    onMounted(() => {
      getUsersOrInvites()
    })

    const deleteItems = async () => {
      const { computedOperator } = useQueryFilters()

      if (selectedTab.value === 'users') {
        await deleteUsers.mutate({
          filter: computedOperator(
            '_id',
            'in',
            selectedItems.value[selectedTab.value].map((item) => item._id)
          ),
        })
      } else {
        await deleteInvitations.mutate({
          filter: computedOperator(
            'userEmail',
            'in',
            selectedItems.value[selectedTab.value].map((item) => item.userEmail)
          ),
        })
      }
      getUsersOrInvites()
      selectedItems.value[selectedTab.value] = []
    }

    const deleteButtonProps = computed(() => {
      return {
        selectedItems: selectedItems.value?.[selectedTab.value] || [],
        fieldName: selectedTab.value === 'users' ? 'username' : 'userEmail',
        entityNameSingular:
          selectedTab.value === 'users' ? 'user' : 'invitation',
        entityNamePlural:
          selectedTab.value === 'users' ? 'users' : 'invitations',
        loadingDelete:
          deleteUsers.loading.value || deleteInvitations.loading.value,
      }
    })

    return {
      css: { adminPage, tablePanel },
      queryInitialValues,
      state,
      changeSelectedTab,
      selectedTab,
      roles,
      statuses,
      onTableSort,
      onSearchInputChange,
      showInvitationModal,
      closeInvitationModal,
      isInvitationModalVisible,
      selectedItems,
      deleteItems,
      deleteButtonProps,
      getUsersOrInvites,
      toggleSelectedItems,
      canInviteUsers,
      canChangeUserRole,
      tabsData: [
        { key: 'users', name: 'Users' },
        { key: 'invites', name: 'Invites' },
      ],
    }
  },
})
