import { Invitation, User } from '@/types/models/user'
import dayjs from 'dayjs'
import { filter, map, omit, pick } from 'lodash'

export const transformInvitation = (invitation: Invitation): Invitation => {
  return {
    ...invitation,
    updatedAt: invitation.updatedAt
      ? dayjs(invitation.updatedAt).format('DD.MM.YYYY') +
        ' at ' +
        dayjs(invitation.updatedAt).format('HH:mm')
      : '',
  }
}

export const transformInvitations = (
  items: Invitation[] | null
): Invitation[] => {
  return items?.map(transformInvitation) || []
}

export const formatProjectInvitation = (record: Invitation) => {
  return {
    position: record.position,
    projectIds: record.projectIds || [],
    projectRoles: record.projectRoles || [],
    role: record.role,
    userEmail: record.userEmail,
  }
}

export const removeProjectFromUser = (record: any, projectId: string) => {
  return {
    ...omit(record, ['_id', '__typename', 'createdAt', 'updatedAt', 'status']),
    projectIds: filter(
      record.projectIds,
      (userProjectId) => userProjectId !== projectId
    ),
    projectRoles: map(
      filter(
        record.projectRoles,
        (projectRole) => projectRole.projectId !== projectId
      ),
      (projectRole) => omit(projectRole, '__typename')
    ),
  }
}
