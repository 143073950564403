
import { computed, defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import SeamlessPreview from '@/components/common/SeamlessPreview.vue'
import { rightContent, leftContent } from '@/views/campaigns/styles'
import { useStore } from '@/store'
import { CampaignsService } from '@/graphqlBackOffice/campaigns'
import { capitalizeFirstLetter } from '@/utils/textHelpers'
import { getFirstTranslationOfField, getLanguageFromKey } from '@/utils/languagesHelper'
import BckContentCard from '@/components/ui/BckContentCard.vue'
import { pageContentLoading } from '@/components/common/styles'
import { translateNavigationCard } from '@/graphqlBackOffice/productBuilder'
import { NavigationCard } from '@/types/models'
import { useErrors } from '@/graphqlBackOffice/composables/useErrors'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'
import ProjectsService from '@/graphqlBackOffice/projects/service'

export default defineComponent({
  components: { BckContentCard, SeamlessPreview },

  props: {
    id: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
    funnelId: {
      type: String,
      default: '',
    },
    defaultLanguage: {
      type: String,
      required: false,
    },
  },

  setup(props) {
    const { push } = useRouter()
    const error = ref('')
    const saving = ref(false)
    const currentAction = ref('')
    const store = useStore()
    const defaultLanguageOption = computed(() => props.defaultLanguage || '')
    const { campaign, loading: loadingCampaign } = CampaignsService.getCampaignById(props.campaignId, 'cache-first')
    let funnel = computed(() => store.state.campaignsModule.funnel)
    let loadingFunnel = ref(false)
    if ((!funnel.value || (funnel.value && !funnel.value.productionLink)) && props.funnelId) {
      const { funnel: funnelById, loading } = CampaignsService.getFunnelById(props.funnelId, 'cache-and-network')

      funnel = funnelById
      loadingFunnel = loading
    }

    const { project } = ProjectsService.getProjectById(props.id)

    const { publishFunnels } = CampaignsService.useMutations()

    const languagesText = computed(
      () =>
        campaign.value?.languages &&
        `${campaign.value?.languages?.length > 1 ? 'Multi Language ' : 'Single Language'} - ${campaign.value?.languages
          ?.map((lang) => getLanguageFromKey(lang))
          ?.join(', ')}`
    )

    const goToFunnelsPage = () => {
      push({ name: 'Funnels' })
    }

    const saveDraft = async (action = 'draft', redirectToFunnels = true) => {
      currentAction.value = action
      saving.value = true

      if (funnel.value) {
        const { mutation, networkErrorMessage } = CampaignsService.saveFunnel(
          funnel.value,
          props.campaignId,
          campaign.value?.name || '',
          props.id
        )

        return (
          mutation
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((response: any) => {
              if (redirectToFunnels) {
                store.dispatch('campaignsModule/setFunnel', undefined)
                goToFunnelsPage()
              } else {
                return response?.data?.saveFunnel
              }
            })
            .catch(() => {
              error.value =
                networkErrorMessage.value?.indexOf('duplicate key error') > -1
                  ? 'The funnel name is already used inside this campaign. Please change the name.'
                  : 'Something went wrong, please try again later'
            })
            .finally(() => {
              saving.value = false
            })
        )
      }
    }

    const publish = async () => {
      if (!funnel.value) {
        error.value = 'Something went wrong, please try again later.'
        return
      }
      currentAction.value = 'publish'
      if (!funnel.value._id) {
        const newFunnel = await saveDraft('publish', false)
        if (!newFunnel) {
          return
        }
        funnel.value._id = newFunnel?._id
        funnel.value.status = 'PUBLISHED'
      }
      error.value = ''
      saving.value = true
      const { mutate: mutatePublish, error: mutationError } = publishFunnels
      try {
        await mutatePublish({ funnelIds: [funnel.value._id] })
        goToFunnelsPage()
      } catch (err) {
        const { networkErrorMessage } = useErrors(mutationError)
        error.value = networkErrorMessage.value
      } finally {
        saving.value = false
      }
    }

    const edit = () => {
      if (props.funnelId) {
        push({ name: 'Edit Funnel' })
      } else {
        push({ name: 'Create Funnel' })
      }
    }

    /**
     * Seamless preview cards.
     */
    const previewCards = computed(() =>
      funnel.value?.cards?.map((card) => {
        if (card.type === 'offer' || card.type === 'leadForm' || card.type === 'trafficCard') {
          return card
        }

        return translateNavigationCard(card as NavigationCard, campaign.value?.languages?.[0] || 'en')
      })
    )

    const projectBackgroundImageComputed = computed(() => {
      const projectBackgroundImageAsset = project.value?.branding?.assets?.find(
        (asset) => asset.resourceType === 'projectBackgroundImage'
      )
      return projectBackgroundImageAsset ? projectBackgroundImageAsset.resourcePath : null
    })

    const cardDesign = computed(() => {
      return project.value?.branding?.cardDesign
    })

    const isPublished = computed(() => funnel.value?.status === 'PUBLISHED')

    const canPublish = isAuthorized(Permissions.PUBLISH_DRAFT_FUNNEL, props.id)
    const canEdit = isAuthorized(Permissions.EDIT_FIELDS_ON_DRAFT_FUNNEL, props.id)
    return {
      projectBackgroundImageComputed,
      cardDesign,
      campaign,
      capitalizeFirstLetter,
      css: { rightContent, leftContent, pageContentLoading },
      currentAction,
      error,
      funnel,
      getFirstTranslationOfField,
      goToFunnelsPage,
      edit,
      languagesText,
      loading: computed(() => loadingCampaign.value || loadingFunnel.value),
      previewCards,
      publish,
      saveDraft,
      saving,
      isPublished,
      canPublish,
      canEdit,
      project,
      defaultLanguageOption,
    }
  },
})
