
import { defineComponent, ref, computed, Ref, watch } from 'vue'
import * as yup from 'yup'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { useRouter } from 'vue-router'
import PwaConfigurationService from '@/graphqlBackOffice/pwaConfiguration/service'
import { useForm } from 'vee-validate'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'
import { isImage, testFileSize } from '@/utils/fieldsValidations'
import { formError } from '@/components/ui/styles'

import { pwaConfigurationStyle as css } from '../styles'

import LanguageTabs from '@/components/common/LanguageTabs.vue'
import { useTranslatableFields } from '@/composables/useTranslatableFields'
import { map, size } from 'lodash'
import { IMAGES_EXT } from '@/utils/constants'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

/* eslint-disable @typescript-eslint/no-explicit-any */

export default defineComponent({
  components: { LanguageTabs },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { loading, project } = ProjectsService.getProjectById(props.id)

    const router = useRouter()
    // const canEdit = isAuthorized(Permissions.EDIT_PROJECT_PWA_CONFIG, props.id)
    const canEdit = false // disabled for now

    const {
      isAllFormTranslated,
      copyTranslationFromSavedEntity,
      translatedFields,
      updateTranslation,
      getTranslationsForFields,
    } = useTranslatableFields()

    const { pages } = ProductBuilderService.getProductPages(props.id)

    const translatableFields = ['appAlreadyInstalled', 'webapp']

    const requiredTranslatableFields = ['appAlreadyInstalled']
    const languages: Ref<string[] | undefined> = ref([])

    const selectedPages: Ref<string[]> = ref([])

    const selectedLanguage = ref('en')
    const pageError = ref('')

    const pagesOptions = computed(() =>
      map(pages?.value, (page) => ({
        value: page._id,
        label: page.name,
      }))
    )

    const changeLanguage = (lang: string) => {
      pageError.value = ''
      selectedLanguage.value = lang
      setValues({
        ...values,
        ...translatedFields(translatableFields, [], lang),
      })
    }

    const onSelectingPage = (value: string[]) => (selectedPages.value = value)

    const saving = ref(false)

    const pwaConfigurationValidation = yup.object().shape({
      appAlreadyInstalled: yup.string().required('This field is required.'),
      webapp: yup.string(),
      pwaIcon: yup
        .mixed()
        .required('This field is required.')
        .test(
          'fileSize',
          'File is too large',
          (value) =>
            !(
              value?.[0] &&
              typeof value?.[0] === 'object' &&
              !testFileSize(value?.[0])
            )
        )
        .test(
          'fileType',
          'Unsupported file type',
          (value) =>
            !(
              value?.[0] &&
              typeof value?.[0] === 'object' &&
              !isImage(value?.[0])
            )
        ),
    })

    const { setValues, values, handleSubmit, meta } = useForm({
      validationSchema: pwaConfigurationValidation,
    })

    const onSubmit = handleSubmit(async () => {
      if (
        size(languages.value) > 1 &&
        !isAllFormTranslated(requiredTranslatableFields, languages.value || [])
      ) {
        pageError.value =
          'Some required fields are empty. Please check all language tabs.'
        return
      }

      try {
        saving.value = true
        await PwaConfigurationService.updatePwaConfiguration({
          _id: project?.value?.pwaConfiguration._id,
          projectName: project?.value?.name,
          pwaIcon: values.pwaIcon[0],
          projectId: props.id,
          pwaTriggers: selectedPages.value,
          popupDescription: {
            ...getTranslationsForFields(translatableFields),
          },
        })

        // go to meta description page

        isAuthorized(Permissions.ACCESS_PROJECT_AUTHORISATION, props.id)
          ? router.push({ name: 'Authorization', params: { action: 'save' } })
          : router.push({
              name: 'Advanced',
              params: { action: 'save' },
            })
      } catch (error) {
        pageError.value =
          error?.response?.data?.error ||
          'Something went wrong, please try again later.'
      } finally {
        saving.value = false
      }
    })

    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          languages.value = project.value?.languages
          selectedPages.value = project.value?.pwaConfiguration?.pwaTriggers
            ? project.value?.pwaConfiguration?.pwaTriggers
            : []
          selectedLanguage.value = project.value?.languages[0] || 'en'

          copyTranslationFromSavedEntity(
            project.value?.pwaConfiguration.popupDescription,
            translatableFields
          )

          if (project.value?.pwaConfigurationSet) {
            setValues({
              ...values,
              pwaIcon: project.value?.pwaConfiguration?.asset?.resourcePath
                ? project.value?.pwaConfiguration?.asset?.resourcePath
                : '',
              ...translatedFields(
                translatableFields,
                [],
                selectedLanguage?.value
              ),
            })
          }
        }
      }
    )

    const goBack = () => {
      isAuthorized(Permissions.ACCESS_PROJECT_CONFIG, props.id)
        ? router.push({ name: 'Configuration' })
        : router.push({
            name: 'Meta Information',
          })
    }

    return {
      loading,
      project,
      saving,
      values,
      pagesOptions,
      languages,
      updateTranslation,
      selectedLanguage,
      formError,
      changeLanguage,
      goBack,
      onSubmit,
      css,
      pageError,
      selectedPages,
      onSelectingPage,
      size,
      meta,
      IMAGES_EXT,
      canEdit,
    }
  },
})
