import axios from 'axios'

export const getProject = async (projectId: string) => {
  const { data } = await axios.post('/api/project/get', { projectId })

  return data[0]
}

export const updateCardDesign = async (projectId: string, cardDesign: string) => {
  await axios.post(`/api/project/updateCardDesign`, { projectId, cardDesign })
}
