/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { transformTranslationsForApi } from '@/utils/languagesHelper'
import { isObject } from 'lodash'

export const transformPwaConfigurationForApi = (pwaConfiguration: any) => {
  return {
    _id: pwaConfiguration._id,
    pwaTriggers: pwaConfiguration.pwaTriggers,
    projectName: pwaConfiguration.projectName,
    projectId: pwaConfiguration.projectId,
    ...(isObject(pwaConfiguration?.pwaIcon) && {
      pwaIcon: pwaConfiguration?.pwaIcon,
    }),
    popupDescription: {
      appAlreadyInstalled: transformTranslationsForApi(
        pwaConfiguration?.popupDescription?.appAlreadyInstalled?.translations
      ),
      notificationsEnabledSuccesfully: transformTranslationsForApi(
        pwaConfiguration?.popupDescription?.notificationsEnabledSuccesfully
          ?.translations
      ),
      notificationsDeclined: transformTranslationsForApi(
        pwaConfiguration?.popupDescription?.notificationsDeclined?.translations
      ),
      webapp: transformTranslationsForApi(
        pwaConfiguration?.popupDescription?.webapp?.translations
      ),
    },
  }
}
