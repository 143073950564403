
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { loading, tableCell, tableRow } from '@/components/ui/styles'
import { useTableView } from '@/composables/useTableView'
import { useModal } from '@/composables/useModal'
import { roles, projectRoles, statuses } from '@/utils/constants'
import UserFormModal from '@/views/user/components/UserFormModal.vue'
import { ProjectRole, User } from '@/types/models'
import { getSessionValue } from '@/graphqlBackOffice/auth/session'
import { roleColorMap } from '@/utils/colorMapper'

export default defineComponent({
  components: { UserFormModal },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    // If set, the table is showing users from this project, otherwise it shows all users.
    projectId: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    selectedUsers: {
      type: Array,
      default: () => [],
    },
    canEditUsers: {
      type: Boolean,
      default: false,
    },
    canChangeUserRole: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['toggle-user', 'change'],

  setup(props) {
    const currentUser = getSessionValue('user')
    const editedUser = ref()
    const { onTableSort, queryInitialValues } = useTableView()
    const { isModalVisible, showModal, closeModal } = useModal()

    const openEditUser = (row: User) => {
      if (!props.canEditUsers) {
        return
      }

      editedUser.value = row
      showModal()
    }

    /**
     * If showing the project's users, show the user role within the project,
     * otherwise show the main user role
     */
    const getUserRole = (row: User) => {
      if (!props.projectId) {
        return row.role
      }
      const router = useRoute()
      return (
        row.projectRoles?.find(
          (item: ProjectRole) => item.projectId === router.params.id
        )?.role || row.role
      )
    }

    const isCurrentUser = computed(
      () => editedUser.value?._id === currentUser._id
    )
    return {
      roleColorMap,
      currentUser,
      isCurrentUser,
      editedUser,
      openEditUser,
      getUserRole,
      closeModal,
      queryInitialValues,
      onTableSort,
      roles: { ...roles, ...projectRoles },
      statuses,
      isModalVisible,
      usersTableCells: [
        { name: 'select', label: '', width: '5%' },
        {
          name: 'username',
          label: 'User Name',
          width: '15%',
          sortable: true,
        },
        !props.projectId
          ? {
              name: 'project',
              label: 'Project',
              width: '10%',
            }
          : null,
        {
          name: 'role',
          label: 'Role',
          width: '15%',
          sortable: true,
        },
        {
          name: 'country',
          label: 'Country',
          width: '10%',
          sortable: true,
        },
        {
          name: 'position',
          label: 'Position',
          width: '15%',
          sortable: true,
        },
        {
          name: 'email',
          label: 'E-mail',
          width: '20%',
          sortable: true,
        },
        {
          name: 'status',
          label: 'Status',
          width: '10%',
          sortable: true,
        },
      ].filter((item) => !!item),
      css: { tableRow, tableCell, loading },
    }
  },
})
