import { style } from 'typestyle'

export const toggleContainer = style({
  display: 'flex',
  marginTop: '32px',
  width: '480px',
  justifyContent: 'space-between',
})

export const toggle = style({
  display: 'flex',
  alignItems: 'center',
})
