import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckOfferCard = _resolveComponent("BckOfferCard")!
  const _component_BckButton = _resolveComponent("BckButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.individualOfferStyle.card(_ctx.margin, _ctx.active, _ctx.disabled))
  }, [
    _createVNode(_component_BckOfferCard, {
      "show-template-name": "",
      "template-key": _ctx.templateKey,
      margin: "auto",
      disabled: _ctx.disabled
    }, null, 8, ["template-key", "disabled"]),
    _createVNode(_component_BckButton, {
      "button-style": "secondary",
      size: "small",
      margin: "auto",
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('card-selected')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ], 2))
}