import { style } from 'typestyle'

export const iframe = style({
  width: '100%',
  border: 0,
})

export const loading = style({
  margin: '150px auto',
  display: 'flex !important',
})
