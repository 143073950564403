import gql from 'graphql-tag'

export const SEND_INVITATION = gql`
  mutation InviteUserMutation($record: CreateOneUserInvitationInput!) {
    inviteUser(record: $record) {
      recordId
      record {
        userEmail
        role
      }
      error {
        message
      }
    }
  }
`

export const RESEND_INVITATION = gql`
  mutation Mutation(
    $record: UpdateOneResendInvitationInput!
    $filter: FilterUpdateOneResendInvitationInput
  ) {
    resendUserInvitation(record: $record, filter: $filter) {
      record {
        userEmail
        role
        status
      }
    }
  }
`

export const DELETE_USERS = gql`
  mutation RemoveUsersMutation($filter: FilterRemoveManyUserInput!) {
    deleteUsers(filter: $filter) {
      numAffected
    }
  }
`

export const DELETE_INVITATIONS = gql`
  mutation RemoveInvitationsMutation(
    $filter: FilterRemoveManyUserInvitationInput!
  ) {
    removeUserInvitations(filter: $filter) {
      numAffected
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($id: MongoID!, $record: UpdateByIdUserInput!) {
    updateUser(_id: $id, record: $record) {
      record {
        _id
        role
        username
        email
      }
    }
  }
`

export const UPDATE_USER_INVITATION = gql`
  mutation UpdateUserInvitation(
    $id: MongoID!
    $record: UpdateByIdUserInvitationInput!
  ) {
    updateUserInvitation(_id: $id, record: $record) {
      record {
        _id
        role
        userEmail
      }
    }
  }
`
