import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_BckIndividualOfferCard = _resolveComponent("BckIndividualOfferCard")!
  const _component_BckPagination = _resolveComponent("BckPagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.offersStyle.individualContainer)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.individualOfferStyle.card('no small small no')]),
        style: _normalizeStyle(_ctx.individualOfferStyle.addNewButtonStyle),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-offer')))
      }, [
        _createVNode(_component_BckIcon, {
          class: _normalizeClass(_ctx.individualOfferStyle.addNewButtonSvg),
          "icon-name": _ctx.iconName,
          "view-box": "0 0 70 65",
          fill: "none"
        }, null, 8, ["class", "icon-name"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.textClassName),
          style: { lineHeight: '25px' }
        }, " Create New " + _toDisplayString(_ctx.startCase(_ctx.entityLabel.toLowerCase())), 3)
      ], 6),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offers, (offer, i) => {
        return (_openBlock(), _createBlock(_component_BckIndividualOfferCard, {
          key: i,
          offer: offer,
          "entity-label": _ctx.entityLabel,
          margin: "no small small no",
          "can-delete-offer": _ctx.canDeleteOffer,
          "can-duplicate-offer": _ctx.canDuplicateOffer,
          onEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('edit-offer', $event))),
          onDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('delete-offer', $event))),
          onDuplicate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('duplicate-offer', $event)))
        }, null, 8, ["offer", "entity-label", "can-delete-offer", "can-duplicate-offer"]))
      }), 128))
    ], 2),
    (!_ctx.loading && _ctx.pageInfo?.itemCount > 12)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.offersStyle.footer)
        }, [
          _createVNode(_component_BckPagination, {
            "total-results": _ctx.pageInfo?.itemCount,
            "limit-per-page": 12,
            "max-pagination-items": 6
          }, null, 8, ["total-results"])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}