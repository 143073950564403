
import { computed, defineComponent, Ref, ref, watch, watchEffect } from 'vue'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import { DateRange } from '@/types/models'
import SeamlessPreview from '@/components/common/SeamlessPreview.vue'
import { pageContentLoading } from '@/components/common/styles'
import { useRouter } from 'vue-router'
import FunnelSchedule from '@/views/campaigns/components/FunnelSchedule.vue'
import { useFormState } from '@/composables/useFormState'
import { useStore } from '@/store'
import CardsSelection from '@/components/common/CardsSelection.vue'
import LanguageTabs from '@/components/common/LanguageTabs.vue'
import { useTranslatableFields } from '@/composables/useTranslatableFields'
import { CampaignsService } from '@/graphqlBackOffice/campaigns'
import { CarouselPageCard } from '@/types/components/UiTypes'
import { formError } from '@/components/ui/styles'
import { add as addToDate } from 'date-fns'
import { filter, isEmpty, size, values as arrayValues } from 'lodash'
import { scrollTo } from '@/utils/animations'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'
import ProjectsService from '@/graphqlBackOffice/projects/service'

export default defineComponent({
  name: 'CreateEditFunnel',

  components: { CardsSelection, FunnelSchedule, LanguageTabs, SeamlessPreview },

  props: {
    id: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
    funnelId: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const translatableFields = ['title', 'description']
    const store = useStore()
    const { push } = useRouter()
    const pageError = ref('')
    const cardsError = ref('')
    const funnelScheduleError = ref('')
    const cardsSelectionRef = ref()
    let languages: Ref<string[]> = ref([])
    const selectedLanguage = ref('')
    const existingCards: Ref<CarouselPageCard[]> = ref([])

    const canEdit = isAuthorized(Permissions.EDIT_FIELDS_ON_DRAFT_FUNNEL, props.id)

    const { project } = ProjectsService.getProjectById(props.id)

    const { values, handleSubmit, meta, setValues } = useForm({
      validationSchema: yup.object().shape({
        name: yup.string().required('This field is required.'),
        title: yup.string().required('This field is required.'),
        description: yup.string().required('This field is required.'),
      }),
    })
    const {
      copyTranslationFromSavedEntity,
      updateTranslation,
      getTranslationsForFields,
      isAllFormTranslated,
      translatedFields,
    } = useTranslatableFields()

    const { campaign, loading: loadingCampaign } = CampaignsService.getCampaignById(props.campaignId, 'cache-first')

    let funnel = computed(() => store.state.campaignsModule.funnel)
    let loadingFunnel = ref(true)
    if (props.funnelId && props.funnelId !== funnel.value?._id) {
      const { funnel: funnelById, loading } = CampaignsService.getFunnelById(props.funnelId, 'network-only')

      funnel = funnelById
      loadingFunnel = loading
    } else {
      loadingFunnel.value = false
    }

    const funnelSchedule = ref({
      start: funnel.value?.startDate || addToDate(new Date(), { weeks: 1 }),
      end: funnel.value?.endDate || addToDate(new Date(), { weeks: 2 }),
    })

    const updateFunnelSchedule = (range: DateRange) => {
      funnelSchedule.value = range
    }

    const setInitialFormValues = () => {
      copyTranslationFromSavedEntity(funnel.value, translatableFields)
      setValues({
        ...funnel?.value,
        ...translatedFields(translatableFields, [], selectedLanguage?.value),
      })
      existingCards.value = funnel.value?.cards || []
      updateFunnelSchedule({
        start: funnel.value?.startDate || addToDate(new Date(), { weeks: 1 }),
        end: funnel.value?.endDate || addToDate(new Date(), { weeks: 2 }),
      })
    }

    watchEffect(() => {
      if (!loadingCampaign.value) {
        if (!campaign.value) {
          push({ name: 'Funnel Manager' })
        }
        languages.value = campaign.value?.languages || []
        selectedLanguage.value = languages.value?.[0]
      }
    })

    watch(
      () => loadingFunnel.value,
      (loading) => {
        if (!loading) {
          if (!funnel.value) {
            push({ name: 'Funnels' })
          }
        }
        setInitialFormValues()
      }
    )

    const projectBackgroundImageComputed = computed(() => {
      const projectBackgroundImageAsset = project.value?.branding?.assets?.find(
        (asset) => asset.resourceType === 'projectBackgroundImage'
      )
      return projectBackgroundImageAsset ? projectBackgroundImageAsset.resourcePath : null
    })

    const cardDesign = computed(() => {
      return project.value?.branding?.cardDesign
    })

    const { useLeavingConfirmation } = useFormState(meta, values)
    canEdit && useLeavingConfirmation()

    const goBack = () => {
      push({ name: 'Funnels' })
    }

    const onSubmit = handleSubmit(() => {
      if (!funnelSchedule.value) {
        funnelScheduleError.value = 'This field is required.'
        return
      }
      if (size(arrayValues(languages.value)) > 1 && !isAllFormTranslated(translatableFields, languages.value || [])) {
        scrollTo('.language-tabs')
        pageError.value = 'Some required fields are empty. Please check all language tabs.'
        return
      }

      const selectedCards = computed(() => {
        return filter(cardsSelectionRef.value?.selectedCards, (card) => !isEmpty(card))
      })
      if (isEmpty(selectedCards.value)) {
        scrollTo('.cards')
        cardsError.value = 'Please select at least one card for the funnel.'
        return
      }

      store.dispatch('campaignsModule/setFunnel', {
        ...values,
        ...getTranslationsForFields(translatableFields),
        cards: selectedCards.value,
        startDate: funnelSchedule.value.start,
        endDate: funnelSchedule.value.end,
      })

      push({
        name: 'Preview Funnel',
        params: {
          action: 'save',
          ...(props.funnelId && { funnelId: props.funnelId }),
        },
      })
    })

    const changeLanguage = (lang: string) => {
      selectedLanguage.value = lang
      setValues({
        ...values,
        ...translatedFields(translatableFields, [], lang),
      })
    }

    if (!props.funnelId || !loadingFunnel.value) {
      setInitialFormValues()
    }

    return {
      cardDesign,
      projectBackgroundImageComputed,
      availableCards: ['offer', 'card', 'leadForm'],
      cardsError,
      cardsSelectionRef,
      changeLanguage,
      css: { pageContentLoading, formError },
      existingCards,
      goBack,
      funnel,
      funnelSchedule,
      funnelScheduleError,
      isLoading: computed(() => loadingCampaign.value || loadingFunnel.value),
      loadingFunnel,
      languages,
      meta,
      onSubmit,
      pageError,
      previewCards: computed(() => cardsSelectionRef.value?.previewCards),
      selectedLanguage,
      updateFunnelSchedule,
      updateTranslation,
      values,
      canEdit,
      project,
    }
  },
})
