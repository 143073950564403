
import { defineComponent, ref, Ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import { useStore } from '@/store'
import { projectStepStyle as css } from '../../project/styles'
import { getColor } from '@/utils'
import LanguageDropdown from '@/components/common/LanguageDropdown.vue'
import { useForm, FormActions } from 'vee-validate'
import ProjectService from '@/graphqlBackOffice/projects/service'
import { formError } from '@/components/ui/styles'
import {
  getCookieValue,
  setProjectRolesCookie,
} from '@/graphqlBackOffice/auth/session'
import { LANGUAGES } from '@/utils/constants'
import { PAGE_CONFIGURATION_DOMAIN_NAME } from '@/components/common/constants/navigationMenu'

interface ProjectNameFormType {
  projectName: string
  termsAndConditions: boolean
}

export default defineComponent({
  components: { LanguageDropdown },

  setup() {
    const { push } = useRouter()
    const store = useStore()
    const selectedDefaultLanguage = ref<string | undefined>('en')

    const changeDefaultLanguage = (value: string[]) => {
      if (value[0]) {
        selectedDefaultLanguage.value = value[0]
      } else selectedDefaultLanguage.value = undefined

      store.dispatch(
        'projectWizardModule/setDefaultLanguage',
        selectedDefaultLanguage.value
      )
    }

    const isSavingProject = ref(false)
    const errorMessage = ref('')

    const termsAndConditions = computed(
      () => store.state.projectWizardModule.termsAndConditions
    )

    const projectName = computed(
      () => store.state.projectWizardModule.projectName
    )

    const customValidationSchema = yup.object().shape({
      projectName: yup
        .string()
        .required('This field is required.')
        .matches(
          /^[a-zA-Z0-9-.\s]+$/,
          'Only numbers, letters, spaces, dots and "-" character are allowed for this field '
        )
        .test(
          'value',
          'This name is not allowed',
          (val) => val?.toLowerCase() !== 'preview'
        ),
      termsAndConditions: yup
        .boolean()
        .required('The terms and conditions must be accepted.')
        .oneOf([true], 'The terms and conditions must be accepted.'),
    })
    const formValues = {
      projectName: projectName.value,
      termsAndConditions: termsAndConditions.value,
    }

    const { handleSubmit, isSubmitting, setValues } = useForm({
      initialValues: formValues,
      validationSchema: customValidationSchema,
    })

    const changeTermsAndConditions = () => {
      setValues({
        projectName: projectName.value,
        termsAndConditions: !termsAndConditions.value,
      })
      setTermsAndConditions()
    }

    const setTermsAndConditions = () =>
      store.dispatch(
        'projectWizardModule/setTermsAndConditions',
        !termsAndConditions.value
      )

    const onSubmit = handleSubmit(
      (values: ProjectNameFormType, { resetForm }: typeof FormActions) => {
        const defaultLang =
          selectedDefaultLanguage.value!
        const {
          project: createProjectMutation,
          loading,
          networkErrorMessage,
        } = ProjectService.createProject(
          projectName.value as string,
          'undefined',
          [defaultLang],
          defaultLang
        )

        isSavingProject.value = loading.value

        createProjectMutation
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((response: any) => {
            resetForm({})
            if (response.data) {
              const projectId = response.data.createProject._id
              const projectRoles = getCookieValue('projectRoles')
              projectRoles.push({ projectId: projectId, role: 'CLIENT' })
              setProjectRolesCookie(projectRoles)
              store.dispatch('projectWizardModule/resetProjectWizard')
              push({
                name: PAGE_CONFIGURATION_DOMAIN_NAME,
                params: { id: projectId },
              })
            }
          })
          .catch(() => {
            errorMessage.value =
              networkErrorMessage.value?.indexOf('duplicate key error') > -1
                ? 'This name is already in use.'
                : 'Something went wrong, please try again later'
          })
          .finally(() => {
            isSavingProject.value = loading.value
          })
      }
    )

    const projectNameChange = (value: string) => {
      setProjectName(value)
    }

    const setProjectName = (projectName: string) =>
      store.dispatch('projectWizardModule/setProjectName', projectName)

    return {
      selectedDefaultLanguage,
      changeDefaultLanguage,
      getColor,
      push,
      css,
      isSubmitting,
      projectNameChange,
      changeTermsAndConditions,
      termsAndConditions,
      projectName,
      onSubmit,
      errorMessage,
      isSavingProject,
      formError,
      LANGUAGES,
    }
  },
})
