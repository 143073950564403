import { POSITION, useToast, TYPE } from 'vue-toastification'
import { style } from 'typestyle'
import { getColor } from '@/utils'
import 'vue-toastification/dist/index.css'

interface UseNotificationsComposable {
  notify: (args: {
    text: string
    type?: string
    timeout?: number
    position?: string
  }) => void
}

const getNotificationColor = (type: TYPE) => {
  switch (type) {
    case TYPE.ERROR:
      return getColor('primary-red')
      break
    case TYPE.INFO:
      return getColor('primary-blue')
      break
    case TYPE.SUCCESS:
      return getColor('primary-green')
      break
    case TYPE.WARNING:
      return getColor('primary-yellow')
      break
    default:
      return getColor('primary-blue')
  }
}

const getNotificationIcon = (type: TYPE) => {
  switch (type) {
    case TYPE.ERROR:
      return "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjQzNzUgNy41ODMzM1Y3LjU4MzMzQzcuNDM3NSA3LjgyNDk1IDcuMjQxNjIgOC4wMjA4MyA3IDguMDIwODNWOC4wMjA4M0M2Ljc1ODM4IDguMDIwODMgNi41NjI1IDcuODI0OTUgNi41NjI1IDcuNTgzMzNMNi41NjI1IDQuOTU4MzNDNi41NjI1IDQuOTU4MzMgNi41NjI1IDQuNzYyNDUgNi41NjI1IDQuNTIwODNWNC41MjA4M0M2LjU2MjUgNC4yNzkyIDYuNzU4MzggNC4wODMzMyA3IDQuMDgzMzNWNC4wODMzM0M3LjI0MTYyIDQuMDgzMzMgNy40Mzc1IDQuMjc5MiA3LjQzNzUgNC41MjA4M0w3LjQzNzUgNy4xNDU4M0M3LjQzNzUgNy4xNDU4MyA3LjQzNzUgNy4zNDE3IDcuNDM3NSA3LjU4MzMzWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTcuMTA5MzggOC44OTU4M0M2Ljc0Njk0IDguODk1ODMgNi40NTMxMyA5LjE4OTY0IDYuNDUzMTMgOS41NTIwOEM2LjQ1MzEzIDkuOTE0NTIgNi43NDY5NCAxMC4yMDgzIDcuMTA5MzggMTAuMjA4M0M3LjQ3MTgxIDEwLjIwODMgNy43NjU2MyA5LjkxNDUyIDcuNzY1NjMgOS41NTIwOEM3Ljc2NTYzIDkuMTg5NjQgNy40NzE4MSA4Ljg5NTgzIDcuMTA5MzggOC44OTU4M1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy4wMDAwMSAxLjU3MDUxQzQuMDAxMzkgMS41NzA1MSAxLjU3MDUyIDQuMDAxMzcgMS41NzA1MiA2Ljk5OTk5QzEuNTcwNTIgOS45OTg2MiA0LjAwMTM5IDEyLjQyOTUgNy4wMDAwMSAxMi40Mjk1QzkuOTk4NjMgMTIuNDI5NSAxMi40Mjk1IDkuOTk4NjIgMTIuNDI5NSA2Ljk5OTk5QzEyLjQyOTUgNC4wMDEzNyA5Ljk5ODYzIDEuNTcwNTEgNy4wMDAwMSAxLjU3MDUxWk0wLjU4MzM0NCA2Ljk5OTk5QzAuNTgzMzQ0IDMuNDU2MTcgMy40NTYxOCAwLjU4MzMyOCA3LjAwMDAxIDAuNTgzMzI4QzEwLjU0MzggMC41ODMzMjggMTMuNDE2NyAzLjQ1NjE3IDEzLjQxNjcgNi45OTk5OUMxMy40MTY3IDEwLjU0MzggMTAuNTQzOCAxMy40MTY3IDcuMDAwMDEgMTMuNDE2N0MzLjQ1NjE4IDEzLjQxNjcgMC41ODMzNDQgMTAuNTQzOCAwLjU4MzM0NCA2Ljk5OTk5WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==') left 28px center no-repeat"
      break
    case TYPE.INFO:
      return "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjAwMDAxIDEuNTcwNTJDNC4wMDEzOSAxLjU3MDUyIDEuNTcwNTIgNC4wMDEzOSAxLjU3MDUyIDcuMDAwMDFDMS41NzA1MiA5Ljk5ODYzIDQuMDAxMzkgMTIuNDI5NSA3LjAwMDAxIDEyLjQyOTVDOS45OTg2MyAxMi40Mjk1IDEyLjQyOTUgOS45OTg2MyAxMi40Mjk1IDcuMDAwMDFDMTIuNDI5NSA0LjAwMTM5IDkuOTk4NjMgMS41NzA1MiA3LjAwMDAxIDEuNTcwNTJaTTAuNTgzMzQ0IDcuMDAwMDFDMC41ODMzNDQgMy40NTYxOCAzLjQ1NjE4IDAuNTgzMzQ0IDcuMDAwMDEgMC41ODMzNDRDMTAuNTQzOCAwLjU4MzM0NCAxMy40MTY3IDMuNDU2MTggMTMuNDE2NyA3LjAwMDAxQzEzLjQxNjcgMTAuNTQzOCAxMC41NDM4IDEzLjQxNjcgNy4wMDAwMSAxMy40MTY3QzMuNDU2MTggMTMuNDE2NyAwLjU4MzM0NCAxMC41NDM4IDAuNTgzMzQ0IDcuMDAwMDFaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuMTI1IDYuNDE2NjZDNi4xMjUgNi4xNzUwMyA2LjMyMDg4IDUuOTc5MTYgNi41NjI1IDUuOTc5MTZIN0M3LjI0MTYyIDUuOTc5MTYgNy40Mzc1IDYuMTc1MDMgNy40Mzc1IDYuNDE2NjZWOS4wNDE2NkM3LjY3OTEyIDkuMDQxNjYgNy44NzUgOS4yMzc1MyA3Ljg3NSA5LjQ3OTE2QzcuODc1IDkuNzIwNzggNy42NzkxMiA5LjkxNjY2IDcuNDM3NSA5LjkxNjY2SDdDNi43NTgzOCA5LjkxNjY2IDYuNTYyNSA5LjcyMDc4IDYuNTYyNSA5LjQ3OTE2VjYuODU0MTZDNi4zMjA4OCA2Ljg1NDE2IDYuMTI1IDYuNjU4MjggNi4xMjUgNi40MTY2NloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02Ljg5MDYyIDUuMTA0MTZDNy4yNTMwNiA1LjEwNDE2IDcuNTQ2ODggNC44MTAzNCA3LjU0Njg4IDQuNDQ3OTFDNy41NDY4OCA0LjA4NTQ3IDcuMjUzMDYgMy43OTE2NiA2Ljg5MDYyIDMuNzkxNjZDNi41MjgxOSAzLjc5MTY2IDYuMjM0MzggNC4wODU0NyA2LjIzNDM4IDQuNDQ3OTFDNi4yMzQzOCA0LjgxMDM0IDYuNTI4MTkgNS4xMDQxNiA2Ljg5MDYyIDUuMTA0MTZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K') left 28px center no-repeat"
      break
    case TYPE.SUCCESS:
      return "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4yMzM1IDEuOTQ3MDZDMTMuNTE3MyAyLjIxODM5IDEzLjUyNzQgMi42Njg0MiAxMy4yNTYxIDIuOTUyMjNMNy4zNjU0NSA5LjExMzY4QzcuMjI4MTcgOS4yNTcyOCA3LjAzNzA3IDkuMzM2ODggNi44Mzg0NSA5LjMzMzIxQzYuNjM5ODIgOS4zMjk1NSA2LjQ1MTggOS4yNDI5NCA2LjMxOTkxIDkuMDk0MzhMMy42NzkyOCA2LjExOTg4QzMuNDE4NjIgNS44MjYyNiAzLjQ0NTMzIDUuMzc2OTEgMy43Mzg5NiA1LjExNjI0QzQuMDMyNTkgNC44NTU1NyA0LjQ4MTk0IDQuODgyMjkgNC43NDI2MSA1LjE3NTkyTDYuODcwOTQgNy41NzMzNUwxMi4yMjgzIDEuOTY5NjVDMTIuNDk5NyAxLjY4NTg0IDEyLjk0OTcgMS42NzU3MyAxMy4yMzM1IDEuOTQ3MDZaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyLjU3ODEgNi4xODcxNUMxMi40NjE0IDYuMzAxMzUgMTIuNDA1NyA2LjQ2MzAxIDEyLjQxNjggNi42MjU5MUMxMi40MjUyIDYuNzQ5NTIgMTIuNDI5NSA2Ljg3NDI3IDEyLjQyOTUgNy4wMDAwMUMxMi40Mjk1IDkuOTk4NjMgOS45OTg2MyAxMi40Mjk1IDcuMDAwMDEgMTIuNDI5NUM0LjAwMTM5IDEyLjQyOTUgMS41NzA1MiA5Ljk5ODYzIDEuNTcwNTIgNy4wMDAwMUMxLjU3MDUyIDQuMDAxMzkgNC4wMDEzOSAxLjU3MDUyIDcuMDAwMDEgMS41NzA1MkM3LjQ3ODMgMS41NzA1MiA3Ljk0MjE1IDEuNjMyMzcgOC4zODM5OSAxLjc0ODVDOC41Njk2NCAxLjc5NzI5IDguNzY5NTggMS43NTI0MiA4LjkwNjc3IDEuNjE4MTdWMS42MTgxN0M5LjE3MDc0IDEuMzU5ODUgOS4wODM1OCAwLjkxNzg4MiA4LjcyNzgzIDAuODE4NjVDOC4xNzgxMSAwLjY2NTMxNyA3LjU5ODYzIDAuNTgzMzQ0IDcuMDAwMDEgMC41ODMzNDRDMy40NTYxOCAwLjU4MzM0NCAwLjU4MzM0NCAzLjQ1NjE4IDAuNTgzMzQ0IDcuMDAwMDFDMC41ODMzNDQgMTAuNTQzOCAzLjQ1NjE4IDEzLjQxNjcgNy4wMDAwMSAxMy40MTY3QzEwLjU0MzggMTMuNDE2NyAxMy40MTY3IDEwLjU0MzggMTMuNDE2NyA3LjAwMDAxQzEzLjQxNjcgNi44MTY5NiAxMy40MDkgNi42MzU3IDEzLjM5NCA2LjQ1NjUzQzEzLjM1OTYgNi4wNDY1NyAxMi44NzIyIDUuODk5NDEgMTIuNTc4MSA2LjE4NzE1VjYuMTg3MTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K') left 28px center no-repeat"
      break
    case TYPE.WARNING:
      return "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjQzNzUgOC4xNjY2NkM3LjQzNzUgOC40MDgyOCA3LjI0MTYyIDguNjA0MTYgNyA4LjYwNDE2QzYuNzU4MzggOC42MDQxNiA2LjU2MjUgOC40MDgyOCA2LjU2MjUgOC4xNjY2Nkw2LjU2MjUgNS41NDE2NkM2LjU2MjUgNS41NDE2NiA2LjU2MjUgNS4zNDU3OCA2LjU2MjUgNS4xMDQxNkM2LjU2MjUgNC44NjI1MyA2Ljc1ODM4IDQuNjY2NjYgNyA0LjY2NjY2QzcuMjQxNjIgNC42NjY2NiA3LjQzNzUgNC44NjI1MyA3LjQzNzUgNS4xMDQxNkw3LjQzNzUgNy43MjkxNkM3LjQzNzUgNy43MjkxNiA3LjQzNzUgNy45MjUwMyA3LjQzNzUgOC4xNjY2NloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik03LjEwOTM4IDkuNDc5MTZDNi43NDY5NCA5LjQ3OTE2IDYuNDUzMTMgOS43NzI5NyA2LjQ1MzEzIDEwLjEzNTRDNi40NTMxMyAxMC40OTc4IDYuNzQ2OTQgMTAuNzkxNyA3LjEwOTM4IDEwLjc5MTdDNy40NzE4MSAxMC43OTE3IDcuNzY1NjMgMTAuNDk3OCA3Ljc2NTYzIDEwLjEzNTRDNy43NjU2MyA5Ljc3Mjk3IDcuNDcxODEgOS40NzkxNiA3LjEwOTM4IDkuNDc5MTZaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuMjUyNiAzLjM1NDE4QzcuMTQwMzQgMy4xNTk3MyA2Ljg1OTY4IDMuMTU5NzMgNi43NDc0MiAzLjM1NDE4TDIuMjAwNzkgMTEuMjI5MkMyLjA4ODUzIDExLjQyMzYgMi4yMjg4NSAxMS42NjY3IDIuNDUzMzggMTEuNjY2N0gxMS41NDY2QzExLjc3MTIgMTEuNjY2NyAxMS45MTE1IDExLjQyMzYgMTEuNzk5MiAxMS4yMjkyTDcuMjUyNiAzLjM1NDE4Wk03Ljc1Nzc4IDEuODk1ODRDNy40MjEgMS4zMTI1MSA2LjU3OTAzIDEuMzEyNTEgNi4yNDIyNCAxLjg5NTg0TDAuNjg1MjQ1IDExLjUyMDhDMC4zNDg0NTcgMTIuMTA0MiAwLjc2OTQ0MSAxMi44MzMzIDEuNDQzMDIgMTIuODMzM0gxMi41NTdDMTMuMjMwNiAxMi44MzMzIDEzLjY1MTYgMTIuMTA0MiAxMy4zMTQ4IDExLjUyMDhMNy43NTc3OCAxLjg5NTg0WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==') left 28px center no-repeat"
      break
    default:
      return "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjAwMDAxIDEuNTcwNTJDNC4wMDEzOSAxLjU3MDUyIDEuNTcwNTIgNC4wMDEzOSAxLjU3MDUyIDcuMDAwMDFDMS41NzA1MiA5Ljk5ODYzIDQuMDAxMzkgMTIuNDI5NSA3LjAwMDAxIDEyLjQyOTVDOS45OTg2MyAxMi40Mjk1IDEyLjQyOTUgOS45OTg2MyAxMi40Mjk1IDcuMDAwMDFDMTIuNDI5NSA0LjAwMTM5IDkuOTk4NjMgMS41NzA1MiA3LjAwMDAxIDEuNTcwNTJaTTAuNTgzMzQ0IDcuMDAwMDFDMC41ODMzNDQgMy40NTYxOCAzLjQ1NjE4IDAuNTgzMzQ0IDcuMDAwMDEgMC41ODMzNDRDMTAuNTQzOCAwLjU4MzM0NCAxMy40MTY3IDMuNDU2MTggMTMuNDE2NyA3LjAwMDAxQzEzLjQxNjcgMTAuNTQzOCAxMC41NDM4IDEzLjQxNjcgNy4wMDAwMSAxMy40MTY3QzMuNDU2MTggMTMuNDE2NyAwLjU4MzM0NCAxMC41NDM4IDAuNTgzMzQ0IDcuMDAwMDFaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuMTI1IDYuNDE2NjZDNi4xMjUgNi4xNzUwMyA2LjMyMDg4IDUuOTc5MTYgNi41NjI1IDUuOTc5MTZIN0M3LjI0MTYyIDUuOTc5MTYgNy40Mzc1IDYuMTc1MDMgNy40Mzc1IDYuNDE2NjZWOS4wNDE2NkM3LjY3OTEyIDkuMDQxNjYgNy44NzUgOS4yMzc1MyA3Ljg3NSA5LjQ3OTE2QzcuODc1IDkuNzIwNzggNy42NzkxMiA5LjkxNjY2IDcuNDM3NSA5LjkxNjY2SDdDNi43NTgzOCA5LjkxNjY2IDYuNTYyNSA5LjcyMDc4IDYuNTYyNSA5LjQ3OTE2VjYuODU0MTZDNi4zMjA4OCA2Ljg1NDE2IDYuMTI1IDYuNjU4MjggNi4xMjUgNi40MTY2NloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02Ljg5MDYyIDUuMTA0MTZDNy4yNTMwNiA1LjEwNDE2IDcuNTQ2ODggNC44MTAzNCA3LjU0Njg4IDQuNDQ3OTFDNy41NDY4OCA0LjA4NTQ3IDcuMjUzMDYgMy43OTE2NiA2Ljg5MDYyIDMuNzkxNjZDNi41MjgxOSAzLjc5MTY2IDYuMjM0MzggNC4wODU0NyA2LjIzNDM4IDQuNDQ3OTFDNi4yMzQzOCA0LjgxMDM0IDYuNTI4MTkgNS4xMDQxNiA2Ljg5MDYyIDUuMTA0MTZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K') left 28px center no-repeat"
  }
}

const notificationStyle = (type: TYPE): string =>
  style({
    backgroundColor: getNotificationColor(type) + ' !important',
    padding: '12px 28px 12px 56px !important',
    gap: '10px',
    minHeight: '40px !important',
    alignItems: 'center',
    justifyContent: 'center !important',
    borderRadius: '4px !important',
    background: getNotificationIcon(type),
    $nest: {
      '.Vue-Toastification__toast-body': {
        top: '11.5px',
        left: '52px',
        fontSize: '14px',
      },
    },
  })

export const useNotifications = (): UseNotificationsComposable => {
  const toast = useToast()

  const notify = (args: {
    text: string
    type?: string
    timeout?: number
    position?: string
  }) => {
    toast(args.text, {
      type: (args.type as TYPE) ?? TYPE.DEFAULT,
      timeout: args.timeout ?? 5000,
      position: (args.position as POSITION) ?? POSITION.TOP_RIGHT,
      toastClassName: notificationStyle((args.type as TYPE) ?? TYPE.DEFAULT),
      hideProgressBar: true,
      icon: false,
      closeButton: false,
    })
  }

  return { notify }
}
