/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ComputedRef } from 'vue'
import { isEqual } from 'lodash'
import { FormMeta } from 'vee-validate'
import { onBeforeRouteLeave } from 'vue-router'

interface UseFormStateComposable {
  useLeavingConfirmation: () => void
  hasUnsavedChanges: ComputedRef<boolean>
}

export const LEAVING_MESSAGE =
  'You have unsaved changes which will be lost if you leave the page. Are you sure you want to continue?'

export const useFormState = (
  meta: ComputedRef<typeof FormMeta>,
  values: Record<string, any>
): UseFormStateComposable => {
  /**
   * Returns true if form has changed since initial values
   */
  const hasUnsavedChanges = computed(() => {
    if (meta.value.initialValues && values) {
      const fields = Object.keys(meta.value.initialValues)
      return fields.some(
        (fieldName) =>
          !isEqual(meta.value.initialValues[fieldName], values[fieldName])
      )
    }

    return false
  })

  /**
   * Shows confirmation popup before leaving a page without saving data, except the saving routes
   * (with action="save" parameter - needs to be set manually)
   */
  const useLeavingConfirmation = () => {
    onBeforeRouteLeave(async (to, from, next) => {
      if (to.params?.action === 'save' || !hasUnsavedChanges.value) {
        next()

        return
      }

      if (window.confirm(LEAVING_MESSAGE)) {
        next()
      } else {
        next(false)
      }
    })
  }

  return { hasUnsavedChanges, useLeavingConfirmation }
}
