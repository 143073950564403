import * as yup from 'yup'
import { isImage, testFileSize } from './fieldsValidations'

export const imageValidation = yup
  .mixed()
  .required('This field is required.')
  .test(
    'fileSize',
    'File is too large',
    (value) =>
      !(
        value?.[0] &&
        typeof value?.[0] === 'object' &&
        !testFileSize(value?.[0])
      )
  )
  .test(
    'fileType',
    'Unsupported file type',
    (value) =>
      !(value?.[0] && typeof value?.[0] === 'object' && !isImage(value?.[0]))
  )
  .test('isPresent', 'Upload file', (value) => value !== '')
