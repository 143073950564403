
import { computed, defineComponent, ref, reactive, watch } from 'vue'
import { dashboardEnvGroup, dashboardImage } from '../styles'
import { formError } from '@/components/ui/styles'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import ProductBuilderService from '@/graphqlBackOffice/productBuilder/service'
import { PREVIEW, PUBLISH } from '@/utils/constants'
import { DeployType } from '@/types/models'
import { getProjectLogo } from '@/graphqlBackOffice/projects/transformers'
import { useSeamlessUrls } from '@/composables/useSeamlessUrls'
import { useRouter } from 'vue-router'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

interface DashboardState {
  isModalOpen: boolean
  deployType: DeployType
  isDeployingPreview: boolean
  isDeployingPublish: boolean
}

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const error = ref('')
    const { push } = useRouter()
    const state: DashboardState = reactive({
      isModalOpen: false,
      deployType: null,
      isDeployingPreview: false,
      isDeployingPublish: false,
    })
    const projectSettingsProgress = ref({})
    const { loading, project } = ProjectsService.getProjectById(props.id)
    const { productDefaultLanguage } = ProductBuilderService.getProjectDefaultLanguage(props.id)
    const { previewProduct, publishProduct } = ProductBuilderService.useMutations()
    const canPreviewPublish = isAuthorized(Permissions.PREVIEW_PUBLISH_PROJECT, props.id)
    const canOpenLinks = isAuthorized(Permissions.OPEN_PROJECT_LINKS, props.id)
    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          projectSettingsProgress.value = project?.value?.settings.configuration || false
        }
      }
    )
    const logo = computed(() => (project.value ? getProjectLogo(project.value) : ''))
    const onPreviewPress = () => {
      error.value = ''
      state.isModalOpen = true
      state.deployType = PREVIEW
    }
    const onPublishPress = () => {
      error.value = ''
      state.isModalOpen = true
      state.deployType = PUBLISH
    }
    const closeModal = () => {
      state.isModalOpen = false
      setTimeout(() => (state.deployType = null), 500)
    }
    const onClosePress = () => closeModal()
    const deployPreview = async () => {
      state.isDeployingPreview = true
      try {
        await previewProduct.mutate({ productId: project.value?.productId._id })
        closeModal()
      } catch {
        error.value = 'Something went wrong, please try again later.'
        return false
      } finally {
        state.isDeployingPreview = false
      }
      return true
    }
    const deployPublish = async () => {
      const previewResult = await deployPreview()
      state.isDeployingPublish = true
      try {
        if (!previewResult) throw new Error('Preview failed.')
        await publishProduct.mutate({ productId: project.value?.productId._id })
        closeModal()
      } catch {
        error.value = 'Something went wrong, please try again later.'
      } finally {
        state.isDeployingPublish = false
      }
    }
    const { envs } = useSeamlessUrls(props.id, project)
    return {
      loading,
      css: { formError, dashboardImage, dashboardEnvGroup },
      error,
      project,
      logo,
      PUBLISH,
      state,
      productDefaultLanguage,
      onPreviewPress,
      onPublishPress,
      deployPreview,
      deployPublish,
      onClosePress,
      cardsData: ref([
        {
          title: 'CREATE NEW OFFER',
          subtitle:
            'Increase your visibility and start growing your market with a new campaign.<br/><br/>Manage your campaigns in one place and start targeting your customers.',
          buttonText: 'Try Now',
          redirectTo: 'Funnel Manager',
          disabled: computed(() => !projectSettingsProgress.value),
        },
        {
          title: 'CREATE NEW CAMPAIGN',
          subtitle:
            'Increase your visibility and start growing your market with a new campaign.<br/><br/>Manage your campaigns in one place and start targeting your customers.',
          buttonText: 'Try Now',
          redirectTo: 'Offers',
          disabled: computed(() => !projectSettingsProgress.value),
        },
        {
          title: 'ADD BRAND DETAILS',
          subtitle:
            'Increase your visibility and start growing your market with a new campaign.<br/><br/>Manage your campaigns in one place and start targeting your customers.',
          buttonText: 'Try Now',
          redirectTo: 'Flow Builder',
          disabled: computed(() => !projectSettingsProgress.value),
        },
      ]),
      envs,
      push,
      canPreviewPublish,
      canOpenLinks,
    }
  },
})
