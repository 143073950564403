
import { defineComponent, ref, onMounted, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { DateRange } from '@/types/models'
import { useTabs } from '@/composables/useTabs'
import { useTableView } from '@/composables/useTableView'
import { offersStyle } from '@/views/campaigns/styles'
import IndividualOffers from '@/views/campaigns/components/IndividualOffers.vue'
import TaggedOffers from '@/views/campaigns/components/TaggedOffers.vue'
import { CampaignsService } from '@/graphqlBackOffice/campaigns'
import { loading, formError } from '@/components/ui/styles'
import { size } from 'lodash'
import NoDataMessage from '@/components/common/NoDataMessage.vue'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

export default defineComponent({
  components: { NoDataMessage, IndividualOffers, TaggedOffers },

  setup() {
    const state = reactive({
      isLoading: { value: true },
      offers: {},
      tags: {},
    })

    const errorMessage = ref('')

    const { push, currentRoute } = useRouter()
    const projectId = currentRoute.value.params.id

    const {
      deleteOffer: deleteOfferAction,
      duplicateOffer: duplicateOfferAction,
    } = CampaignsService.useMutations()

    const {
      queryInitialValues,
      selectedItems,
      onSearchInputChange,
      onDateInputChange,
      onMarketingPlatformChange,
      onTypeChange,
      onTableSort,
      getFiltersFromCurrentRoute,
    } = useTableView()

    const { changeSelectedTab, selectedTab } = useTabs(
      queryInitialValues.selectedTab || 'individual',
      true
    )

    const getOffers = () => {
      const offersQuery = getFiltersFromCurrentRoute()
      offersQuery.perPage = 12

      if (selectedTab.value === 'individual') {
        const { result, loading } = CampaignsService.getOffers(
          projectId as string,
          offersQuery
        )
        state.offers = result
        state.isLoading = loading
      } else {
        const { result, loading } = CampaignsService.getOffersByTags(
          projectId as string,
          offersQuery
        )
        state.tags = result
        state.isLoading = loading
      }
    }

    watch(currentRoute, getOffers)

    onMounted(getOffers)

    const date = ref({
      range: {
        start: new Date(1990, 0, 1),
        end: new Date(),
      },
    })

    const handleDateChange = (newRange: DateRange) => {
      date.value.range = newRange
      onDateInputChange(date.value.range)
    }

    const goToAddOffer = () => {
      push({ name: 'Create Offer' })
    }

    const editOffer = (offer: any) => {
      push({ name: 'Edit Offer', params: { offerId: offer._id } })
    }

    const deleteOffer = async (id: string) => {
      state.isLoading = ref(true)
      await deleteOfferAction.mutate({ id })
      getOffers()
    }

    const duplicateOffer = async (id: string) => {
      state.isLoading = ref(true)

      try {
        await duplicateOfferAction.mutate({ id })
      } catch (error) {
        state.isLoading = ref(false)
        errorMessage.value = 'Something went wrong, please try again later'
      }
      getOffers()
    }

    const canCreateOffer = isAuthorized(
      Permissions.CREATE_OFFER,
      projectId.toString()
    )

    const canDeleteOffer = isAuthorized(
      Permissions.DELETE_OFFER,
      projectId.toString()
    )

    const canDuplicateOffer = isAuthorized(
      Permissions.DUPLICATE_OFFER,
      projectId.toString()
    )
    return {
      css: { formError, loading },
      editOffer,
      deleteOffer,
      duplicateOffer,
      errorMessage,
      onTableSort,
      state,
      getFiltersFromCurrentRoute,
      onSearchInputChange,
      onDateInputChange,
      onMarketingPlatformChange,
      onTypeChange,
      changeSelectedTab,
      handleDateChange,
      goToAddOffer,
      selectedTab,
      selectedItems,
      queryInitialValues,
      offersStyle,
      getOffers,
      date,
      tabsData: [
        { key: 'individual', name: 'Individual' },
        { key: 'tags', name: 'Tags' },
      ],
      size,
      canCreateOffer,
      canDeleteOffer,
      canDuplicateOffer,
    }
  },
})
