import { getColor } from '@/utils'
import { style } from 'typestyle'

export const container = style({
  marginTop: '24px',
  marginBottom: '24px',
  display: 'flex',
})

export const button = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1',
  height: '40px',
  borderRadius: '20px',
  fontSize: '12px',
  color: '#fff',
  position: 'relative',
  marginRight: '16px',

  $nest: {
    svg: {
      position: 'absolute',
      top: '9px',
      left: '23px',
    },
    '&:last-child': {
      marginRight: 0,
    },
  }
})

export const buttonSelected = style({
  backgroundColor: getColor('primary-blue'),
})

export const buttonDisabled = style({
  border: '1px solid #D9D9D9',
  color: '#D9D9D9',

  $nest: {
    svg: {
      stroke: '#D9D9D9',
    },
  }
})
