
import { defineComponent, ref } from 'vue'
import { loading, tableCell, tableRow, copyButton } from '@/components/ui/styles'
import { useTableView } from '@/composables/useTableView'
import { capitalizeFirstLetter } from '@/utils/textHelpers'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },
    rows: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const { onTableSort, queryInitialValues } = useTableView()
    const { push } = useRouter()
    const toggledCampaign = ref()
    const loadingAction = ref(false)
    let errorMessage = ref('')

    const editCampaign = (id: string) => {
      push({ name: 'Edit Funnel Group', params: { campaignId: id } })
    }

    const goToFunnels = (campaignId: string) => {
      push({ name: 'Funnels', params: { campaignId: campaignId } })
    }

    return {
      capitalizeFirstLetter,
      css: { copyButton, tableRow, tableCell, loading },
      editCampaign,
      errorMessage,
      goToFunnels,
      loadingAction,
      queryInitialValues,
      onTableSort,
      platformIcon: [
        { name: 'googleAds', label: 'Google' },
        { name: 'facebookAds', label: 'Facebook' },
      ],
      campaignsTableCells: [
        { name: 'name', label: 'Funnel Group Name', width: '30%', sortable: true },
        { name: 'platform', label: 'Platform', width: '18%', sortable: true },
        { name: 'funnels', label: 'Funnels', width: '18%', sortable: false },
        { name: 'type', label: 'Type', width: '18%', sortable: true },
        { name: 'createdat', label: 'Created', width: '15%', sortable: true },
      ].filter((item) => !!item),
      toggledCampaign,
    }
  },
})
