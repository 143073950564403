
import { defineComponent, PropType } from 'vue'
import { offersStyle } from '@/views/campaigns/styles'
import { Offer } from '@/types/models'
import { PageInfo } from '@/types/graphql'
import BckIndividualOfferCard from '@/components/ui/BckIndividualOfferCard.vue'
import { addProjectCardText, individualOfferStyle } from '@/components/ui/styles'
import { startCase } from 'lodash'

export default defineComponent({
  components: {
    BckIndividualOfferCard,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    offers: {
      type: Object as PropType<Offer[]>,
      required: true,
    },
    entityLabel: {
      type: String,
      default: 'offer',
    },
    pageInfo: {
      type: Object as PropType<PageInfo>,
      default: () => {
        return {}
      },
    },
    canDeleteOffer: {
      type: Boolean,
      required: true,
    },
    canDuplicateOffer: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['add-offer', 'edit-offer', 'delete-offer', 'duplicate-offer'],

  setup(props) {
    const disabled = !props.canDuplicateOffer

    const textClassName = addProjectCardText({
      margin: 'small no no no',
      padding: 'no',
      color: disabled ? 'secondary-gray' : 'swashly-transparent-30-black',
    })

    return {
      offersStyle,
      individualOfferStyle,
      textClassName,
      iconName: disabled ? 'addProjectDisabled' : 'addProject',
      startCase,
    }
  },
})
