import { style } from 'typestyle'

export {
  imageCard,
  integrationCardContainer,
  groupCardTitle,
  groupCardSubtitle,
  groupCardButton,
  imageCardIntegration,
  wrapper,
} from './IntegrationsDashboard'

export const overviewText = style({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '129%',
  color: '#1E2E39',
  maxWidth: '500px',
})

export const wrapperText = style({
  marginLeft: '10px',
})
