import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckOfferTagCard = _resolveComponent("BckOfferTagCard")!
  const _component_BckPagination = _resolveComponent("BckPagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.offersStyle.individualContainer)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(_ctx.individualTagStyle.card('no small small no', _ctx.selected === tag.name)),
          onClick: ($event: any) => (_ctx.expandTag(tag?.name))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.individualTagStyle.offerGroup)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.individualTagStyle.title)
            }, _toDisplayString(tag.name), 3)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.individualTagStyle.offerGroup)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.individualTagStyle.number)
            }, _toDisplayString(tag.offers), 3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.individualTagStyle.detail)
            }, _toDisplayString(tag.offers > 1 ? _ctx.entityLabelPlural : _ctx.entityLabel), 3)
          ], 2),
          (_ctx.selected === tag.name)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.individualTagStyle.triangle)
              }, null, 2))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1))
      }), 128)),
      (_ctx.selected)
        ? (_openBlock(), _createBlock(_component_BckOfferTagCard, {
            key: 0,
            loading: _ctx.isLoading,
            offers: _ctx.offers?.items || [],
            margin: "no no small no"
          }, null, 8, ["loading", "offers"]))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.isLoading && _ctx.pageInfo?.itemCount > 12)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.offersStyle.footer)
        }, [
          (!_ctx.isLoading && _ctx.pageInfo?.itemCount > 12)
            ? (_openBlock(), _createBlock(_component_BckPagination, {
                key: 0,
                "total-results": _ctx.pageInfo?.itemCount,
                "limit-per-page": 12,
                "max-pagination-items": 6
              }, null, 8, ["total-results"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}