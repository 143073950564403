
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { projectStepStyle as css } from '../../project/styles'

export default defineComponent({
  setup() {
    const { back, push } = useRouter()

    return {
      push,
      back,
      css,
    }
  },
})
