
import { defineComponent, ref, PropType, computed } from 'vue'
import { margin } from '@/components/ui/styles'
import LanguageTabs from '@/components/common/LanguageTabs.vue'
import { useTranslatableFields } from '@/composables/useTranslatableFields'
import { isEmpty } from 'lodash'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import BckEditor from '@/components/ui/BckEditor.vue'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'

export default defineComponent({
  components: { BckEditor, LanguageTabs },

  props: {
    productId: {
      type: String,
      required: true,
    },
    languages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    entity: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<any>,
      default: () => {
        return {}
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['on-editor-change'],

  setup(props, { emit }) {
    const saving = ref(false)
    const pageError = ref('')
    const {
      copyTranslationFromSavedEntity,
      getTranslationsForFields,
      translatedField,
      updateTranslation,
      translations,
    } = useTranslatableFields()
    const editorContent = ref('')

    const selectedLanguage = ref(props.languages?.[0] || '')

    const setContent = () => {
      editorContent.value = translatedField(
        'description',
        selectedLanguage.value
      ) as string
    }

    if (!isEmpty(props.entity)) {
      copyTranslationFromSavedEntity(props.entity, ['title', 'description'])
      setContent()
    }

    const { setValues } = useForm({
      validationSchema: yup.object().shape({
        title: yup.string().required('This field is required.'),
      }),
      initialValues: {
        title: translatedField('title', selectedLanguage.value),
      },
    })

    const changeLanguage = (lang: string) => {
      selectedLanguage.value = lang
      setContent()
      setValues({ title: translatedField('title', selectedLanguage.value) })
    }

    const getUpdatedEntity = () => {
      return {
        ...props.entity,
        ...getTranslationsForFields(['title', 'description']),
      }
    }

    const translatedLearnMorePage = computed(() => {
      return {
        title: translatedField('title', selectedLanguage.value),
        description: translatedField('description', selectedLanguage.value),
      }
    })

    const saveChanges = async () => {
      try {
        saving.value = true
        const type = 'TOS'
        await ProductBuilderService.savePage(
          { name: type, type: type, ...getUpdatedEntity() },
          'LearnMorePage',
          props.productId || ''
        )
        emit('on-editor-change', false)
      } catch (error: any) {
        pageError.value =
          error?.response?.data?.error ||
          'Something went wrong on page saving, please try again later.'
      } finally {
        saving.value = false
      }
    }

    return {
      editorContent,
      selectedLanguage,
      changeLanguage,
      getUpdatedEntity,
      updateTranslation,
      translatedLearnMorePage,
      translations,
      pageError: computed(() => pageError.value),
      saving,
      saveChanges,
      css: { margin },
    }
  },
})
