
import { computed, defineComponent, Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import OfferTemplate from '@/views/campaigns/components/OfferTemplate.vue'
import { useCardGrid } from '@/composables/useCardGrid'
import { CampaignTemplateCardProps } from '@/types/components'
import { useStore } from '@/store'

export default defineComponent({
  components: { OfferTemplate },
  setup() {
    const { push } = useRouter()
    const store = useStore()
    const campaign = computed(() => store.state.campaignsModule.newCampaign)
    const cardsData: Ref<Array<CampaignTemplateCardProps>> = ref([
      {
        name: 'TRAFFIC',
        buttonText: 'Select',
        selected: campaign.value?.template === 'TRAFFIC',
      },
      {
        name: 'BUY',
        buttonText: 'Select',
        selected: campaign.value?.template === 'BUY',
      },
      // {
      //   name: 'BOOK',
      //   buttonText: 'Select',
      //   selected: campaign.value?.template === 'BOOK',
      // },
      {
        name: 'MIX',
        buttonText: 'Select',
        selected: campaign.value?.template === 'MIX',
      },
    ])
    const { toggleSelectedCard, useLeavingConfirmation, selectedCards } = useCardGrid(cardsData, true)
    useLeavingConfirmation()

    const goBack = () => {
      push({ name: 'Funnel Manager' })
    }

    const next = () => {
      store.dispatch('campaignsModule/setNewCampaign', {
        ...campaign.value,
        template: selectedCards.value?.[0].name,
      })

      push({ name: 'Create New Funnel Group', params: { action: 'save' } })
    }

    return { cardsData, goBack, next, toggleSelectedCard, selectedCards }
  },
})
