
import { defineComponent, ref, computed, watch } from 'vue'
import * as yup from 'yup'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import { formError } from '@/components/ui/styles'

import { pwaConfigurationStyle as css } from '../styles'

import LanguageDropdown from '@/components/common/LanguageDropdown.vue'
import { size } from 'lodash'
import { IMAGES_EXT } from '@/utils/constants'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'
import { getLanguageFromKey } from '@/utils/languagesHelper'
import { useNotifications } from '@/composables/useNotifications'

export default defineComponent({
  components: { LanguageDropdown },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter()
    const toast = useNotifications()
    const { updateTranslation } = ProjectsService.useMutations()

    const { loading, project } = ProjectsService.getProjectById(props.id)
    const canEdit = isAuthorized(Permissions.EDIT_PROJECT_LANGUAGE, props.id)

    const saving = ref(false)
    const pageError = ref('')

    const languageValidation = yup.object().shape({
      defaultLang: yup.string().required('This field is required.'),
      languages: yup
        .array()
        .min(1, 'You need at least one language')
        .required('This field is required.'),
      activeLanguages: yup
        .array()
        .min(1, 'You need at least one language')
        .required('This field is required.'),
    })
    const { setValues, values, handleSubmit, meta } = useForm({
      validationSchema: languageValidation,
    })

    const additionalLanguages = computed(() => {
      return values?.languages?.filter(
        (lang: string) => lang !== values.defaultLang
      )
    })

    const languageOptions = computed(
      () =>
        values?.languages?.map((lang: string) => ({
          value: lang,
          label: getLanguageFromKey(lang),
        })) || []
    )

    const activeLanguageOptions = computed(
      () =>
        values?.languages?.map((lang: string) => {
          return lang === values.defaultLang
            ? {
                value: lang,
                label: getLanguageFromKey(lang),
                unavailable: true,
              }
            : {
                value: lang,
                label: getLanguageFromKey(lang),
              }
        }) || []
    )

    const changeLanguages = (value: string[]) => {
      setValues({
        ...values,
        languages: [values.defaultLang, ...value],
        activeLanguages: [
          values.defaultLang,
          ...values.activeLanguages.filter(
            (lang: string) =>
              value.includes(lang) && lang !== values.defaultLang
          ),
        ],
      })
    }

    const updateActiveLanguages = (value: string[]) => {
      setValues({
        ...values,
        activeLanguages: value,
      })
    }

    const updateDefaultLang = (value: string) => {
      const newActiveLanguages =
        values.activeLanguages.indexOf(value) === -1
          ? [value, ...values.activeLanguages]
          : values.activeLanguages
      setValues({
        ...values,
        activeLanguages: newActiveLanguages,
        defaultLang: value,
      })
    }

    const goBack = () => {
      router.push({
        name: 'Project',
        params: { id: props.id },
      })
    }

    const onSubmit = handleSubmit(async () => {
      try {
        pageError.value = ''
        const addedLangs =
          values.languages.filter(
            (item: string) => !project.value?.languages?.includes(item)
          ) || []
        const removedLangs =
          project.value?.languages?.filter(
            (item: string) => !values.languages.includes(item)
          ) || []

        const activeLangDiff = values.activeLanguages
          .filter((x: string) => !project.value?.activeLanguages.includes(x))
          .concat(
            project.value?.activeLanguages.filter(
              (x: string) => !values.activeLanguages.includes(x)
            )
          )
        if (
          addedLangs.length > 0 ||
          removedLangs.length > 0 ||
          values.defaultLang !== project.value?.languages[0] ||
          activeLangDiff.length > 0
        ) {
          if (removedLangs.length > 0) {
            if (
              !confirm(
                'Warning: When you remove a language, you will lose all content for that language! Are you sure you want to continue?'
              )
            ) {
              saving.value = false
              return
            }
          }
          saving.value = true
          updateTranslation
            .mutate({
              projectId: props.id,
              defaultLang: values.defaultLang,
              langs: values.languages,
              activeLanguages: values.activeLanguages,
            })
            .then(() => {
              toast.notify({
                text: 'Your language changes have been applied.',
                type: 'success',
              })
            })
            .catch(() => {
              toast.notify({
                text: 'There was an issue applying your language changes. Please try again.',
                type: 'error',
              })
            })
        }
        router.push({
          name: 'Project',
          params: { id: props.id },
        })
      } catch (error) {
        pageError.value =
          'There was an issue applying your language changes. Please try again.'
      } finally {
        saving.value = false
      }
    })

    watch(
      () => loading.value,
      () => {
        if (project.value) {
          setValues({
            defaultLang: project.value?.languages[0],
            languages: project.value?.languages,
            activeLanguages: project.value?.activeLanguages,
          })
        }
      }
    )

    return {
      loading,
      project,
      formError,
      css,
      size,
      IMAGES_EXT,
      canEdit,
      changeLanguages,
      updateDefaultLang,
      updateActiveLanguages,
      goBack,
      onSubmit,
      meta,
      pageError,
      values,
      saving,
      languageOptions,
      activeLanguageOptions,
      additionalLanguages,
    }
  },
})
