
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useModal } from '@/composables/useModal'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { projectGetHelpStyle as css } from '../styles'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const isArchiving = ref(false)
    const isDeleting = ref(false)
    const { updateProjectArchivation, deleteProject } =
      ProjectsService.useMutations()
    const canArchiveProject = isAuthorized(
      Permissions.ARCHIVE_PROJECT,
      props.id
    )
    const canDeleteProject = isAuthorized(Permissions.DELETE_PROJECT, props.id)

    const {
      isModalVisible: isArchiveModalVisible,
      closeModal: closeArchiveModal,
      showModal: showArchiveModal,
    } = useModal()
    const {
      isModalVisible: isDeleteModalVisible,
      closeModal: closeDeleteModal,
      showModal: showDeleteModal,
    } = useModal()

    const router = useRouter()

    const archive = async () => {
      isArchiving.value = true
      await updateProjectArchivation.mutate({
        projectId: props.id,
        archived: true,
      })

      isArchiving.value = false
      router.push({ name: 'Projects' })
    }

    const handleDeleteProject = async () => {
      isDeleting.value = true
      await deleteProject.mutate({ id: router.currentRoute.value.params.id })
      isDeleting.value = false
      router.push({ name: 'Projects' })
    }

    return {
      isArchiving,
      isDeleting,
      isArchiveModalVisible,
      closeArchiveModal,
      showArchiveModal,
      isDeleteModalVisible,
      closeDeleteModal,
      showDeleteModal,
      archive,
      css,
      canArchiveProject,
      canDeleteProject,
      handleDeleteProject,
    }
  },
})
