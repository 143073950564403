import axios from 'axios'
import { getAllLeadForms } from './grid'

export const getAvailableTags = async (projectId: string): Promise<string[]> => {
  let result: string[] = []

  try {
    const leadForms = await getAllLeadForms(projectId)
    const set = new Set<string>()

    for (const leadForm of leadForms) {
      for (const tag of leadForm.tags) {
        set.add(tag)
      }
    }

    result = Array.from(set)
  } catch (error: any) {
    console.log(error)
  }

  return result
}

export const saveLeadForm = async (projectId: string, model: any, translations: any) => {
  const method = model._id ? 'update' : 'create'

  const formData = new FormData()
  formData.append('projectId', projectId)
  formData.append('leadForm', JSON.stringify(model))

  for (const language of model.languages) {
    const file = translations.value.image[language][0]
    const verticalFile = translations.value.verticalImage[language][0]

    if (file instanceof File) {
      formData.append(`image_${language}`, file)
    }

    if (file instanceof File) {
      formData.append(`verticalImage_${language}`, verticalFile)
    }
  }

  await axios.post(`/api/lead-form/${method}`, formData)
}

export const getLeadForm = async (projectId: string, leadFormId: string) => {
  const { data } = await axios.post('/api/lead-form/get', { projectId, id: leadFormId })

  return data
}
