import { computed, ComputedRef, Ref } from 'vue'
import { forEach } from 'lodash'
import { CardProps, CampaignTemplateCardProps } from '@/types/components'
import { isEqual } from 'lodash'
import { onBeforeRouteLeave } from 'vue-router'
import { LEAVING_MESSAGE } from '@/composables/useFormState'

interface useCardGridComposable {
  getButtonText: (selected: boolean) => string
  isDirty: () => boolean
  toggleSelectedCard: (cardIndex: number) => void
  selectedCards: ComputedRef<Array<CardProps | CampaignTemplateCardProps>>
  useLeavingConfirmation: () => void
}

export const useCardGrid = (
  cardsData: Ref<Array<CardProps | CampaignTemplateCardProps>>,
  isSingleSelection?: boolean
): useCardGridComposable => {
  let changesMade = false
  let initialSelection: Array<string> = []

  const isDirty = () => {
    if (!changesMade) {
      return false
    }
    return !isEqual(
      initialSelection,
      selectedCards.value.map((item) => item.name)
    )
  }

  const getButtonText = (selected: boolean): string => {
    return selected ? 'Selected' : 'Select'
  }

  const toggleSelectedCard = (cardIndex: number) => {
    if (!changesMade) {
      changesMade = true
      // Take a snapshot of initial state of cards for dirty state
      initialSelection =
        cardsData.value
          .filter((item) => item.selected)
          .map((item) => item.name) || []
    }

    const card = cardsData.value[cardIndex]
    card.selected = !card.selected
    card.buttonText = getButtonText(card.selected)

    if (isSingleSelection) {
      forEach(cardsData.value, (card, index) => {
        if (Number(index) !== cardIndex) {
          card.selected = false
          card.buttonText = getButtonText(card.selected)
        }
      })
    }
  }

  const selectedCards = computed(() =>
    cardsData.value.filter((card) => card.selected)
  )

  /**
   * Shows confirmation popup before leaving a page without saving data, except the saving routes
   * (with action="save" parameter - needs to be set manually)
   */
  const useLeavingConfirmation = () => {
    onBeforeRouteLeave(async (to, from, next) => {
      if (to.params?.action === 'save' || !isDirty()) {
        next()

        return
      }

      if (window.confirm(LEAVING_MESSAGE)) {
        next()
      } else {
        next(false)
      }
    })
  }

  return {
    isDirty,
    getButtonText,
    toggleSelectedCard,
    selectedCards,
    useLeavingConfirmation,
  }
}
