
import { defineComponent, PropType, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useTableView } from '@/composables/useTableView'
import { PageInfo } from '@/types/graphql'
import { offersStyle } from '@/views/campaigns/styles'
import { individualTagStyle } from '@/components/ui/styles'
import { CampaignsService } from '@/graphqlBackOffice/campaigns'
import { OfferTag } from '@/types/models'
import { useQueryFilters } from '@/graphqlBackOffice/composables'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    entityLabel: {
      type: String,
      default: 'Offer',
    },
    entityLabelPlural: {
      type: String,
      default: 'Offers',
    },
    allEntities: {
      default: undefined,
    },
    tags: {
      type: Object as PropType<OfferTag[]>,
      required: true,
    },
    pageInfo: {
      type: Object as PropType<PageInfo>,
      required: true,
    },
  },
  setup(props) {
    let isLoading = ref(true)
    const offers = ref({})
    const selected = ref('')
    const { computedOperator } = useQueryFilters()

    const { currentRoute } = useRouter()
    const { getFiltersFromCurrentRoute } = useTableView()
    const offersQuery = getFiltersFromCurrentRoute()

    const expandTag = (tag: string) => {
      if (props.allEntities) {
        offers.value = {
          items: (props.allEntities as any[]).filter((x) => x.tags.includes(tag)),
        }
        isLoading.value = false
        selected.value = tag
        return
      }

      const projectId = currentRoute.value.params.id
      const { result, loading } = CampaignsService.getOffers(projectId as string, offersQuery, [
        computedOperator('tags', 'in', [tag]),
      ])
      selected.value = tag

      watch(
        () => loading.value,
        () => {
          isLoading.value = loading.value
          offers.value = result.value
        }
      )
    }
    return { individualTagStyle, offersStyle, expandTag, isLoading, offers, selected }
  },
})
