
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConfirmationModal',

  props: {
    title: {
      type: String,
      default: 'Are you sure you want to delete this?',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    portrait: {
      type: Boolean,
      default: true,
    },
    confirmationButtonText: {
      type: String,
      default: '',
    },
    confirmationButtonColor: {
      type: String,
      default: 'primary-blue',
    },
  },

  emits: ['close', 'confirm'],
})
