
import { defineComponent, ref, watch } from 'vue'
import {
  imageCard,
  integrationCardContainer,
  groupCardTitle,
  groupCardSubtitle,
  groupCardButton,
  wrapper,
} from '../styles'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const error = ref('')
    const { push } = useRouter()

    const navigateToHotjar = () => {
      emit('card-selected')
      push({ name: 'Hotjar' })
    }

    const navigateToFbPixel = () => {
      emit('card-selected')
      push({ name: 'Facebook Pixel' })
    }
    const navigateToMsClarity = () => {
      emit('card-selected')
      push({ name: 'Microsoft Clarity' })
    }
    const projectSettingsProgress = ref({})
    const { loading, project } = ProjectsService.getProjectById(props.id)

    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          projectSettingsProgress.value = project?.value?.settings.configuration || false
        }
      }
    )

    return {
      loading,
      css: {
        imageCard,
        integrationCardContainer,
        groupCardTitle,
        groupCardSubtitle,
        groupCardButton,
        wrapper,
      },
      error,
      project,
      push,
      navigateToHotjar,
      navigateToFbPixel,
      navigateToMsClarity,
    }
  },
})
