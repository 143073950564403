import { computed, ComputedRef, ref, Ref, watchEffect } from 'vue'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { OptionType } from '@/types/components'
import { filter } from 'lodash'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { isSuperAdmin } from '@/graphqlBackOffice/auth/session'
import { NavigationCard, Offer } from '@/types/models'

interface UsePayloadButtons {
  internalPages: ComputedRef<OptionType[]>
  extensionPages: Ref
  payloadTypes: ComputedRef<OptionType[] | OptionType[][]>
  pageActions: OptionType[]
}

export const usePayloadButtons = (
  projectId: string,
  data: ComputedRef<Offer | undefined> | NavigationCard,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
): UsePayloadButtons => {
  const extensionPages = ref<unknown>([])
  const { pages } = ProductBuilderService.getProductPages(projectId, fetchPolicy)

  const { project, loading: loadingProject } = ProjectsService.getProjectById(projectId, 'cache-first')

  watchEffect(async () => {
    if (!loadingProject.value) {
      if (project.value?.name) {
        const res = await ProjectsService.getConfig()
        extensionPages.value = res.data[project.value?.name]?.map((item: any) => ({
          label: item.name,
          value: item.name,
        }))
      }
    }
  })

  const internalPages = computed(() => {
    return filter(pages?.value, (page) => page.pageType !== 'ReadMorePage')?.map((page) => {
      return {
        value: page._id,
        label: page.name,
      }
    })
  })

  const payloadTypes = computed(() => {
    if (!data) {
      const payloads = [
        { value: 'EXTERNAL', label: 'External' },
        { value: 'INTERNAL', label: 'Internal' },
        { value: 'PAGE_ACTION', label: 'Page action' },
      ]
      if (isSuperAdmin()) payloads.push({ value: 'EXTENSION', label: 'Extension' })
      return [payloads, payloads]
    } else {
      const navigation = data as NavigationCard
      if (navigation?.payloadType) {
        const list = navigation?.payloadType.map((x) => {
          const payloads = [
            { value: 'EXTERNAL', label: 'External' },
            { value: 'INTERNAL', label: 'Internal' },
            { value: 'PAGE_ACTION', label: 'Page action' },
          ]
          if (isSuperAdmin() || x === 'EXTENSION') payloads.push({ value: 'EXTENSION', label: 'Extension' })
          return payloads
        })
        for (let i = list.length; list.length <= 2; i++) {
          const payloads = [
            { value: 'EXTERNAL', label: 'External' },
            { value: 'INTERNAL', label: 'Internal' },
            { value: 'PAGE_ACTION', label: 'Page action' },
          ]
          if (isSuperAdmin()) payloads.push({ value: 'EXTENSION', label: 'Extension' })
          list.push(payloads)
        }
        return list
      } else {
        const offer = data as ComputedRef<Offer | undefined>
        const payloads = [
          { value: 'EXTERNAL', label: 'External' },
          { value: 'INTERNAL', label: 'Internal' },
          { value: 'PAGE_ACTION', label: 'Page action' },
        ]
        if (isSuperAdmin() || offer?.value?.payloadType === 'EXTENSION')
          payloads.push({ value: 'EXTENSION', label: 'Extension' })
        return payloads
      }
    }
  })

  const pageActions = [
    { value: 'ADD_TO_HOMESCREEN', label: 'Add to homescreen' },
    { value: 'NAVIGATION_BACK', label: 'Navigate back' },
  ]

  return { internalPages, extensionPages, pageActions, payloadTypes }
}
