
import { defineComponent, PropType } from 'vue'
import CountryFlag from 'vue-country-flag-next'
import { Language } from '@/types/models'
import { LANGUAGES } from '@/utils/constants'
import { Sizes } from '@/utils'

export default defineComponent({
  name: 'LanguageDropdown',

  components: { CountryFlag },

  props: {
    name: {
      type: String,
      default: 'languages',
    },
    selectedLanguages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    max: {
      type: Number,
      default: -1,
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'large no no no',
    },
    placeholder: {
      type: String,
      default: 'Enter your language',
    },
    languages: {
      type: Array as PropType<Language[]>,
      default: LANGUAGES,
    },
  },
  emits: ['change'],
})
