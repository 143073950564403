import { style } from 'typestyle'
import seamlessPreviewImage from '@/assets/seamlessMobilePreview.png'

export const container = style({
  display: 'flex',
  justifyContent: 'flex-end',
})

export const mobilePreview = style({
  position: 'relative',
  width: '304px',
  height: '619px',
  background: `url(${seamlessPreviewImage}) no-repeat center center`,
  backgroundSize: 'cover',
})

export const backdrop = style({
  backgroundColor: '#808080',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '271px',
  height: '521px',
  borderBottomLeftRadius: '33px',
  borderBottomRightRadius: '33px',
  position: 'absolute',
  left: '16px',
  top: '81px',
})

export const modal = style({
  position: 'relative',
  backgroundColor: '#fff',
  width: '230px',
  height: '430px',
  borderRadius: '12px',
  padding: '72px 24px 0 24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

export const closeButton = style({
  position: 'absolute',
  right: '12px',
  top: '16px',
  backgroundColor: '#808080',
  color: '#fff',
  borderRadius: '15px',
  padding: '6px 12px',
  fontSize: '8px',
})

export const registerButton = style({
  backgroundColor: '#D9D9D9',
  borderRadius: '12px',
  color: '#fff',
  marginBottom: '40px',
  padding: '8px',
  textAlign: 'center',
  fontSize: '10px',
})

export const formTitle = style({
  fontWeight: 'bold',
  marginBottom: '20px',
  paddingLeft: '5px',
})

export const formInput = style({
  backgroundColor: '#F6F6F6',
  border: 0,
  width: '100%',
  marginTop: '10px',
  padding: '8px',
  fontSize: '10px',
  borderBottom: '1px solid #bbbbbb',
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
})

export const disclaimerStyle = {
  justifyContent: 'flex-end',
  paddingRight: '40px',
}
