import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_BckText = _resolveComponent("BckText")!
  const _component_BckGrid = _resolveComponent("BckGrid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BckGrid, {
      gap: "9px",
      margin: "xxSmall no"
    }, {
      default: _withCtx(() => [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_BckIcon, {
              key: 0,
              class: _normalizeClass(_ctx.css.editIcon),
              "icon-name": "edit",
              "view-box": "0 0 15 15",
              height: "16",
              width: "17",
              stroke: _ctx.getColor('secondary-gray'),
              fill: _ctx.getColor('secondary-gray'),
              onClick: _ctx.startEdit
            }, null, 8, ["class", "stroke", "fill", "onClick"]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.css.loading('DARK', 'medium'))
            }, null, 2)),
        (!_ctx.editing)
          ? (_openBlock(), _createBlock(_component_BckText, {
              key: 2,
              color: "primary-blue",
              weight: "bold",
              margin: "no no xxSmall"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.value), 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("input", {
              key: 3,
              ref: "inputRef",
              disabled: _ctx.disabled,
              autofocus: "",
              class: _normalizeClass(_ctx.css.input),
              placeholder: _ctx.placeholder,
              autocomplete: "off",
              type: "text",
              onKeyup: [
                _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.$emit('update', $event)), ["enter"])),
                _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.editing = false), ["esc"]))
              ]
            }, null, 42, _hoisted_1))
      ]),
      _: 1
    }),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.css.formError)
        }, _toDisplayString(_ctx.error), 3))
      : _createCommentVNode("", true)
  ], 64))
}