
import { defineComponent, ref, Ref } from 'vue'
import { loading, tableCell, tableRow } from '@/components/ui/styles'
import { useTableView } from '@/composables/useTableView'
import { statuses } from '@/utils/constants'
import { Invitation } from '@/types/models'
import UsersService from '@/graphqlBackOffice/users/service'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    selectedInvitations: {
      type: Array,
      default: () => [],
    },
    canResendInvites: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['toggle-invitation'],

  setup(props) {
    const { onTableSort, queryInitialValues } = useTableView()
    const resending: Ref<Array<string>> = ref([])
    const { resendInvitation } = UsersService.useMutations()

    const resend = (row: Invitation) => {
      if (!props.canResendInvites) {
        return
      }

      resending.value.push(row._id)
      resendInvitation
        .mutate({
          record: { userEmail: row.userEmail },
          filter: { userEmail: row.userEmail },
        })
        .catch((error: Error) => {
          console.log(error.message)
        })
        .finally(() => {
          resending.value.splice(resending.value.indexOf(row._id), 1)
        })
    }

    return {
      resend,
      queryInitialValues,
      resending,
      onTableSort,
      statuses,
      invitesTableCells: [
        { name: 'select', label: '', width: '5%' },
        {
          name: 'userEmail',
          label: 'Invites sent',
          width: '30%',
          sortable: true,
        },
        { name: 'sendDate', label: 'Send Date', width: '25%' },
        { name: 'status', label: 'Status', width: '25%', sortable: true },
        { name: 'resend', label: 'Resend', width: '15%' },
      ],
      css: { tableRow, tableCell, loading },
    }
  },
})
