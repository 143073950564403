
import { defineComponent, ref, watch } from 'vue'
import {
  imageCardIntegration,
  integrationCardContainer,
  groupCardTitle,
  groupCardSubtitle,
  groupCardButton,
  overviewText,
  wrapperText,
} from '../styles'
import { useRouter } from 'vue-router'
import { useModal } from '@/composables/useModal'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { useNotifications } from '@/composables/useNotifications'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isConnected = ref(false)
    const toast = useNotifications()
    const { updateProjectIntegrations } = ProjectsService.useMutations()
    const error = ref('')
    const { push } = useRouter()

    const { loading, project } = ProjectsService.getProjectById(props.id)

    const canEdit = isAuthorized(Permissions.EDIT_PROJECT_INTEGRATIONS, props.id)
    console.log('=canEdit==>', canEdit)

    const { isModalVisible: isModalVisibleAdd, showModal: showModalAdd, closeModal: closeModalAdd } = useModal()

    const { isModalVisible: isModalVisibleEdit, showModal: showModalEdit, closeModal: closeModalEdit } = useModal()

    const customValidationSchema = yup.object().shape({
      integrationID: yup.string().required('This field is required.').nullable(),
    })

    const { values, handleSubmit } = useForm({
      validationSchema: customValidationSchema,
    })

    const retrieveFilteredAndFormattedIntegrations = () => {
      const integrations = project.value?.integrations

      const filteredIntegrations = integrations?.filter((integration) => integration.name !== 'clarity') ?? []

      return filteredIntegrations.map((integration) => ({
        name: integration.name,
        integrationId: integration.integrationId,
      }))
    }

    const addEditIntegration = handleSubmit(async () => {
      try {
        await updateProjectIntegrations
          .mutate({
            projectId: props.id,
            integrations: [
              ...retrieveFilteredAndFormattedIntegrations(),
              { name: 'clarity', integrationId: values.integrationID },
            ],
          })
          .then(() => {
            isConnected.value = true
            toast.notify({
              text: 'Your changes have been applied',
              type: 'success',
            })
          })
          .catch(() => {
            isConnected.value = false
            toast.notify({
              text: 'It seems that there was a problem while applying your integration changes. Please try again and see if the issue persists.',
              type: 'error',
            })
          })
        closeModalAdd()
        closeModalEdit()
        push({
          name: 'Microsoft Clarity',
        })
      } catch (e) {
        error.value = e.message
      }
    })

    const removeIntegration = handleSubmit(async () => {
      try {
        await updateProjectIntegrations
          .mutate({
            projectId: props.id,
            integrations: [...retrieveFilteredAndFormattedIntegrations()],
          })
          .then(() => {
            isConnected.value = false
            values.integrationID = ''
            toast.notify({
              text: 'Your changes have been applied',
              type: 'success',
            })
          })
          .catch(() => {
            isConnected.value = true
            toast.notify({
              text: 'It seems that there was a problem while applying your integration changes. Please try again and see if the issue persists.',
              type: 'error',
            })
          })
        closeModalEdit()
        push({
          name: 'Microsoft Clarity',
          params: { id: props.id },
        })
      } catch (e) {
        error.value = e.message
      }
    })
    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          if (project.value?.integrations) {
            values.integrationID = project.value?.integrations.find(
              (integration) => integration.name === 'clarity'
            )?.integrationId
          } else {
            values.integrationID = ''
          }
        }
      }
    )

    watch(
      () => project.value?.integrations,
      (integrations) => {
        isConnected.value = !!integrations?.find((integration) => integration.name === 'clarity')
      },
      { immediate: true } // This triggers the watcher immediately upon component creation
    )

    return {
      loading,
      css: {
        imageCardIntegration,
        integrationCardContainer,
        groupCardTitle,
        groupCardSubtitle,
        groupCardButton,
      },
      error,
      project,
      push,
      showModalAdd,
      isModalVisibleAdd,
      closeModalAdd,
      showModalEdit,
      isModalVisibleEdit,
      closeModalEdit,
      addEditIntegration,
      removeIntegration,
      isConnected,
      values,
      canEdit,
      overviewText,
      wrapperText,
    }
  },
})
