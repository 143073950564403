import { computed, Ref } from 'vue'
import { Project } from '@/types/models'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'

export const useSeamlessUrls = (
  projectId: string,
  project: Ref<Project | null>
) => {
  const { menu } = ProductBuilderService.getProductMenu(projectId)
  const envs = computed(() => {
    const homepageId = menu.value ? menu.value[0].payload : undefined

    return [
      {
        name: 'Preview',
        displayUrl: project.value?.domain?.previewUrl || '',
        url:
          project.value?.domain?.previewUrl && homepageId
            ? `${project.value?.domain?.previewUrl}/page/${homepageId}`
            : '',
      },
      {
        name: 'Live',
        url:
          project.value?.domain?.productionUrl && homepageId
            ? `${project.value?.domain?.productionUrl}/page/${homepageId}`
            : '',
        displayUrl: project.value?.domain?.productionUrl || '',
      },
    ]
  })

  return { envs }
}
