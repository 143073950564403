import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_BckH4 = _resolveComponent("BckH4")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css.inner)
    }, [
      _createVNode(_component_BckIcon, {
        "icon-name": !_ctx.stepCompleted ? 'loading' : 'doneLoading',
        "view-box": "0 0 31 31",
        height: "31",
        width: "31",
        class: _normalizeClass(!_ctx.stepCompleted ? _ctx.loadingStyle : '')
      }, null, 8, ["icon-name", "class"]),
      _createVNode(_component_BckH4, {
        margin: "small",
        align: "center"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.stepDescription), 1)
        ]),
        _: 1
      })
    ], 2)
  ], 2))
}