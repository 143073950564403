
import { defineComponent, onMounted, reactive, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import { tablePanel } from '@/components/ui/styles'
import { useTableView } from '@/composables/useTableView'
import { useTabs } from '@/composables/useTabs'
import ProjectCampaignsTable from '@/views/project/components/ProjectCampaignsTable.vue'
import { campaignManagerStyle } from '../styles'
import { CampaignsService } from '@/graphqlBackOffice/campaigns'
import { values } from 'lodash'
import { PaginatedList } from '@/types/graphql'
import { useStore } from '@/store'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

export default defineComponent({
  components: { ProjectCampaignsTable },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { push } = useRouter()
    const store = useStore()

    const state = reactive({
      activeCampaigns: {},
      completedCampaigns: {},
      campaignsTableLoading: {},
    })

    const handleNewCampaign = () => {
      store.dispatch('campaignsModule/setNewCampaign', undefined)
      push({ name: 'Create Funnel Group' })
    }

    const {
      queryInitialValues,
      selectedItems,
      toggleSelectedItems,
      onSearchInputChange,
      onDateInputChange,
      onMarketingPlatformChange,
      onTypeChange,
      onTableSort,
      getFiltersFromCurrentRoute,
    } = useTableView()

    const { currentRoute } = useRouter()

    const { changeSelectedTab, selectedTab } = useTabs(queryInitialValues.selectedTab || 'active', true)

    const getCampaigns = () => {
      const tableFilters = getFiltersFromCurrentRoute()

      const { loading, result } = CampaignsService.getCampaigns(tableFilters, {
        projectId: props.id,
        completed: selectedTab.value === 'completed',
      })

      state.campaignsTableLoading = loading
      if (selectedTab.value === 'completed') {
        state.completedCampaigns = result || { items: [], count: 0 }
      } else {
        state.activeCampaigns = result || { items: [], count: 0 }
      }
    }

    const count = computed(() =>
      selectedTab.value === 'active'
        ? (state.activeCampaigns as PaginatedList)?.count
        : (state.completedCampaigns as PaginatedList)?.count
    )

    watch(currentRoute, getCampaigns)

    onMounted(() => {
      getCampaigns()
    })

    const canCreateCampaign = isAuthorized(Permissions.CREATE_NEW_FUNNEL_GROUP, props.id)

    return {
      campaignManagerStyle,
      tablePanel,
      queryInitialValues,
      onSearchInputChange,
      onDateInputChange,
      onMarketingPlatformChange,
      onTypeChange,
      changeSelectedTab,
      selectedTab,
      selectedItems,
      state,
      onTableSort,
      toggleSelectedItems,
      getCampaigns,
      handleNewCampaign,
      count,
      tabsData: [
        { key: 'active', name: 'Active' },
        { key: 'completed', name: 'Completed' },
      ],
      values,
      currentRoute,
      canCreateCampaign,
    }
  },
})
