import { Color, getColor } from '@/utils'
import { style } from 'typestyle'

export const adminPage = style({
  height: '100%',
  padding: '16px 42px',
})

export const toggleIcon = (active?: boolean): string =>
  style({
    background: active
      ? "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCA3IDUuMTUgOS40NDJhMSAxIDAgMCAxLTEuMyAwTDEgN00xIDRsMi44NS0yLjQ0MmExIDEgMCAwIDEgMS4zIDBMOCA0IiBzdHJva2U9IiM0Q0IwNTYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48L3N2Zz4=') left center no-repeat"
      : "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCA3IDUuMTUgOS40NDJhMSAxIDAgMCAxLTEuMyAwTDEgN00xIDRsMi44NS0yLjQ0MmExIDEgMCAwIDEgMS4zIDBMOCA0IiBzdHJva2U9IiNFMjQyM0MiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48L3N2Zz4=') left center no-repeat",
  })

export const dot = (color: Color): string =>
  style({
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: getColor(color),
  })
