
import { defineComponent, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { projectConfigurationStyle as cssConfig } from '../styles'
import { projectStepStyle as css } from '../styles'
import { ProxyConfiguration } from '@/types/models'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup() {
    const currentRoute = useRoute()

    const router = useRouter()

    const state = computed(() => {
      return {
        protocolType: currentRoute?.query?.protocolType,
        proxyType: currentRoute?.query?.proxyType,
        nginx: currentRoute?.query?.proxyType === 'NGINX' ? true : false,
        haproxy: currentRoute?.query?.proxyType === 'HAPROXY' ? true : false,
        secure:
          currentRoute?.query?.protocolType === 'HTTPS' ||
          currentRoute?.query?.protocolType === 'HTTP-HTTPS'
            ? true
            : false,
      }
    })
    const customValidationSchema = yup.object().shape({
      domainName: yup.string().required('This field is required.'),
      endpoint: yup.string().required('This field is required.'),

      ...(state.value.nginx && {
        dnsResolver: yup.string().required('This field is required.'),
      }),
      ...(state.value.nginx &&
        state.value.secure && {
          certificatePath: yup.string().required('This field is required.'),
          certificateUrlPath: yup.string().required('This field is required.'),
        }),
      ...(state.value.haproxy &&
        state.value.secure && {
          pemCertificatePath: yup.string().required('This field is required.'),
        }),
    })

    const { values, handleSubmit } = useForm({
      validationSchema: customValidationSchema,
    })

    const onSubmit = handleSubmit(async (values: ProxyConfiguration) => {
      values.protocolType = state.value.protocolType as string
      values.proxyType = state.value.proxyType as string

      router.push({ name: 'Configuration' })
    })

    return {
      cssConfig,
      css,
      onSubmit,
      values,
      state,
    }
  },
})
