import { computed, ComputedRef } from 'vue'
import { INVITATIONS, USERS } from '@/graphqlBackOffice/users'
import { transformInvitations } from '@/graphqlBackOffice/users/transformers'
import {
  useAction,
  useFetch,
  useQueryFilters,
} from '@/graphqlBackOffice/composables'
import { TableFiltersQuery } from '@/types/components'
import {
  RESEND_INVITATION,
  SEND_INVITATION,
  DELETE_USERS,
  DELETE_INVITATIONS,
  UPDATE_USER,
  UPDATE_USER_INVITATION,
} from '@/graphqlBackOffice/users/mutations'
import { Invitation, User } from '@/types/models'

class UsersService {
  public getUsers(usersQuery: TableFiltersQuery, customFilters = {}) {
    const { computedQuery } = useQueryFilters()
    const { result, refetch, loading } = useFetch(
      USERS,
      computedQuery(
        usersQuery,
        ['email', 'username', 'country', 'position'],
        customFilters
      ),
      {
        fetchPolicy: 'cache-and-network',
      }
    )

    const users: ComputedRef<{ items: User[]; count: number }> = computed(
      () => result?.value?.users
    )

    const refetchUsers = (
      usersQuery: TableFiltersQuery,
      customFilters = {}
    ) => {
      const { computedQuery } = useQueryFilters()
      return refetch(
        computedQuery(
          usersQuery,
          ['email', 'username', 'country', 'position'],
          customFilters
        )
      )
    }

    return { result: users, refetchUsers, loading }
  }

  public getProjectUsers(usersQuery: TableFiltersQuery, projectId: string) {
    const { computedOperator } = useQueryFilters()
    return this.getUsers(
      usersQuery,
      computedOperator('projectIds', 'in', [projectId])
    )
  }

  public getInvitations(usersQuery: TableFiltersQuery, customFilters = {}) {
    const { computedQuery } = useQueryFilters()
    const { result, refetch, loading } = useFetch(
      INVITATIONS,
      computedQuery(usersQuery, ['userEmail'], customFilters),
      {
        fetchPolicy: 'cache-and-network',
      }
    )

    const invitations: ComputedRef<{ items: Invitation[]; count: number }> =
      computed(() => {
        return {
          items: transformInvitations(result.value?.invitations.items) || [],
          count: result.value?.invitations.count,
        }
      })

    const refetchInvitations = (
      usersQuery: TableFiltersQuery,
      customFilters = {}
    ) => refetch(computedQuery(usersQuery, ['userEmail'], customFilters))

    return { result: invitations, refetchInvitations, loading }
  }

  public getProjectInvitations(
    usersQuery: TableFiltersQuery,
    projectId: string
  ) {
    const { computedOperator } = useQueryFilters()
    return this.getInvitations(
      usersQuery,
      computedOperator('projectIds', 'in', [projectId])
    )
  }

  public useMutations() {
    return {
      sendInvitation: useAction(SEND_INVITATION),
      resendInvitation: useAction(RESEND_INVITATION, {
        refetchQueries: [{ query: INVITATIONS }],
      }),
      deleteUsers: useAction(DELETE_USERS),
      deleteInvitations: useAction(DELETE_INVITATIONS),
      updateUser: useAction(UPDATE_USER),
      updateUserInvitation: useAction(UPDATE_USER_INVITATION),
    }
  }
}

export default new UsersService()
