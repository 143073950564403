
import { LANGUAGES } from '@/utils/constants'
import { ref } from '@vue/reactivity'
import { defineComponent, PropType } from '@vue/runtime-core'
export default defineComponent({
  props: {
    title: {
      type: String,
      default: 'Edit offer languages',
    },
    options: {
      type: Array as PropType<string[]>,
      required: true,
    },
    selectedOptions: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['close', 'submit'],
  setup(props, { emit }) {
    const languages = ref<string[]>(props.selectedOptions)

    return {
      handleChange: (lang: string) => {
        if (languages.value.includes(lang))
          languages.value = languages.value.filter((l) => l !== lang)
        else languages.value = [...new Set([...languages.value, lang])]
      },
      handleSubmit: () => emit('submit', languages.value),
      LANGUAGES,
      emit,
      languages,
    }
  },
})
