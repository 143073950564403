
import { computed, defineComponent, ref, watch } from 'vue'
import { pageContentLoading } from '@/components/common/styles'
import { useRoute, useRouter } from 'vue-router'
import { campaignFunnelsStyle as css } from '@/views/campaigns/styles'
import { formError } from '@/components/ui/styles'
import BckFunnelCard from '@/components/ui/BckFunnelCard.vue'
import { DateRange, Funnel } from '@/types/models'
import { capitalizeFirstLetter } from '@/utils/textHelpers'
import { useStore } from '@/store'
import { CampaignsService } from '@/graphqlBackOffice/campaigns'
import { useLocalSearch } from '@/composables/useLocalInputSearch'
import { areIntervalsOverlapping } from 'date-fns'
import BckEditText from '@/components/ui/BckEditText.vue'
import NoDataMessage from '@/components/common/NoDataMessage.vue'
import { useTabs } from '@/composables/useTabs'
import { size } from 'lodash'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'
import { ProductBuilderService } from '@/graphqlBackOffice/productBuilder'

export default defineComponent({
  name: 'CampaignFunnels',
  components: { NoDataMessage, BckEditText, BckFunnelCard },

  props: {
    id: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { push } = useRouter()
    const store = useStore()
    let loadingDuplicate = ref(false)
    let loadingRefetch = ref(false)
    const updatingNameLoading = ref(false)
    let errorMessage = ref('')
    let updatingNameError = ref('')
    let { campaign, loading: loadingCampaign } = CampaignsService.getCampaignById(props.campaignId, 'cache-first')
    const { productDefaultLanguage } = ProductBuilderService.getProjectDefaultLanguage(props.id)

    const { deleteFunnel: deleteFunnelAction } = CampaignsService.useMutations()
    const route = useRoute()
    const tabInitVal = route.query.selectedTab ? (route.query.selectedTab as string) : 'active'

    const { changeSelectedTab, selectedTab } = useTabs(tabInitVal, true)

    const goToAddFunnel = () => {
      store.dispatch('campaignsModule/setFunnel', undefined)
      push({ name: 'Create Funnel', params: { campaignId: props.campaignId } })
    }

    const goToCampaignsTable = () => {
      push({ name: 'Funnel Manager' })
    }

    const {
      loading: loadingFunnels,
      result: funnels,
      refetchFunnels,
    } = CampaignsService.getFunnels({}, { campaignId: props.campaignId })

    const { updateSearchKeyword, filteredItems: filteredByKeyword } = useLocalSearch(funnels, ['name'])

    const deleteFunnel = async (funnel: Funnel) => {
      errorMessage.value = ''
      await deleteFunnelAction.mutate({ id: funnel._id })

      const { loading } = refetchFunnels({}, { campaignId: props.campaignId })
      loadingRefetch = loading
    }

    const range = ref()

    const onDateInputChange = (newRange: DateRange) => {
      range.value = newRange
    }

    const filteredFunnels = computed(() => {
      return filteredByKeyword.value.filter((item: Funnel) => {
        if (item.startDate && item.endDate && range.value?.start) {
          return areIntervalsOverlapping({ start: item.startDate, end: item.endDate }, range.value)
        }
        return true
      })
    })

    const activeFunnels = computed(() => filteredFunnels.value.filter((item: Funnel) => item.status === 'PUBLISHED'))

    const draftFunnels = computed(() => filteredFunnels.value.filter((item: Funnel) => item.status === 'NOT_PUBLISHED'))

    const duplicateFunnel = async (funnel: Funnel) => {
      errorMessage.value = ''
      loadingDuplicate.value = true
      const { result, loading } = CampaignsService.getRawFunnelById(funnel?._id || '')
      await watch(
        () => loading.value,
        async (loading) => {
          if (!loading) {
            try {
              await CampaignsService.duplicateFunnel(result.value?.funnels?.[0], props.campaignId)
              const { loading } = refetchFunnels({}, { campaignId: props.campaignId })
              loadingRefetch = loading
            } catch (error) {
              errorMessage.value = 'Something went wrong, please try again later'
            } finally {
              loadingDuplicate.value = false
            }
          }
        }
      )
    }

    const updateCampaignName = async ($event: Event) => {
      updatingNameError.value = ''
      updatingNameLoading.value = true
      const target = $event.target as HTMLInputElement

      try {
        const { error } = await CampaignsService.updateCampaign(props.campaignId, { name: target.value })
        if (error) {
          if (error.message?.indexOf('duplicate key error') > -1) {
            updatingNameError.value = 'This name is already in use.'
          } else {
            updatingNameError.value = 'Something went wrong, please try again later'
          }
        }
      } catch (error) {
        updatingNameError.value = 'Something went wrong, please try again later'
      }

      if (campaign.value) {
        campaign.value.name = target.value
      }

      updatingNameLoading.value = false
    }

    const canCreateFunnel = isAuthorized(Permissions.CREATE_NEW_FUNNEL, props.id)

    const canDuplicateFunnel = isAuthorized(Permissions.DUPLICATE_FUNNEL, props.id)

    const canDeleteFunnel = isAuthorized(Permissions.DELETE_FUNNEL, props.id)

    const canEditCampaignTitle = isAuthorized(Permissions.EDIT_CAMPAIGN_TITLE, props.id)

    return {
      activeFunnels,
      campaign,
      capitalizeFirstLetter,
      changeSelectedTab,
      css: { ...css, formError },
      deleteFunnel,
      draftFunnels,
      duplicateFunnel,
      errorMessage,
      loading: loadingCampaign || loadingFunnels || loadingRefetch,
      loadingFunnels: loadingFunnels || loadingRefetch,
      loadingDuplicate,
      filteredFunnels: computed(() =>
        selectedTab.value === 'active' ? activeFunnels.value || [] : draftFunnels.value || []
      ),
      funnels,
      goToAddFunnel,
      goToCampaignsTable,
      onDateInputChange,
      pageContentLoading,
      range,
      selectedTab,
      size,
      tabsData: computed(() => [
        { key: 'active', name: `Active (${size(activeFunnels.value)})` },
        { key: 'drafts', name: `Drafts (${size(draftFunnels.value)})` },
      ]),
      updateSearchKeyword,
      updateCampaignName,
      updatingNameLoading,
      updatingNameError,
      canCreateFunnel,
      canDuplicateFunnel,
      canDeleteFunnel,
      canEditCampaignTitle,
      defaultLanguage: computed(() => {
        if (campaign.value?.languages) {
          return campaign.value.languages.includes(productDefaultLanguage.value)
            ? productDefaultLanguage.value
            : campaign.value.languages[0]
        } else return ''
      }),
    }
  },
})
