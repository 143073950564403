
import { computed, defineComponent, onMounted, reactive, watch } from 'vue'
import InvitationsTable from '@/views/user/components/InvitationsTable.vue'
import UsersTable from '@/views/user/components/UsersTable.vue'
import { adminPage } from '@/views/user/styles'
import { tablePanel } from '@/components/ui/styles'
import { roles, statuses } from '@/utils/constants'
import { useModal } from '@/composables/useModal'
import { useTableView } from '@/composables/useTableView'
import { useTabs } from '@/composables/useTabs'
import DeleteSelectedItemsButton from '@/components/common/DeleteSelectedItemsButton.vue'
import UserFormModal from '@/views/user/components/UserFormModal.vue'
import UsersService from '@/graphqlBackOffice/users/service'
import { useRouter } from 'vue-router'
import { useQueryFilters } from '@/graphqlBackOffice/composables'
import { removeProjectFromUser } from '@/graphqlBackOffice/users/transformers'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

/* eslint-disable @typescript-eslint/no-explicit-any */

export default defineComponent({
  components: {
    UserFormModal,
    DeleteSelectedItemsButton,
    InvitationsTable,
    UsersTable,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const state = reactive({
      users: {},
      invites: {},
      usersTableLoading: {},
      invitesTableLoading: {},
    })
    let refetchUsersQuery: any = undefined
    let refetchInvitationsQuery: any = undefined

    const canInviteUsers = isAuthorized(Permissions.INVITE_USERS_TO_PROJECT, props.id)
    const canChangeUserRole = isAuthorized(Permissions.CHANGE_PROJECT_USER_ROLES, props.id)

    const { isModalVisible, closeModal, showModal } = useModal()
    const {
      queryInitialValues,
      selectedItems,
      toggleSelectedItems,
      onSearchInputChange,
      getFiltersFromCurrentRoute,
      onTableSort,
    } = useTableView()
    const { updateUserInvitation, updateUser } = UsersService.useMutations()
    const { changeSelectedTab, selectedTab } = useTabs(queryInitialValues.selectedTab || 'users', true)
    const { currentRoute } = useRouter()

    const getUsersOrInvites = () => {
      const filters = getFiltersFromCurrentRoute()
      const { computedOperator } = useQueryFilters()

      if (selectedTab.value === 'users') {
        if (!refetchUsersQuery) {
          const { loading, refetchUsers, result } = UsersService.getProjectUsers(filters, props.id)
          state.usersTableLoading = loading
          state.users = result || { items: [], count: 0 }
          refetchUsersQuery = refetchUsers
        } else {
          if (!state.usersTableLoading) {
            refetchUsersQuery(filters, computedOperator('projectIds', 'in', [props.id]))
          }
        }
      } else {
        if (!refetchInvitationsQuery) {
          const { loading, refetchInvitations, result } = UsersService.getProjectInvitations(filters, props.id)
          state.invitesTableLoading = loading
          state.invites = result || { items: [], count: 0 }
          refetchInvitationsQuery = refetchInvitations
        } else {
          if (!state.invitesTableLoading) {
            refetchInvitationsQuery(filters, computedOperator('projectIds', 'in', [props.id]))
          }
        }
      }
    }

    watch(currentRoute, getUsersOrInvites)

    onMounted(() => {
      getUsersOrInvites()
    })

    const deleteButtonProps = computed(() => {
      return {
        selectedItems: selectedItems.value?.[selectedTab.value] || [],
        fieldName: selectedTab.value === 'users' ? 'username' : 'userEmail',
        entityNameSingular: selectedTab.value === 'users' ? 'user' : 'invitation',
        entityNamePlural: selectedTab.value === 'users' ? 'users' : 'invitations',
      }
    })

    const deleteItems = async () => {
      // Remove only from current project if user is deleted from project authorization page.
      await Promise.all(
        selectedItems.value[selectedTab.value].map(async (user) => {
          const updatedUser = removeProjectFromUser(user, props.id)
          if (selectedTab.value === 'users') {
            await updateUser.mutate({ id: user?._id, record: updatedUser })
          } else {
            await updateUserInvitation.mutate({
              id: user?._id,
              record: updatedUser,
            })
          }
        })
      )

      getUsersOrInvites()
      selectedItems.value[selectedTab.value] = []
    }

    return {
      css: { adminPage, tablePanel },
      queryInitialValues,
      state,
      changeSelectedTab,
      selectedTab,
      roles,
      statuses,
      onTableSort,
      onSearchInputChange,
      showModal,
      closeModal,
      isModalVisible,
      deleteButtonProps,
      selectedItems,
      toggleSelectedItems,
      getUsersOrInvites,
      deleteItems,
      canInviteUsers,
      canChangeUserRole,
      tabsData: [
        { key: 'users', name: 'Users' },
        { key: 'invites', name: 'Invites' },
      ],
    }
  },
})
