
import { roles, projectRoles, statuses } from '@/utils/constants'
import {
  defineComponent,
  computed,
  reactive,
  PropType,
  onMounted,
  Ref,
  ref,
} from 'vue'
import { dot, toggleIcon } from '@/views/user/styles'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import UsersService from '@/graphqlBackOffice/users/service'
import { Invitation, ProjectRole, ProjectUserRole, User } from '@/types/models'
import { formError, hidden, loading } from '@/components/ui/styles'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import {
  formatProjectInvitation,
  removeProjectFromUser,
} from '@/graphqlBackOffice/users/transformers'
import { textBubbleStyle } from '@/components/ui/styles'
import { useErrors } from '@/graphqlBackOffice/composables/useErrors'
import { useQueryFilters } from '@/graphqlBackOffice/composables'
import omitDeep from 'omit-deep-lodash'
import { roleColorMap } from '@/utils/colorMapper'
import { useNotifications } from '@/composables/useNotifications'

export default defineComponent({
  name: 'UserFormModal',
  props: {
    user: {
      type: Object as PropType<User>,
      default: null,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      default: '',
    },
    isCurrentUser: {
      type: Boolean,
      default: false,
    },
    canChangeRole: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close', 'change'],

  setup(props, { emit }) {
    const state = reactive({
      loadingDelete: false,
      loadingSave: false,
      projects: {},
      error: '',
    })
    const { sendInvitation, updateUser, deleteUsers } =
      UsersService.useMutations()
    const toast = useNotifications()

    // Selected projects with the roles assigned.
    const projectUserRoles: Ref<ProjectRole[]> = ref([])

    const customValidationSchema = yup.object().shape({
      userEmail: props.user
        ? yup.string()
        : yup
            .string()
            .email('Invalid email')
            .required('This field is required.'),
      username: props.user
        ? yup.string().required('This field is required.')
        : yup.string(),
      position: yup.string().nullable().required('This field is required.'),
      status: yup.string(),
      role: yup.string().required('This field is required.'),
    })

    const { errors, handleSubmit, resetForm, setValues, values } = useForm({
      validationSchema: customValidationSchema,
    })

    onMounted(() => {
      if (props.user) {
        setValues({
          username: props.user.username,
          position: props.user.position,
          role: props.projectId
            ? getRoleForProject(props.user.projectRoles, props.projectId)?.value
            : props.user.role,
          status: props.user.status,
        })
      }
    })

    const save = handleSubmit(async (values: Invitation | User) => {
      try {
        state.loadingSave = true
        if (!props.user) {
          // Add current project if user is invited from project authorization page
          if (props.projectId) {
            values.projectIds = [props.projectId]
            values.projectRoles = [
              {
                projectId: props.projectId,
                role: values.role as ProjectUserRole,
              },
            ]
            values.role = 'FF_USER'
          } else if (values.role === 'FF_USER') {
            values.projectRoles = projectUserRoles.value
          }
          await sendInvitation.mutate({
            record: formatProjectInvitation(values as Invitation),
          })
        } else {
          values.projectRoles = []
          if (props.projectId) {
            props.user.projectRoles.forEach((item) => {
              values.projectRoles.push({
                projectId: item.projectId,
                role:
                  item.projectId === props.projectId
                    ? (values.role as ProjectUserRole)
                    : item.role,
              })
            })
            values.role = 'FF_USER'
          } else {
            values.projectRoles = omitDeep(
              props.user.projectRoles,
              '__typename'
            )
          }
          await updateUser.mutate({ id: props.user._id, record: values })
        }
        closeModal()

        toast.notify({
          text: 'Invitation sent successfully!',
          type: 'success',
        })
        emit('change')
      } catch (error) {
        const { networkErrorMessage } = useErrors(sendInvitation.error)
        state.error =
          networkErrorMessage.value ||
          'Something went wrong, please try again later.'
      } finally {
        state.loadingSave = false
      }
    })

    const deleteUser = async () => {
      const { computedOperator } = useQueryFilters()

      try {
        state.loadingDelete = true
        // Remove only from current project if user is deleted from project authorization page.
        if (props.projectId) {
          // Remove only from current project if user is deleted from project authorization page.
          const updatedUser = removeProjectFromUser(props.user, props.projectId)
          await updateUser.mutate({ id: props.user?._id, record: updatedUser })
        } else {
          await deleteUsers.mutate({
            filter: computedOperator('_id', 'in', props.user._id),
          })
        }

        closeModal()
        emit('change')
      } catch (error) {
        state.error = 'Something went wrong, please try again later.'
      } finally {
        state.loadingDelete = false
      }
    }

    const projectsOptions = computed(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return Object.values(state.projects).map((item: any) => {
        return {
          value: item._id,
          label: item.name,
        }
      })
    })

    const changeRole = (value: string) => {
      if ((value === 'FF_USER' || value === 'ADMIN') && !props.user) {
        const { projects } = ProjectsService.getProjects(false)
        state.projects = projects
      }
    }

    const toggleProjects = (selectedProjects: Array<string>) => {
      // Add new projects
      selectedProjects.map((projectId) => {
        if (
          !projectUserRoles.value.find((item) => item.projectId === projectId)
        ) {
          projectUserRoles.value.push({
            projectId: projectId,
            role: 'CLIENT',
          })
        }
      })

      // Remove the deselected projects
      projectUserRoles.value = projectUserRoles.value.filter(
        (item: ProjectRole) => {
          return selectedProjects.indexOf(item.projectId) > -1
        }
      )
    }

    const changeProjectRole = (value: ProjectUserRole, projectId: string) => {
      projectUserRoles.value.forEach((item) => {
        if (item.projectId === projectId) {
          item.role = value
        }
      })
    }

    const changeStatus = () => {
      if (props.isCurrentUser) return
      values.status = values.status === 'DISABLED' ? 'ACTIVE' : 'DISABLED'
    }

    const closeModal = () => {
      resetForm()
      state.error = ''
      emit('close')
    }

    const hasErrors = computed(() => {
      return Object.values(errors.value).length > 0
    })

    /**
     * Returns the role of the user on a certain project.
     */
    const getRoleForProject = (
      projectUserRoles: ProjectRole[],
      projectId: string
    ) => {
      const projectRole = projectUserRoles.find(
        (i) => i.projectId === projectId
      )
      return projectRole ? projectRoles[projectRole.role] : undefined
    }

    return {
      roleColorMap,
      css: { hidden, dot, formError, loading, toggleIcon, textBubbleStyle },
      changeStatus,
      toggleProjects,
      deleteUser,
      changeProjectRole,
      getRoleForProject,
      updateUser,
      closeModal,
      changeRole,
      projectsOptions,
      values,
      hasErrors,
      errors,
      save,
      roles,
      projectRoles,
      statuses,
      state,
      projectUserRoles,
    }
  },
})
