
import { computed, defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useForm, FormActions } from 'vee-validate'
import * as yup from 'yup'
import { ApolloCache } from '@apollo/client/cache/core/cache'
import { formError } from '@/components/ui/styles'
import { projectConfigurationStyle as cssConfig } from '../styles'
import { projectStepStyle as css } from '../styles'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { updateCachedProjectFields } from '@/graphqlBackOffice/projects'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

interface EnterDomainFormType {
  domainName: string
  password: string
}

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { loading, project } = ProjectsService.getProjectById(props.id)
    const { updateFFSocialDomainConfig } = ProjectsService.useMutations()
    const router = useRouter()
    const isSavingDomainName = ref(false)
    const isValid = ref(true)
    const isInUse = ref(false)

    const domainId = computed(() => project.value && project.value?.domain?._id)
    const projectName = computed(() => project.value?.name)
    const canEdit = isAuthorized(Permissions.CONFIGURE_PROJECT_DOMAIN, props.id)

    const customValidationSchema = yup.object().shape({
      domainName: yup
        .string()
        .test(
          'value',
          'This name is not allowed',
          (val) => val?.toLowerCase() !== 'preview'
        )
        .matches(/^[a-z]+$/, 'Do not use special characters')
        .required('This field is required.'),
    })

    const { handleSubmit, isSubmitting, setFieldValue, errors } = useForm({
      initialTouched: {
        domainName: true,
      },
      validationSchema: customValidationSchema,
    })

    const sanitizeDomainName = (name: string) => {
      return name.replace(/[^a-z]/gi, '')
    }

    watch([loading, project], ([loading, project]) => {
      if (!loading) {
        if (project?.domain?.productionDomainConfigurationId)
          router.push({ name: 'Configuration' })

        projectName.value && setFieldValue('domainName', sanitizeDomainName(projectName.value))
      }
    })

    const onSubmit = handleSubmit(
      async (
        values: EnterDomainFormType,
        { resetForm }: typeof FormActions
      ) => {
        isSavingDomainName.value = true
        try {
          const response = await updateFFSocialDomainConfig.mutate(
            {
              domainId: domainId.value,
              domainName: values.domainName,
            },
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              update: (cache: ApolloCache<any>) => {
                updateCachedProjectFields(cache, props.id, {
                  domainName: values.domainName,
                })
              },
            }
          )
          resetForm()
          isSavingDomainName.value = false
          if (response?.data.updateFFSocialDomain.error) {
            isValid.value = false
          } else {
            router.push({ name: 'Configuration' })
          }
        } catch (e) {
          if (e.networkError.statusCode == 400) {
            isInUse.value = true
            isSavingDomainName.value = false
          }
        }
      }
    )

    const onChange = () => {
      isInUse.value = false
      isSavingDomainName.value = false
    }

    return {
      errors,
      canEdit,
      loading,
      formError,
      isValid,
      isInUse,
      onChange,
      onSubmit,
      isSavingDomainName,
      domainId,
      isSubmitting,
      cssConfig,
      css,
    }
  },
})
