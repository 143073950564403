import gql from 'graphql-tag'

export const UPDATE_PWA_CONFIGURATION = gql`
  mutation UpdatePwaConfiguration($pwaConfiguration: PwaConfigurationInput) {
    updatePwaConfiguration(pwaConfiguration: $pwaConfiguration) {
      popupDescription {
        notificationsDeclined {
          translations {
            lang
            value
          }
        }
        notificationsEnabledSuccesfully {
          translations {
            lang
            value
          }
        }
        appAlreadyInstalled {
          translations {
            lang
            value
          }
        }
        webapp {
          translations {
            lang
            value
          }
        }
      }
      _id
      pwaTriggers
      pwaIconId
    }
  }
`
