import { style } from 'typestyle'

export const loading = style({
  margin: '150px auto',
  display: 'flex !important',
})

export const inputWrapper = style({
  $nest: {
    input: {
      background: 'transparent !important',
    },
    label: {
      $nest: {
        '&:before': {
          background: 'transparent !important',
        },
      },
    },
  },
})

export const selectWrapper = style({
  $nest: {
    div: {
      $nest: {
        input: {
          background: 'transparent !important',
        },
        label: {
          $nest: {
            '&:before': {
              background: 'transparent !important',
            },
          },
        },
      },
    },
  },
})
