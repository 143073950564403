import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckCheckbox = _resolveComponent("BckCheckbox")!
  const _component_BckText = _resolveComponent("BckText")!
  const _component_BckTextBubble = _resolveComponent("BckTextBubble")!
  const _component_BckLink = _resolveComponent("BckLink")!
  const _component_BckTable = _resolveComponent("BckTable")!
  const _component_UserFormModal = _resolveComponent("UserFormModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BckTable, {
      rows: _ctx.rows,
      cells: _ctx.usersTableCells,
      sortable: "",
      loading: _ctx.loading,
      count: _ctx.count,
      "initial-sorted-by": _ctx.queryInitialValues.sortedBy,
      "initial-sort-order": _ctx.queryInitialValues.sortOrder,
      onSort: _ctx.onTableSort
    }, {
      default: _withCtx(({ row }) => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.css.tableRow({})),
          onClick: ($event: any) => (_ctx.openEditUser(row))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell('5%')),
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createVNode(_component_BckCheckbox, {
              name: row._id,
              disabled: row._id === _ctx.currentUser._id,
              checked: !!_ctx.selectedUsers.find((item) => item._id === row._id),
              onChange: ($event: any) => (_ctx.$emit('toggle-user', row))
            }, null, 8, ["name", "disabled", "checked", "onChange"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell('15%'))
          }, [
            _createVNode(_component_BckText, {
              color: "primary-blue",
              size: "small",
              weight: "bold",
              clickable: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.username), 1)
              ]),
              _: 2
            }, 1024)
          ], 2),
          (!_ctx.projectId)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.css.tableCell('10%'))
              }, [
                _createVNode(_component_BckTextBubble, {
                  size: "46px",
                  centered: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(row.role === 'SUPER_ADMIN' ? 'All' : row.projectIds.length), 1)
                  ]),
                  _: 2
                }, 1024)
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell('15%'))
          }, [
            _createVNode(_component_BckTextBubble, {
              size: "123px",
              color: _ctx.roleColorMap[_ctx.roles[_ctx.getUserRole(row)].value]
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.roles[_ctx.getUserRole(row)].label), 1)
              ]),
              _: 2
            }, 1032, ["color"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell('10%'))
          }, [
            _createVNode(_component_BckText, {
              color: "gray",
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.country || '-'), 1)
              ]),
              _: 2
            }, 1024)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell('15%'))
          }, [
            _createVNode(_component_BckText, {
              color: "gray",
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.position || '-'), 1)
              ]),
              _: 2
            }, 1024)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell('20%'))
          }, [
            _createVNode(_component_BckLink, {
              weight: "normal",
              href: `mailto:${row.email}`,
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.email), 1)
              ]),
              _: 2
            }, 1032, ["href"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell('10%'))
          }, [
            _createVNode(_component_BckText, {
              color: _ctx.statuses[row.status].color
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.statuses[row.status].label), 1)
              ]),
              _: 2
            }, 1032, ["color"])
          ], 2)
        ], 10, _hoisted_1)
      ]),
      _: 1
    }, 8, ["rows", "cells", "loading", "count", "initial-sorted-by", "initial-sort-order", "onSort"]),
    (_ctx.isModalVisible)
      ? (_openBlock(), _createBlock(_component_UserFormModal, {
          key: 0,
          user: _ctx.editedUser,
          "show-delete": !_ctx.isCurrentUser,
          "is-current-user": _ctx.isCurrentUser,
          "project-id": _ctx.projectId,
          "can-change-role": _ctx.canChangeUserRole,
          onClose: _ctx.closeModal,
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change')))
        }, null, 8, ["user", "show-delete", "is-current-user", "project-id", "can-change-role", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}