
import { defineComponent } from 'vue'
import { loading } from '@/views/project/styles/ProjectConfiguration'
import * as css from '../styles/ConfigurationStep'

export default defineComponent({
  props: {
    stepDescription: {
      type: String,
      required: true,
    },
    stepCompleted: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { loadingStyle: loading, css }
  },
})
