
import { computed, defineComponent, ref, watch } from 'vue'
import * as yup from 'yup'
import BckFile from '@/components/ui/BckFile.vue'
import BckText from '@/components/ui/BckText.vue'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { useAction } from '@/graphqlBackOffice/composables'
import { updateProject } from '@/graphqlBackOffice/projects/localCache'
import { useProjectStyle } from '@/composables/useProjectStyle'
import { MetaData, Asset, AssetType } from '@/types/models'
import { useForm } from 'vee-validate'
import { isImage, testFileSize } from '@/utils/fieldsValidations'
import { useRouter } from 'vue-router'
import { useFormState } from '@/composables/useFormState'
import { getFullCdnUrlByAsset } from '@/graphqlBackOffice/projects/transformers'
import { IMAGES_EXT } from '@/utils/constants'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'
import * as css from '../styles/ProjectMetaDescription'

/* eslint-disable @typescript-eslint/no-explicit-any */

export default defineComponent({
  components: { BckFile, BckText },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { loading, project } = ProjectsService.getProjectById(props.id)
    const {
      createAsset: createAssetMutation,
      updateAsset: updateAssetMutation,
      removeAsset: removeAssetMutation,
      updateMetaData,
    } = ProjectsService.useMutations()
    const saving = ref(false)
    const router = useRouter()
    const errorMessage = ref('')
    const metaData = ref()

    const { branding, setBranding, getProjectAsset } = useProjectStyle()
    const canEdit = isAuthorized(
      Permissions.EDIT_PROJECT_META_INFORMATION,
      props.id
    )

    const setMetaData = (projectMetaData: MetaData | undefined): void => {
      if (projectMetaData) {
        metaData.value = {
          _id: projectMetaData._id || '',
          title: projectMetaData.title || '',
          description: projectMetaData.description || '',
          asset: projectMetaData.asset || '',
          favicon: projectMetaData.favicon || null,
          appStoreLink: projectMetaData.appStoreLink || '',
          googlePlayLink: projectMetaData.googlePlayLink || '',
        }
      }
    }

    const metaLogo = computed(() => {
      const metaLogo =
        metaData.value?.asset || getProjectAsset(branding.value?.assets, 'logo')
      if (metaLogo) {
        return typeof metaLogo.resourcePath === 'string'
          ? getFullCdnUrlByAsset(metaLogo)
          : metaLogo.resourcePath
      }
      return null
    })

    const metaFavicon = computed(() => {
      const metaFavicon =
        metaData.value?.favicon ||
        getProjectAsset(branding.value?.assets, 'favicon')
      if (metaFavicon) {
        return typeof metaFavicon.resourcePath === 'string'
          ? getFullCdnUrlByAsset(metaFavicon)
          : metaFavicon.resourcePath
      }
      return null
    })

    const metaDescriptionValidation = yup.object().shape({
      metaTitle: yup
        .string()
        .trim()
        .required('This field is required')
        .nullable(),
      metaDescription: yup
        .string()
        .trim()
        .required('This field is required')
        .nullable(),
      metaLogo: yup
        .mixed()
        .test(
          'fileSize',
          'File is too large',
          (value) => !(value?.[0] && !testFileSize(value?.[0]))
        )
        .test(
          'fileType',
          'Unsupported file type',
          (value) => !(value?.[0] && !isImage(value?.[0]))
        ),
      favicon: yup
        .mixed()
        .test(
          'fileSize',
          'File is too large. Try to use 32x32 or 16x16 image',
          (value) => !(value?.[0] && !testFileSize(value?.[0], 5000))
        )
        .test(
          'fileType',
          'Unsupported file type',
          (value) => !(value?.[0] && !isImage(value?.[0]))
        ),
      metaAppLinksEnabled: yup
        .boolean()
        .notRequired(),
      metaAppStoreLink: yup
        .string()
        .notRequired()
        .matches(
          /^((https:\/\/)?(www.)?(apps\.apple\.com)((\/)[\w#-_.~]+)*(\/\w+\?[a-zA-Z0-9_]+=[^"<>^`{|}&]+(&[a-zA-Z0-9_]+=[^"<>^`{|}&]+)*)?)?$/gm,
          'This field needs to be an IOS link'
        ),
      metaGooglePlayLink: yup
        .string()
        .notRequired()
        .matches(
          /^((https:\/\/)?(www.)?(play\.google\.com)((\/)[\w#-_.~]+)*(\/\w+\?[a-zA-Z0-9_]+=[^"<>^`{|}&]+(&[a-zA-Z0-9_]+=[^"<>^`{|}&]+)*)?)?$/gm,
          'This field needs to be an Android link'
        ),
    })

    const { setValues, values, errors, meta } = useForm({
      validationSchema: metaDescriptionValidation,
    })

    // Check for unsaved changes on page leave.
    const { useLeavingConfirmation } = useFormState(meta, values)
    useLeavingConfirmation()

    const hasErrors = computed(() => {
      // ignore favicon errors because field is not required and input doesn't take invalid values
      delete errors.value.favicon
      return Object.values(errors.value).length > 0
    })

    const { mutate: localMutation } = useAction(updateProject)

    const updateAsset = async (
      projectId: string,
      uploadedFile: File | string,
      resourceType: AssetType
    ) => {
      const md = { ...metaData.value }
      if (resourceType === 'metaLogo') {
        md.asset = {
          ...metaData.value.asset,
          resourcePath: uploadedFile || '',
        }
      }

      if (resourceType === 'favicon') {
        md.favicon = {
          resourceType: 'favicon',
          ...metaData.value.favicon,
          _id: metaData.value.favicon?._id || '',
          resourcePath: uploadedFile || '',
        }
      }
      setMetaData(md)
      await localMutation({
        id: projectId,
        fieldName: 'metaData',
        value: metaData.value,
      })

      await localMutation({
        id: projectId,
        fieldName: 'dataPrivacy',
        value: true,
      })
    }

    const updateFile = (filePayload: File, resourceType: AssetType) => {
      updateAsset(props.id, filePayload, resourceType)
    }

    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          setBranding(project.value?.branding)
          setMetaData(project.value?.metaData)
          if (project.value?.settings?.metaDescription) {
            setValues({
              metaTitle: project?.value?.metaData?.title || '',
              metaDescription: project?.value?.metaData?.description,
              metaLogo: project?.value?.metaData?.asset,
              metaFavicon: project?.value?.metaData?.favicon,
              metaAppLinksEnabled: !!project?.value?.metaData?.appStoreLink || !!project?.value?.metaData?.googlePlayLink,
              metaAppStoreLink: project?.value?.metaData?.appStoreLink || '',
              metaGooglePlayLink:
                project?.value?.metaData?.googlePlayLink || '',
            })
          }
        }
      }
    )

    if (!loading.value) {
      setBranding(project.value?.branding)
      setMetaData(project.value?.metaData)
      setValues({
        metaTitle: project?.value?.metaData?.title,
        metaDescription: project?.value?.metaData?.description,
        metaLogo: project?.value?.metaData?.asset,
        metaFavicon: project?.value?.metaData?.favicon,
        metaAppStoreLink: project?.value?.metaData?.appStoreLink || '',
        metaGooglePlayLink: project?.value?.metaData?.googlePlayLink || '',
      })
    }

    const updateFavicon = async (favicon: Asset) => {
      if (favicon?._id && favicon._id !== '') {
        if (typeof favicon?.resourcePath === 'object') {
          const result = await updateAssetMutation.mutate({
            id: favicon._id,
            file: favicon.resourcePath || null,
            resourceType: favicon.resourceType,
            projectName: project?.value?.name,
          })
          await updateAsset(
            props.id,
            result?.data?.updateAsset?.resourcePath,
            'favicon'
          )
          return null
        }

        if (favicon?.resourcePath === '') {
          const result = await removeAssetMutation.mutate({
            id: favicon._id,
            projectName: project?.value?.name,
          })
          await updateAsset(
            props.id,
            result?.data?.updateAsset?.resourcePath,
            'favicon'
          )
          return null
        }
      }

      if (typeof favicon?.resourcePath === 'object') {
        const result = await createAssetMutation.mutate({
          file: favicon.resourcePath,
          resourceType: favicon.resourceType,
          projectName: project?.value?.name,
        })
        await updateAsset(
          props.id,
          result?.data?.createAsset?.resourcePath,
          'favicon'
        )
        return result?.data?.createAsset?._id
      }
    }

    const updateLogo = async (asset: Asset) => {
      if (typeof asset?.resourcePath === 'object') {
        const result = await updateAssetMutation.mutate({
          id: asset._id,
          file: asset.resourcePath || null,
          resourceType: asset.resourceType,
          projectName: project?.value?.name,
        })
        await updateAsset(
          props.id,
          result?.data?.updateAsset?.resourcePath,
          'metaLogo'
        )
      }
      if (asset?.resourcePath === '') {
        const result = await removeAssetMutation.mutate({
          id: asset._id,
          projectName: project?.value?.name,
        })
        await updateAsset(
          props.id,
          result?.data?.updateAsset?.resourcePath,
          'metaLogo'
        )
      }
    }

    const onSubmit = async () => {
      saving.value = true
      try {
        const newFaviconId = await updateFavicon(metaData?.value.favicon)
        await updateLogo(metaData?.value.asset)

        await updateMetaData.mutate({
          _id: project.value?.metaData?._id,
          title: values.metaTitle,
          description: values.metaDescription,
          faviconId: newFaviconId,
          appStoreLink: values.metaAppStoreLink || '',
          googlePlayLink: values.metaGooglePlayLink || '',
        })

        isAuthorized(Permissions.ACCESS_PROJECT_CONFIG, props.id)
          ? router.push({ name: 'Configuration', params: { action: 'save' } })
          : router.push({
              name: 'PWA Configuration',
              params: { action: 'save' },
            })
      } catch (err) {
        errorMessage.value =
          err.response?.data?.error ||
          'Something went wrong, please try again later.'
      } finally {
        saving.value = false
      }
    }

    const updateMetaAppLinksEnabled = () => {
      setValues({
        ...values,
        metaAppLinksEnabled: !values.metaAppLinksEnabled,
        metaAppStoreLink: '',
        metaGooglePlayLink: '',
      })
    }

    return {
      loading,
      project,
      saving,
      onSubmit,
      errorMessage,
      hasErrors,
      values,
      branding,
      updateFile,
      updateAsset,
      getProjectAsset,
      metaLogo,
      metaFavicon,
      metaData,
      IMAGES_EXT,
      canEdit,
      css,
      updateMetaAppLinksEnabled,
    }
  },
})
