
import { defineComponent, watch, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { isEmpty, omit, reduce } from 'lodash'
import { projectsPage } from '../styles'
import { ProjectSettings } from '@/types/models'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { useModal } from '@/composables/useModal'
import { useTabs } from '@/composables/useTabs'
import NoDataMessage from '@/components/common/NoDataMessage.vue'
import { getProjectLogo } from '@/graphqlBackOffice/projects/transformers'
import { useStore } from '@/store'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'

export default defineComponent({
  components: { NoDataMessage },

  setup() {
    const state = reactive({
      isLoading: { value: true },
      projects: {},
      isUnarchiving: false,
      selectedProjectId: '',
    })

    const canCreateProject = isAuthorized(Permissions.CREATE_NEW_PROJECT)

    const { updateProjectArchivation } = ProjectsService.useMutations()

    const route = useRoute()
    const router = useRouter()
    const { currentRoute } = useRouter()
    const store = useStore()

    const tabInitialvalue = route.query.selectedTab ? (route.query.selectedTab as string) : 'my-projects'

    const { changeSelectedTab, selectedTab } = useTabs(tabInitialvalue, true)
    const { loading, refetchProjects, projects } = ProjectsService.getProjects(selectedTab.value === 'archived')
    state.isLoading = loading
    state.projects = projects

    const refetchData = () => {
      currentRoute.value.name === 'Projects' && refetchProjects(selectedTab.value === 'archived')
    }
    watch(currentRoute, refetchData)

    const { isModalVisible, closeModal, showModal } = useModal()

    const openUnarchivingModal = (_id: string) => {
      showModal()
      state.selectedProjectId = _id
    }

    const unarchive = async () => {
      state.isUnarchiving = true
      await updateProjectArchivation.mutate({
        projectId: state.selectedProjectId,
        archived: false,
      })

      state.isUnarchiving = false
      router.push({ name: 'Projects' })
      closeModal()
      state.selectedProjectId = ''
    }

    const checkIfCompleteSetup = (settings: ProjectSettings) =>
      reduce(
        omit(settings, ['__typename']),
        (res, value) => {
          return res && !!value
        },
        true
      )

    const goToCreateProject = () => {
      store.dispatch('projectWizardModule/resetProjectWizard')
      router.push({ name: 'Welcome' })
    }

    return {
      state,
      getProjectLogo,
      getDefaultLogo: ProjectsService.getDefaultLogo,
      goToCreateProject,
      selectedTab,
      changeSelectedTab,
      projectsPage,
      isModalVisible,
      isEmpty,
      closeModal,
      showModal,
      openUnarchivingModal,
      checkIfCompleteSetup,
      unarchive,
      canCreateProject,
    }
  },
  data() {
    return {
      tabsData: [
        {
          key: 'my-projects',
          name: 'Projects',
        },
        {
          key: 'archived',
          name: 'Archive',
        },
      ],
    }
  },
})
