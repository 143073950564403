
import { computed, defineComponent, ref } from 'vue'
import { useProjectStyle } from '@/composables/useProjectStyle'
import SeamlessPreview from '@/components/common/SeamlessPreview.vue'
import { getFullCdnUrlByAssetType } from '@/graphqlBackOffice/projects/transformers'
import { getImagePreview } from '@/utils/uploadsHelpers'
import {
  loaderStyle,
  cardImage,
  fakeImage,
  formatsWrapper,
  cardsWrapper,
  brandsWrapper,
  radioButtonsWrapper,
} from '@/views/project/styles'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'
import { getProject, updateCardDesign } from '@/services/project'
import { useNotifications } from '@/composables/useNotifications'

export default defineComponent({
  components: {
    SeamlessPreview,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const project = ref<any>()
    const loading = ref(false)

    const formatPicked = ref()
    const toast = useNotifications()

    const getProjectData = async () => {
      loading.value = true

      try {
        project.value = await getProject(props.id)
        formatPicked.value = project.value.branding[0].cardDesign
      } catch (error: any) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    getProjectData()

    const defaultOffers = ref([{ id: '', name: '', catalogName: '', languages: [], verticalImage: '', image: '' }])

    const errorMessage = ref('')
    const saving = ref(false)

    const canEdit = isAuthorized(Permissions.EDIT_PROJECT_STYLE, props.id)

    const { getProjectAsset } = useProjectStyle()

    const logo = computed(() => {
      const asset = getProjectAsset(project?.value?.branding?.assets, 'logo')
      if (asset && typeof asset.resourcePath === 'object') {
        return URL.createObjectURL(asset?.resourcePath)
      }
      return getFullCdnUrlByAssetType(project?.value?.branding, 'logo')
    })

    const projectBackgroundImage = computed(() => {
      const asset = getProjectAsset(project?.value?.branding?.assets, 'projectBackgroundImage')
      if (asset && typeof asset.resourcePath === 'object') {
        return URL.createObjectURL(asset?.resourcePath)
      }
      return getFullCdnUrlByAssetType(project?.value?.branding, 'projectBackgroundImage')
    })

    const onSubmit = async () => {
      saving.value = true
      try {
        await updateCardDesign(props.id, formatPicked.value)
        toast.notify({
          text: 'Your changes have been applied.',
          type: 'success',
        })
      } catch (err) {
        errorMessage.value = err?.response?.data?.error || 'Something went wrong, please try again later.'
      } finally {
        saving.value = false
      }
    }

    const seamlessPreviewLogo = computed(() => {
      return getImagePreview(logo.value)
    })

    return {
      css: { loaderStyle },
      cardImage,
      fakeImage,
      formatsWrapper,
      cardsWrapper,
      radioButtonsWrapper,
      brandsWrapper,
      onSubmit,
      errorMessage,
      saving,
      loading,
      project,
      logo,
      projectBackgroundImage,
      seamlessPreviewLogo,
      formatPicked,
      canEdit,
      defaultOffers,
    }
  },
})
