import { style } from 'typestyle'
import seamlessPreviewImage from '@/assets/seamlessMobilePreview.png'

export const container = style({
  display: 'flex',
  justifyContent: 'center',
})

export const mobilePreview = style({
  position: 'relative',
  width: '304px',
  height: '619px',
  background: `url(${seamlessPreviewImage}) no-repeat center center`,
  backgroundSize: 'cover',
})

export const content = style({
  width: '271px',
  height: '521px',
  position: 'absolute',
  borderBottomLeftRadius: '33px',
  borderBottomRightRadius: '33px',
  left: '16px',
  top: '81px',
  overflow: 'hidden',
})

export const from = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '10px',
  height: '36px',
  color: '#000',
  backgroundColor: '#F2F3F5',
})

export const message = style({
  position: 'relative',
  backgroundColor: '#34C759',
  color: '#fff',
  fontSize: '13px',
  borderRadius: '20px',
  padding: '16px',
  margin: '40px 22px',
  wordBreak: 'break-all',

  $nest: {
    svg: {
      position: 'absolute',
      left: '-6px',
      bottom: '-4px',
    },
  }
})

export const link = style({
  marginTop: '16px',
  textDecoration: 'underline',
})

export const disclaimerStyle = {
  justifyContent: 'center',
  paddingRight: '40px',
}

export const emailSender = style({
  fontSize: '12px',
  padding: '12px',
  borderBottom: '1px solid #B3B3B3'
})

export const emailSenderFrom = style({
  color: '#808080',
})

export const emailSenderValue = style({
  marginTop: '8px',
  color: '#000',
})

export const emailSubject = style({
  padding: '12px',
  fontWeight: 'bold',
  wordBreak: 'break-all',
})

export const emailBody = style({
  padding: '12px',
  wordBreak: 'break-all',
})
