
import { computed, defineComponent, PropType } from 'vue'
import { funnelCardStyle, deleteButton, copyButton, longText } from './styles'
import { Funnel } from '@/types/models'
import { useModal } from '@/composables/useModal'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { format } from 'date-fns'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { ConfirmationModal },

  props: {
    funnel: {
      type: Object as PropType<Funnel>,
      required: true,
    },
    languages: {
      type: Array as PropType<string[]>,
      required: true,
    },
    defaultLanguage: {
      type: String,
      required: false,
    },
    canDeleteFunnel: {
      type: Boolean,
      required: true,
    },
    canDuplicateFunnel: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['delete', 'duplicate'],

  setup(props, { emit }) {
    const { isModalVisible, showModal, closeModal } = useModal()
    const { push } = useRouter()
    const deleteFunnel = () => {
      emit('delete', props.funnel)
      closeModal()
    }

    const defaultLanguageOption = computed(() => props.defaultLanguage || '')

    const previewFunnel = () => {
      push({
        name: 'Preview Funnel',
        params: {
          funnelId: props.funnel?._id,
          defaultLanguage: props.defaultLanguage,
        },
      })
    }

    const isPublished = computed(() => props.funnel?.status === 'PUBLISHED')

    return {
      closeModal,
      css: { ...funnelCardStyle, deleteButton, copyButton, longText },
      deleteFunnel,
      format,
      funnelCardStyle,
      isModalVisible,
      previewFunnel,
      showModal,
      isPublished,
      defaultLanguageOption,
    }
  },
})
