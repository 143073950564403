import { style } from 'typestyle'

export const toggleContainer = style({
  position: 'absolute',
  right: '16px',
  top: '8px',
})

export const previewContainer = style({
  position: 'sticky',
  top: 0,
})

export const wrapper = style({
  display: 'flex',
  alignItems: 'center',
})
