
import BckOfferCard from './BckOfferCard.vue'
import { computed, defineComponent, PropType, Ref, ref } from 'vue'
import { transformOfferFromApi, translateOffer } from '@/graphqlBackOffice/campaigns/transformers'
import { individualOfferStyle, copyButton, longText } from '@/components/ui/styles'
import { Offer } from '@/types/models'
import { Sizes } from '@/utils'
import { format } from 'date-fns'
import { useRouter } from 'vue-router'
import { useModal } from '@/composables/useModal'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import ProjectsService from '@/graphqlBackOffice/projects/service'

type CardAction = 'delete' | 'duplicate'

export default defineComponent({
  components: { BckOfferCard, ConfirmationModal },

  props: {
    offer: {
      type: Object as PropType<Offer>,
      required: true,
    },
    entityLabel: {
      type: String,
      default: 'offer',
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'small',
    },
    canDeleteOffer: {
      type: Boolean,
      required: true,
    },
    canDuplicateOffer: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['edit', 'delete', 'duplicate'],

  setup(props, { emit }) {
    const { isModalVisible, showModal, closeModal } = useModal()
    const action: Ref<CardAction> = ref('delete')

    const openModal = (actionName: CardAction) => {
      action.value = actionName
      showModal()
    }

    const confirmAction = () => {
      emit(action.value, props.offer._id)
      closeModal()
    }

    const { push, currentRoute } = useRouter()
    const translatedOffer = computed(() => translateOffer(transformOfferFromApi(props.offer), props.offer.languages[0]))
    const projectId = currentRoute.value.params.id

    const { project } = ProjectsService.getProjectById(projectId as string)
    const cardDesign = computed(() => {
      return project.value?.branding?.cardDesign
    })

    return {
      action,
      css: { copyButton, longText },
      closeModal,
      confirmAction,
      isModalVisible,
      openModal,
      translatedOffer,
      individualOfferStyle,
      push,
      format,
      cardDesign,
    }
  },
})
