
import { computed, defineComponent, ref, watch } from 'vue'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import EditLanguagesModal from './EditLanguagesModal.vue'
import { pageContentLoading } from '@/components/common/styles'
import { useRouter } from 'vue-router'
import { useFormState } from '@/composables/useFormState'
import { useStore } from '@/store'
import { createOfferStyle } from '@/views/campaigns/styles'
import BckOfferCard from '@/components/ui/BckOfferCard.vue'
import { getAllCurrencies, getCurrencyInfoByCode } from 'countries-information'
import { CurrencyInfo } from '@/types/components'
import { CampaignsService, transformOfferForApi } from '@/graphqlBackOffice/campaigns'
import { isImage, testFileSize } from '@/utils/fieldsValidations'
import LanguageTabs from '@/components/common/LanguageTabs.vue'
import { useTranslatableFields } from '@/composables/useTranslatableFields'
import { TranslatedCardButton, Offer } from '@/types/models'
import { formError } from '@/components/ui/styles'
import { translateOffer } from '@/graphqlBackOffice/campaigns/transformers'
import AddReadMoreButton from '@/components/common/AddReadMoreButton.vue'
import { usePayloadButtons } from '@/composables/usePayloadButtons'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { OFFER_TEMPLATES } from '@/views/campaigns/constants'
import { isEmpty, size, values as arrayValues } from 'lodash'
import ProductCheckModal from '@/components/common/ProductCheckModal.vue'
import { useModal } from '@/composables/useModal'
import { scrollTo } from '@/utils/animations'
import { IMAGES_EXT } from '@/utils/constants'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'
import { useErrors } from '@/graphqlBackOffice/composables/useErrors'

export default defineComponent({
  components: {
    AddReadMoreButton,
    LanguageTabs,
    BckOfferCard,
    ProductCheckModal,
    EditLanguagesModal,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    offerId: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const translatableFields = ['name', 'description', 'buttonName', 'readMoreButtonName', 'image', 'verticalImage']
    const store = useStore()
    const { push } = useRouter()
    const pageError = ref('')
    const saving = ref(false)
    const hideVaultData = ref(false)
    const { updateOffer, createOffer } = CampaignsService.useMutations()
    const { project } = ProjectsService.getProjectById(props.id)

    // Reset any previously saved language in the store
    store.dispatch('campaignsModule/setSelectedLanguage', '')

    const changeHideVaultData = () => {
      hideVaultData.value = !hideVaultData.value
    }

    const offer = computed(() => store.state.campaignsModule.newOffer)
    const readMoreTemplate = computed(() => store.state.campaignsModule.newOffer?.readMoreTemplate)

    const {
      loading,
      offer: fetchedOffer,
      readMoreTemplate: fetchedReadMoreTemplate,
    } = props.offerId
      ? CampaignsService.getOfferById(props.offerId, 'cache-and-network')
      : {
          loading: ref(false),
          offer: computed(() => undefined),
          readMoreTemplate: computed(() => undefined),
        }

    store.dispatch('campaignsModule/setSelectedLanguage', '')

    const selectedLanguage = computed(
      () => store.state.campaignsModule.selectedLanguage || project.value?.activeLanguages[0] || 'en'
    )

    const translatedOffer = computed(() => translateOffer(offer.value, selectedLanguage.value))

    const cardDesign = computed(() => {
      return project.value?.branding?.cardDesign
    })

    const selectedOfferTags = ref(translatedOffer.value?.tags as string[])
    const systemOfferTags = ref(['Offer'])

    const changeOfferTags = (value: string[]) => {
      selectedOfferTags.value = value
    }

    watch(
      () => loading.value,
      (loading) => {
        if (!loading) {
          fetchedOffer.value && store.dispatch('campaignsModule/setNewOffer', fetchedOffer.value)

          fetchedReadMoreTemplate.value && store.dispatch(readMoreTemplateAction.value, fetchedReadMoreTemplate.value)

          const filteredTags = translatedOffer.value?.tags as string[]

          selectedOfferTags.value = filteredTags.filter(
            (tag: string) => tag !== 'Offer' && tag !== 'style1' && tag !== 'style2' && tag !== 'style3'
          )

          if (!offer.value) {
            push({ name: 'Offers' })
          }

          const catalogName = translatedOffer.value.id
          delete translatedOffer.value.id

          setValues({
            ...translatedOffer.value,
            readMoreToggle: !isEmpty(fetchedOffer.value?.readMoreButtonName?.translations),
            catalogName: catalogName,
          })
          copyTranslationFromSavedEntity(offer.value, translatableFields)
        }
      }
    )

    const {
      translations,
      updateTranslation,
      getTranslationsForFields,
      translatedFields,
      isAllFormTranslated,
      copyTranslationFromSavedEntity,
    } = useTranslatableFields()
    const { internalPages, extensionPages, pageActions, payloadTypes } = usePayloadButtons(props.id, offer)

    const customValidationSchema = yup.object().shape({
      catalogName: yup.string().required('This field is required.'),
      name: yup.string().required('This field is required.'),
      description: yup.string().required('This field is required.'),
      price: yup.string().required('This field is required.'),
      currency: yup.string().required('This field is required.'),
      buttonName: yup.string().required('This field is required.'),
      readMoreButtonName: yup.string(),
      readMoreToggle: yup.boolean(),
      whatsappShare: yup.boolean(),
      payloadType: yup.string().required('This field is required.'),
      payload: yup.string().required('This field is required.'),
      image: yup
        .mixed()
        .required('This field is required.')
        .test(
          'fileSize',
          'File is too large',
          (value) => !(value?.[0] && typeof value?.[0] === 'object' && !testFileSize(value?.[0]))
        )
        .test(
          'fileType',
          'Unsupported file type',
          (value) => !(value?.[0] && typeof value?.[0] === 'object' && !isImage(value?.[0]))
        )
        .test('isPresent', 'Upload file', (value) => value !== ''),
      verticalImage: yup
        .mixed()
        .required('This field is required.')
        .test(
          'fileSize',
          'File is too large',
          (value) => !(value?.[0] && typeof value?.[0] === 'object' && !testFileSize(value?.[0]))
        )
        .test(
          'fileType',
          'Unsupported file type',
          (value) => !(value?.[0] && typeof value?.[0] === 'object' && !isImage(value?.[0]))
        )
        .test('isPresent', 'Upload file', (value) => value !== ''),
    })

    const initialValue = () => {
      if (!props.offerId) {
        const languages = project.value?.activeLanguages || []
        const initialOffer: Offer = {
          projectId: props.id,
          template: 'OFFER',
          languages,
          name: {
            translations: languages.reduce((r, key: string) => ({ ...r, [key]: '' }), {}),
          },
        }
        store.dispatch('campaignsModule/setSelectedLanguage', languages[0])
        store.dispatch('campaignsModule/setNewOffer', initialOffer)
      }
      return translatedOffer.value
    }
    const { values, handleSubmit, meta, setValues } = useForm({
      validationSchema: customValidationSchema,
      initialValues: initialValue(),
    })

    watch(
      () => values.image,
      (bannerImage) => {
        if (bannerImage) {
          if (!systemOfferTags.value.includes('style1')) systemOfferTags.value.push('style1')
          if (!systemOfferTags.value.includes('style2')) systemOfferTags.value.push('style2')
        } else {
          systemOfferTags.value = systemOfferTags.value.filter((tag) => tag !== 'style1' && tag !== 'style2')
        }
      }
    )

    watch(
      () => values.verticalImage,
      (verticalImage) => {
        if (verticalImage) {
          if (!systemOfferTags.value.includes('style3')) systemOfferTags.value.push('style3')
        } else {
          systemOfferTags.value = systemOfferTags.value.filter((tag) => tag !== 'style3')
        }
      }
    )

    const { useLeavingConfirmation } = useFormState(meta, values)
    useLeavingConfirmation()

    const mergeFormAndSavedData = () => {
      if (offer.value?.buttons && offer.value?.buttons?.length > 1 && !values.readMoreToggle)
        offer.value.buttons = offer.value?.buttons?.filter((button) => button.appearance !== 'outline')
      if (offer.value) offer.value.id = values.catalogName

      const allTags = selectedOfferTags.value
      allTags.push(...systemOfferTags.value)
      selectedOfferTags.value = selectedOfferTags.value.filter(
        (tag: string) => tag !== 'style1' && tag !== 'style2' && tag !== 'style3' && tag !== 'Offer'
      )

      delete values.buttons
      delete values.catalogName
      return {
        projectId: props.id,
        ...offer.value,
        ...values,
        ...getTranslationsForFields(translatableFields),
        ...{
          readMoreTemplate: !values.readMoreToggle
            ? undefined
            : props.offerId
            ? store.state.campaignsModule.readMoreTemplate
            : store.state.campaignsModule.newOffer?.readMoreTemplate,
        },
        ...{ tags: allTags },
        catalogName: offer.value ? offer.value.id : '',
      }
    }
    const saveFormData = () => {
      store.dispatch('campaignsModule/setNewOffer', mergeFormAndSavedData())
    }

    const goBack = () => {
      if (props.offerId) {
        push({ name: 'Offers', params: { action: 'save' } })
      } else {
        saveFormData()
        push({ name: 'Offers', params: { action: 'save' } })
      }
    }

    const onSubmit = handleSubmit(async () => {
      if (!offer.value?.readMoreTemplate && values.readMoreTemplate) {
        scrollTo('.content-top')
        pageError.value = 'Read more template is required.'
      } else if (
        size(arrayValues(project.value?.activeLanguages)) > 1 &&
        !isAllFormTranslated(translatableFields, project.value?.activeLanguages || [])
      ) {
        scrollTo('.content-top')
        pageError.value = 'Some required fields are empty. Please check all language tabs.'
      } else {
        try {
          saving.value = true
          if (!props.offerId) {
            saveFormData()
            offer.value &&
              (await createOffer.mutate({
                offer: transformOfferForApi(offer.value),
              }))
          } else {
            await updateOffer.mutate({
              id: props.offerId,
              offer: transformOfferForApi(mergeFormAndSavedData()),
            })
          }
          store.dispatch('campaignsModule/setNewOffer', undefined)
          push({ name: 'Offers', params: { action: 'save' } })
        } catch (err) {
          const { networkErrorMessage } = useErrors(!props.offerId ? createOffer.error : updateOffer.error)
          scrollTo('.content-top')
          if (networkErrorMessage && networkErrorMessage.value.includes('E11000 duplicate key error collection')) {
            pageError.value = 'Duplicate offer ID'
          } else {
            pageError.value = err?.response?.data?.error || 'Something went wrong, please try again later.'
          }
        } finally {
          saving.value = false
        }
      }
    })

    const previewOffer = computed(() => {
      const noValues = Object.values(values).every((val) => !val)
      if (!offer.value && noValues) {
        return null
      }

      let buttons = !isEmpty(translatedOffer.value?.buttons)
        ? (translatedOffer.value?.buttons as TranslatedCardButton[])
        : values.buttonName !== ''
        ? [{ name: values.buttonName, appearance: 'default' }]
        : OFFER_TEMPLATES[offer?.value?.template || 'OFFER']?.buttons.map((button) => {
            return { ...button }
          })

      const whatsappButton = buttons.find((button) => button.appearance === 'whatsapp')
      if (values.whatsappShare && !whatsappButton) {
        buttons.push({ name: 'Share in Whatsapp', appearance: 'whatsapp' })
      }
      if (!values.whatsappShare && whatsappButton) {
        buttons = buttons.filter((button) => button.appearance !== 'whatsapp')
      }

      const readMoreButton = buttons.find((button) => button.appearance === 'outline')

      if (!values.readMoreToggle && readMoreButton) {
        buttons = buttons.filter((button) => button.appearance !== 'outline')
      }

      if (values.readMoreToggle && !readMoreButton) {
        buttons.push({ name: values.readMoreButtonName || 'Read More', appearance: 'outline' })
      }

      buttons.forEach((button) => {
        switch (button.appearance) {
          case 'default':
            button.name =
              values.buttonName && values.price && values.currency
                ? [values.buttonName, values.price + getCurrencyInfoByCode(values.currency).symbol].join(' ')
                : values.buttonName
            break

          case 'outline':
            button.name = values.readMoreButtonName || ''
        }
      })

      return {
        id: values.id || offer.value?.id,
        name: values.name || translatedOffer.value?.name,
        description: values.description || translatedOffer.value?.description,
        buttons: buttons,
        image:
          values?.image && typeof values?.image === 'object'
            ? values.image[0]
            : values.image || translatedOffer.value?.image,
        verticalImage:
          values?.verticalImage && typeof values?.verticalImage === 'object'
            ? values.verticalImage[0]
            : values.verticalImage || translatedOffer.value?.verticalImage,
      }
    })

    const currencyList = getAllCurrencies()
      .filter((currency: CurrencyInfo) => currency.decimals !== null)
      .map((currency: CurrencyInfo) => {
        return {
          value: currency.code,
          label: currency.name,
        }
      })

    /**
     * Actions performed when switching language: stores in state the current
     * selected language and fills the form with previously set values for fields
     */
    const changeLanguage = (lang: string) => {
      store.dispatch('campaignsModule/setSelectedLanguage', lang)
      setValues({
        ...values,
        ...translatedFields(translatableFields, [], lang),
      })
    }

    // Copy existing translation into local translation array if any
    if (!translations.value && offer.value && props.offerId)
      copyTranslationFromSavedEntity(offer.value, translatableFields)

    const readMoreTemplateAction = computed(() => {
      const actionName = props.offerId ? 'saveReadMoreTemplate' : 'saveNewOfferReadMoreTemplate'

      return `campaignsModule/${actionName}`
    })

    const resetButtonPayload = () => {
      values.payload = ''
    }

    const canEdit = isAuthorized(Permissions.EDIT_FIELDS_ON_OFFER, props.id)

    const font = computed(() => project.value?.branding?.googleFontPath)

    const {
      isModalVisible: isLanguagesModalVisable,
      showModal: showLanguagesModal,
      closeModal: closeLanguagesModal,
    } = useModal()

    return {
      cardDesign,
      createOfferStyle,
      onSubmit,
      goBack,
      meta,
      values,
      loading,
      internalPages,
      extensionPages,
      offer,
      previewOffer,
      currencyList,
      readMoreTemplate,
      readMoreTemplateAction,
      selectedLanguage,
      updateTranslation,
      translations,
      translatedOffer,
      changeLanguage,
      css: { pageContentLoading, formError },
      pageActions,
      pageError,
      payloadTypes,
      project,
      resetButtonPayload,
      push,
      saving,
      IMAGES_EXT,
      canEdit,
      selectedOfferTags,
      systemOfferTags,
      changeOfferTags,
      languages: computed(() => {
        const defaultLang = project.value?.productId.defaultLanguage || ''
        const langs = offer.value?.languages || []

        if (offer.value?.languages.includes(defaultLang)) return new Set([defaultLang, ...offer.value?.languages])

        return langs
      }),
      handleChangeLanguages: (languages: string[]) => {
        store.dispatch('campaignsModule/updateOffer', { languages })
        if (!store.state.campaignsModule.newOffer?.languages.includes(selectedLanguage.value))
          store.dispatch('campaignsModule/setSelectedLanguage', store.state.campaignsModule.newOffer?.languages[0])

        closeLanguagesModal()
      },
      isLanguagesModalVisable,
      showLanguagesModal,
      closeLanguagesModal,
      changeHideVaultData,
      hideVaultData,
    }
  },
})
