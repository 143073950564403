import { style } from 'typestyle'

export const container = style({
  display: 'flex',
  justifyContent: 'center',
})

export const inner = style({
  width: '550px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
