import axios from 'axios'
import { OptionType } from '@/types/components'

export interface GoogleFont {
  category: string
  family: string
  variants: Array<string>
  files: { [key: string]: string }
}

class AssetsService {
  public async getGoogleFonts(): Promise<Array<OptionType>> {
    return axios
      .get(
        'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyD9N5rpaafv3xXvrPO1jYEM7bLM272vIfE'
      )
      .then((fonts) => {
        const fontsList: Array<OptionType> = []
        fonts.data.items.forEach((font: GoogleFont) => {
          font.variants.forEach((variant: string) => {
            fontsList.push({
              label: `${font.family} ${variant}`,
              value: font.files[variant],
            })
          })
        })

        return fontsList
      })
  }
}

export default new AssetsService()
