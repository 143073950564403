
import { defineComponent, PropType } from 'vue'
import { individualOfferStyle } from '@/components/ui/styles'
import { OfferTemplateKey } from '@/types/models'
import { Sizes } from '@/utils'
import BckOfferCard from '@/components/ui/BckOfferCard.vue'

export default defineComponent({
  components: { BckOfferCard },

  props: {
    margin: {
      type: String as PropType<Sizes>,
      default: 'small',
    },
    templateKey: {
      type: String as PropType<OfferTemplateKey>,
      default: 'BUY',
    },
    buttonText: {
      type: String,
      default: 'Select',
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['card-selected'],

  setup() {
    return { individualOfferStyle }
  },
})
