
import { computed, defineComponent, PropType, ref } from 'vue'
import { readMoreStyle as css } from '@/views/campaigns/styles'
import { smallDeleteButton } from '@/components/ui/styles'
import SeamlessPreview from '@/components/common/SeamlessPreview.vue'
import { useFieldArray, useForm } from 'vee-validate'
import { getLanguageFromKey } from '@/utils/languagesHelper'
import LanguageTabs from '@/components/common/LanguageTabs.vue'
import { useTranslatableFields } from '@/composables/useTranslatableFields'
import { useStore } from '@/store'
import * as yup from 'yup'
import { forEach, keys, max, pick, values as getObjectValues } from 'lodash'
import { translateReadMoreTemplate } from '@/graphqlBackOffice/campaigns/transformers'
import { ProductPage } from '@/types/models'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { useModal } from '@/composables/useModal'
import ProjectsService from '@/graphqlBackOffice/projects/service'

export default defineComponent({
  name: 'ReadMoreTemplateModal',

  components: { LanguageTabs, SeamlessPreview, ConfirmationModal },

  props: {
    saveActionName: {
      type: String,
      default: '',
    },
    languages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    preselectedLanguage: {
      type: String,
      default: '',
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    readMoreTemplate: {
      type: Object as PropType<ProductPage>,
      default: () => {
        return {}
      },
    },
    offerId: {
      type: String,
      default: '',
    },
    projectId: {
      type: String,
      required: true,
    },
  },

  emits: ['close', 'template-saved'],

  setup(props, { emit }) {
    const store = useStore()

    const { project } = ProjectsService.getProjectById(
      props.projectId,
      'cache-first'
    )

    const indexToDelete = ref(0)
    const {
      isModalVisible: isDeleteModalVisible,
      showModal: showDeleteModal,
      closeModal: closeDeleteModal,
    } = useModal()
    const selectedLanguage = ref(
      props.preselectedLanguage || props.languages?.[0]
    )
    const {
      copyTranslationFromSavedEntity,
      translations,
      removeTranslation,
      updateTranslation,
      getTranslationsForField,
      isAllFormTranslated,
    } = useTranslatableFields()

    const translatedTemplate = computed(() =>
      translateReadMoreTemplate(props.readMoreTemplate, selectedLanguage.value)
    )

    const { values, setValues, handleSubmit } = useForm({
      validationSchema: yup.object().shape({
        title: yup.string().required('This field is required.'),
        subtitle: yup.array(yup.string().required('This field is required.')),
        description: yup.array(
          yup.string().required('This field is required.')
        ),
      }),
      initialValues: translatedTemplate?.value,
    })
    const {
      fields: subtitleFields,
      push: pushSubtitle,
      remove: removeSubtitle,
    } = useFieldArray('subtitle')
    const {
      fields: descriptionFields,
      push: pushDescription,
      remove: removeDescription,
    } = useFieldArray('description')

    const addTableItem = () => {
      const index = tableItemsCount.value
      pushSubtitle('')
      pushDescription('')

      forEach(props.languages, (language) => {
        updateTranslation('subtitle', '', language, index)
        updateTranslation('description', '', language, index)
      })
    }

    const removeTableItem = (index: number) => {
      removeSubtitle(index)
      removeDescription(index)

      removeTranslation('subtitle', index)
      removeTranslation('description', index)
      closeDeleteModal()
    }

    const tableItemsCount = computed(() => {
      return max([
        subtitleFields.value?.length,
        descriptionFields.value?.length,
      ])
    })

    /**
     * Actions performed when switching language: fills the form with
     * previously set values for fields
     */
    const changeLanguage = (lang: string) => {
      const tableItems = tableItemsCount.value
      selectedLanguage.value = lang
      setValues({
        title: translatedField('title'),
        subtitle: translatedField('subtitle', true),
        description: translatedField('description', true),
      })

      // If previously selected language had more table items,
      // add them for current language too.
      while (tableItemsCount.value < tableItems) {
        addTableItem()
      }
    }

    setValues(translatedTemplate.value)

    const translatedField = (fieldName: string, groupFields?: boolean) => {
      return groupFields
        ? getObjectValues(translations.value?.[fieldName]).map(
            // eslint-disable-next-line
            (fieldValues: any) => fieldValues?.[selectedLanguage.value] || ''
          )
        : translations.value?.[fieldName]?.[selectedLanguage.value]
    }

    const isFormValid = computed(() =>
      isAllFormTranslated(
        keys(values).filter((value) => value !== 'description'),
        props.languages
      )
    )

    const saveTemplate = handleSubmit(() => {
      const template = {
        ...pick(props.readMoreTemplate, ['_id', 'pageId', 'name']),
        title: getTranslationsForField('title'),
        subtitle: getTranslationsForField('subtitle'),
        description: getTranslationsForField('description'),
      }
      if (props.saveActionName) {
        store.dispatch(props.saveActionName, template)
      } else {
        emit('template-saved', template)
      }

      emit('close')
    })

    // Copy existing translation into local translation array if any
    if (!translations.value && props.readMoreTemplate) {
      copyTranslationFromSavedEntity(props.readMoreTemplate, keys(values))
    }

    const readMoreRows = computed(() => {
      return getObjectValues(
        values.subtitle?.map((subtitle: string, index: number) => {
          return {
            subtitle: subtitle,
            description: values.description?.[index] || '',
          }
        })
      )
    })

    if (!tableItemsCount.value) {
      // Add one group
      addTableItem()
    }

    const confirmDeleteTableItem = (index: number) => {
      showDeleteModal()
      indexToDelete.value = index
    }

    return {
      addTableItem,
      buttons: ['BUY', 'BACK'],
      changeLanguage,
      closeDeleteModal,
      confirmDeleteTableItem,
      css: { ...css, smallDeleteButton },
      getLanguageFromKey,
      indexToDelete,
      isFormValid,
      isDeleteModalVisible,
      translatedTemplate,
      readMoreRows,
      removeTableItem,
      saveTemplate,
      selectedLanguage,
      subtitleFields,
      tableItemsCount,
      translations,
      updateTranslation,
      values,
      project,
    }
  },
})
