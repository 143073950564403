
import { computed, defineComponent } from 'vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { useModal } from '@/composables/useModal'
import { loading } from '@/components/ui/styles'

export default defineComponent({
  components: {
    ConfirmationModal,
  },
  props: {
    preMsg: {
      type: String,
      required: false,
      default: 'Are you sure you want to delete',
    },
    postMsg: {
      type: String,
      required: false,
      default: '?',
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    entityNameSingular: {
      type: String,
      default: 'item',
    },
    entityNamePlural: {
      type: String,
      default: 'items',
    },
    loadingDelete: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['delete'],

  setup(props, { emit }) {
    const { isModalVisible, closeModal, showModal } = useModal()
    const selectedItemsText = computed(() => {
      const length = props.selectedItems.length

      return `${length} ${
        length === 1 ? props.entityNameSingular : props.entityNamePlural
      }`
    })

    const deleteItems = () => {
      emit('delete')
      closeModal()
    }
    return {
      css: { loading },
      deleteItems,
      selectedItemsText,
      isModalVisible,
      closeModal,
      showModal,
    }
  },
})
