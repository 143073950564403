import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckText = _resolveComponent("BckText")!
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_BckTextBubble = _resolveComponent("BckTextBubble")!
  const _component_BckTable = _resolveComponent("BckTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_BckText, {
          key: 0,
          size: "small",
          color: "primary-red",
          margin: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_BckTable, {
      rows: _ctx.rows,
      cells: _ctx.campaignsTableCells,
      sortable: "",
      loading: _ctx.loadingAction || _ctx.loading,
      count: _ctx.count,
      "initial-sorted-by": _ctx.queryInitialValues.sortedBy,
      "initial-sort-order": _ctx.queryInitialValues.sortOrder,
      onSort: _ctx.onTableSort
    }, {
      default: _withCtx(({ row }) => [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.css.tableRow({}))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell(_ctx.campaignsTableCells[0].width))
          }, [
            _createVNode(_component_BckText, {
              color: "primary-blue",
              size: "small",
              weight: "bold",
              clickable: "",
              onClick: ($event: any) => (_ctx.goToFunnels(row._id))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.name), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell(_ctx.campaignsTableCells[1].width))
          }, [
            (row.platform)
              ? (_openBlock(), _createBlock(_component_BckIcon, {
                  key: 0,
                  "icon-name": `${row.platform}Ads`,
                  "view-box": "0 0 550 550"
                }, null, 8, ["icon-name"]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell(_ctx.campaignsTableCells[2].width))
          }, [
            _createVNode(_component_BckTextBubble, {
              size: "90px",
              color: row.funnelIds?.length > 0 ? 'primary-blue' : 'secondary-gray',
              centered: "",
              onClick: ($event: any) => (_ctx.goToFunnels(row._id))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.funnelsText), 1)
              ]),
              _: 2
            }, 1032, ["color", "onClick"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell(_ctx.campaignsTableCells[3].width))
          }, [
            _createVNode(_component_BckText, {
              color: "primary-navy",
              size: "small",
              weight: "bold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.capitalizeFirstLetter(row.type)), 1)
              ]),
              _: 2
            }, 1024)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.css.tableCell(_ctx.campaignsTableCells[4].width))
          }, [
            _createVNode(_component_BckText, {
              color: "primary-navy",
              size: "small",
              weight: "bold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.createdAt), 1)
              ]),
              _: 2
            }, 1024)
          ], 2)
        ], 2)
      ]),
      _: 1
    }, 8, ["rows", "cells", "loading", "count", "initial-sorted-by", "initial-sort-order", "onSort"])
  ], 64))
}