import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckText = _resolveComponent("BckText")!
  const _component_LanguageTabs = _resolveComponent("LanguageTabs")!
  const _component_BckInput = _resolveComponent("BckInput")!
  const _component_BckEditor = _resolveComponent("BckEditor")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_BckText, {
          key: 0,
          margin: "small no",
          weight: "bold"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.languages.length)
      ? (_openBlock(), _createBlock(_component_LanguageTabs, {
          key: 1,
          "selected-language": _ctx.selectedLanguage,
          languages: _ctx.languages,
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLanguage($event)))
        }, null, 8, ["selected-language", "languages"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BckInput, {
      class: _normalizeClass(_ctx.css.margin('sMedium no no')),
      name: "title",
      "max-characters": "65",
      placeholder: "Page title",
      "full-width": "",
      disabled: _ctx.disabled,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (
        _ctx.updateTranslation('title', $event, _ctx.selectedLanguage),
          _ctx.$emit('on-editor-change', true)
      ))
    }, null, 8, ["class", "disabled"]),
    (_openBlock(), _createBlock(_component_BckEditor, {
      key: `editor${_ctx.selectedLanguage}`,
      ref: "editor",
      disabled: _ctx.disabled,
      content: _ctx.editorContent,
      onContentUpdated: _cache[2] || (_cache[2] = ($event: any) => (
        _ctx.updateTranslation('description', $event, _ctx.selectedLanguage),
          _ctx.$emit('on-editor-change', true)
      ))
    }, null, 8, ["disabled", "content"]))
  ]))
}