
import { defineComponent, ref } from 'vue'
import { format, eachDayOfInterval } from 'date-fns'
import { funnelScheduleStyle as css } from '../styles'
import { calendarStyle, formError } from '@/components/ui/styles'
import { DateRange } from '@/types/models'

export default defineComponent({
  props: {
    startDate: {
      type: Date,
      default: null,
    },
    endDate: {
      type: Date,
      default: null,
    },
    preFilled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Funnel schedule',
    },
    hideDaysSelected: {
      type: Boolean,
      default: false,
    },
    boldTitle: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    const date = ref({
      range: {
        start: props.preFilled ? new Date(props.startDate) : null,
        end: props.preFilled ? new Date(props.endDate) : null,
      },
      set: props.preFilled,
      period: props.preFilled
        ? eachDayOfInterval({ start: props.startDate, end: props.endDate })
            .length
        : 0,
    })

    const onCalendarDateChange = (selectedRange: DateRange) => {
      date.value.range = selectedRange
      date.value.set = true
      date.value.period = eachDayOfInterval(selectedRange).length
      emit('change', date.value.range)
    }

    return {
      date,
      format,
      onCalendarDateChange,
      eachDayOfInterval,
      css: { ...css, formError },
      calendarStyle,
      masks: {
        title: 'MMMM',
        weekdays: 'WWW',
      },
    }
  },
})
