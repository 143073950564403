
import { defineComponent, ref, watch } from 'vue'
import { editTextStyle, formError, loading } from '@/components/ui/styles'
import { getColor } from '@/utils'

export default defineComponent({
  name: 'BckEditText',
  props: {
    placeholder: {
      type: String,
      default: 'Edit name',
    },
    value: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update'],

  setup(props) {
    const editing = ref(false)
    const inputRef = ref()

    watch(
      () => props.loading,
      (loading) => {
        if (!loading) {
          editing.value = false
        }
      }
    )
    const startEdit = () => {
      if (props.disabled) return
      editing.value = true
    }

    return {
      css: {
        ...editTextStyle,
        loading,
        formError,
      },
      editing,
      getColor,
      inputRef,
      startEdit,
    }
  },
})
